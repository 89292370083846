import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, ICreateBuildTest } from "../interfaces";
interface IInitState {
  loading: boolean;
  data: IBuildTest[];
  error: string;
  filtersData: any;
}

const initialState = {
  loading: false,
  data: [],
  error: "",
  filtersData: "",
};

const createbuildTestSlice = createSlice({
  name: "createBuildTest",
  initialState,
  reducers: {
    createBuildTestRequest(
      state: IInitState,
      action: PayloadAction<ICreateBuildTest>
    ) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    createBuildTestSuccess(state: IInitState, action: PayloadAction<any[]>) {
      state.loading = false;
      state.data = action.payload;
    },
    resetCreateBuildTest(state: IInitState) {
      state.loading = false;
      state.data = [];
    },
  },
});

export const {
  createBuildTestRequest,
  createBuildTestSuccess,
  resetCreateBuildTest,
} = createbuildTestSlice.actions;
export default createbuildTestSlice.reducer;
