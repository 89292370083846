import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILoginInitState, ITokens, CredsState } from "./interfaces";
const initialState: ILoginInitState = { isLoading: false, data: {}, error: "" };

const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    loginRequest(state: ILoginInitState, action: PayloadAction<CredsState>) {
      state.isLoading = true;
    },
    loginSuccess(state: ILoginInitState, action: PayloadAction<ITokens>) {
      state.isLoading = false;
      state.data = action.payload;
    },
    loginError(state: ILoginInitState, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { loginRequest, loginSuccess, loginError } = loginSlice.actions;
export default loginSlice.reducer;
