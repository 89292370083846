import { takeLatest, put, call } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  ITermExamListReqBody,
  ITermExamIdReqBody,
  ICreateTermBody,
} from "./interfaces";
import TermExamService from "./services";
import { ResponseGenerator } from "interfaces";
import {
  termExamListRequest,
  termExamListSuccess,
  termExamDataRequest,
  termExamDataSuccess,
} from "./slices/term-exam-list-slices";
import {
  termExamDeliveryListRequest,
  termExamDeliveryListSuccess,
} from "./slices/deliver-list-slice";

import {
  listAssignmentRequest,
  listAssignmentSuccess,
} from "./slices/list-assignment-slice";

import {
  newListAssignmentRequest,
  newListAssignmentSuccess,
} from "./slices/new-list-assignment";
import {
  getPreviewDataRequest,
  getPreviewDataSuccess,
} from "./slices/get-preview-data-slice";

import {
  createTermExamRequest,
  createTermExamSuccess,
} from "./slices/create-term-exam-slice";

import {
  updateTermExamDeliveryRequest,
  getTermExamDeliveryRequest,
  getTermExamDeliverySuccess,
} from "./slices/update-term-exam-delivery";

import {
  termExamDeliveryDataRequest,
  termExamDeliveryDataSuccess,
} from "./slices/delivery-data-slice";

import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";

const termExamService = new TermExamService();

function* deleteTermExamWorker(action: PayloadAction<ITermExamIdReqBody>) {
  try {
    const res: ResponseGenerator = yield call(
      termExamService.deleteTermExamApi,
      action.payload
    );
  } catch (e) {}
}

function* getTermExamListWorker(action: PayloadAction<ITermExamListReqBody>) {
  try {
    const res: ResponseGenerator = yield call(
      termExamService.getTermExamListApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(termExamListSuccess(res.data.data));
    }
  } catch (e) {}
}

function* getTermExamDeliveryListWorker(
  action: PayloadAction<ITermExamIdReqBody>
) {
  try {
    const res: ResponseGenerator = yield call(
      termExamService.getTermExamDeliveryListApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(termExamDeliveryListSuccess(res.data.data));
    }
  } catch (e) {}
}

function* getTermExamDeliveryDataWorker(
  action: PayloadAction<ITermExamIdReqBody>
) {
  try {
    const res: ResponseGenerator = yield call(
      termExamService.getTermExamDeliveryDataApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(termExamDeliveryDataSuccess(res.data.data));
    }
  } catch (e) {}
}

function* getListAssignmentWorker(action: PayloadAction<ITermExamIdReqBody>) {
  try {
    const res: ResponseGenerator = yield call(
      termExamService.getAssignmentListApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(listAssignmentSuccess(res.data.data));
    }
  } catch (e) {}
}

function* getNewListAssignmentWorker(
  action: PayloadAction<ITermExamIdReqBody>
) {
  try {
    const res: ResponseGenerator = yield call(
      termExamService.getNewAssignmentListApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(newListAssignmentSuccess(res.data.data));
    }
  } catch (e) {}
}

function* createTermExamWorker(action: PayloadAction<ICreateTermBody>) {
  try {
    const res: ResponseGenerator = yield call(
      termExamService.getCreateNewTermExamApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(
        snackbarRequest({
          message: action.payload.descriptive_exam_id
            ? "updated successfully"
            : "created successfully",
        })
      );
      if (!action.payload.descriptive_exam_id) {
        yield put(
          termExamDataRequest({
            descriptive_exam_id: res.data.data.descriptive_exam_id,
          })
        );
      }
      yield put(createTermExamSuccess(res.data.data));
      yield put(
        replace(`/term-exams/edit/${res.data.data.descriptive_exam_id}?step=1`)
      );
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (e) {}
}

function* getTermExamDataWorker(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      termExamService.getTermExamDataApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(termExamDataSuccess(res.data.data));
    }
  } catch (e) {}
}

const convertToIdFormat = (res: any) => ({
  ded_id: res.ded_id,
  descriptive_exam_id: res.descriptive_exam_id,
  academic_year_id: res.academic_year_id,
  submission_end_date: res.end_date_time,
  submission_start_date: res.submit_start_time,
  exam_start_date: res.start_date_time,
  batch_id: res.batch_list,
  board_id: res.board_list,
  class_id: res.class_id,
  college_id: res.college_list,
  reservation_status_id: res.reservation_status_list,
  solution_visibility_date: res.solution_visibility_date_time,
  state_id: res.state_list,
  sub_batch_id: res.sub_batch_list,
});

function* getTermExamDeliveryWorker(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      termExamService.getTermExamDeliveryApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      const formattedRes = convertToIdFormat(res.data.data);
      yield put(getTermExamDeliverySuccess(formattedRes));
    }
  } catch (e) {}
}

function* updateTermExamDeliveryWorker(action: any) {
  try {
    const { reqBody, handleDrawerClose } = action.payload;
    const requestBody = {
      term_exam_id: reqBody.term_exam_id,
      descriptive_exam_delivery_id: reqBody.descriptive_exam_delivery_id,
      descriptve_exam_delivery_id: reqBody.descriptive_exam_delivery_id,
      board_list: reqBody.board_id,
      class_id: reqBody.class_id,
      academic_year: reqBody.academic_year_id,
      batch_list: reqBody.batch_id,
      reservation_status_list: reqBody.reservation_status_id,
      sub_batch_list: reqBody.sub_batch_id,
      state_list: reqBody.state_id,
      college_list: reqBody.college_id,
      start_date_time: reqBody.exam_start_date,
      end_date_time: reqBody.submission_end_date,
      submit_start_date_time: reqBody.submission_start_date,
      solution_visibility_date_time: reqBody.solution_visibility_date_time,
    };

    const res: ResponseGenerator = yield call(
      termExamService.updateTermExamDeliveryApi,
      requestBody
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(
        termExamDeliveryListRequest({
          descriptive_exam_id: reqBody.term_exam_id,
        })
      );
      yield put(snackbarRequest({ message: "updated successfully" }));
      handleDrawerClose();
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (e) {}
}

function* getPreviewDataWorker(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      termExamService.getPreviewDataApi,
      action.payload.reqBody
    );
    if (res && res.status === 200 && res.data.status === "success") {
      yield put(snackbarRequest({ message: "get preview data success" }));
      const examDetails = action.payload.examDetails;
      const previewData = res.data.data.map((item: any, idx: number) => ({
        index: idx + 1,
        questionInfo: {
          questionId: item.sbd_question_number,
          marks: examDetails.marks_list[idx].replace(/\s/g, ""),
          questionMarks: "",
        },
        data: item,
      }));
      yield put(getPreviewDataSuccess(previewData));
    }
  } catch (e) {
    console.log("check error", e);
  }
}

export default function* assignmentsSaga() {
  yield takeLatest("DELETE_TERM_EXAM", deleteTermExamWorker);
  yield takeLatest(termExamListRequest.type, getTermExamListWorker);
  yield takeLatest(getPreviewDataRequest.type, getPreviewDataWorker);
  yield takeLatest(
    termExamDeliveryListRequest.type,
    getTermExamDeliveryListWorker
  );
  yield takeLatest(listAssignmentRequest.type, getListAssignmentWorker);
  yield takeLatest(newListAssignmentRequest.type, getNewListAssignmentWorker);
  yield takeLatest(createTermExamRequest.type, createTermExamWorker);
  yield takeLatest(termExamDataRequest.type, getTermExamDataWorker);
  yield takeLatest(getTermExamDeliveryRequest.type, getTermExamDeliveryWorker);
  yield takeLatest(
    updateTermExamDeliveryRequest.type,
    updateTermExamDeliveryWorker
  );
  yield takeLatest(
    termExamDeliveryDataRequest.type,
    getTermExamDeliveryDataWorker
  );
}
