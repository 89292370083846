import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitState {
  isLoading: boolean;
  testdata: any;
  replaceQuestionItem: string | number;
  swapQuestionItem: string[];
  swapQuestionSegment: string[];
  replaceQuestionITemList: boolean;
  error: any;
}

const initialState: IInitState = {
  isLoading: false,
  testdata: false,
  replaceQuestionItem: 0,
  swapQuestionItem: [],
  swapQuestionSegment: [],
  replaceQuestionITemList: false,
  error: false,
};

const testDetailsByIdSlice = createSlice({
  name: "testDetailsById",
  initialState: initialState,
  reducers: {
    getTestDetailsByIdRequest(state: IInitState, action: PayloadAction<any>) {
      state.isLoading = true;
      state.testdata = action.payload;
    },
    getTestDetailsByIdSuccess(state: IInitState, action: PayloadAction<any>) {
      state.isLoading = false;
      state.testdata = action.payload;
    },
    updateReplaceQuestionItem(state: IInitState, action: PayloadAction<any>) {
      state.replaceQuestionItem = action.payload;
    },
    updateSwapQuestionItem(state: IInitState, action: PayloadAction<any>) {
      state.swapQuestionItem = action.payload;
    },
    updateSwapQuestionSegment(state: IInitState, action: PayloadAction<any>) {
      state.swapQuestionSegment = action.payload;
    },
    updateReplaceQuestionId(state: IInitState, action: PayloadAction<any>) {
      state.replaceQuestionITemList = action.payload;
    },
    resetReplaceQuestionItem(state:IInitState){
      state.replaceQuestionItem = 0
    }
  },
});

export const {
  getTestDetailsByIdRequest,
  getTestDetailsByIdSuccess,
  updateReplaceQuestionItem,
  updateSwapQuestionItem,
  updateSwapQuestionSegment,
  updateReplaceQuestionId,
  resetReplaceQuestionItem
} = testDetailsByIdSlice.actions;

export default testDetailsByIdSlice.reducer;
