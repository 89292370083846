import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loading: false,
  data: [],
  error: "",
  filtersData:''
};

const deliveryDataSlice = createSlice({
  name: "termExameliveryData",
  initialState,
  reducers: {
    termExamDeliveryDataRequest(state, action) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    termExamDeliveryDataSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    resetTermExamsListDeliveries(state) {
      state.data = [];
      state.filtersData = "";
    },
  },
});

export const {
  termExamDeliveryDataRequest,
  termExamDeliveryDataSuccess,
  resetTermExamsListDeliveries,
} = deliveryDataSlice.actions;

export default deliveryDataSlice.reducer;
