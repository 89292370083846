import { lazyLoad } from "utils/loadable";

const TermExamsPage = lazyLoad(
  () => import("./index"),
  (module) => module.TermExamsPage,
  {
    fallback: (
      <div>
        <p>Loading....</p>
      </div>
    ),
  }
);

export default TermExamsPage;
