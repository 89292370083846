import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IUpdateBuildTest } from "../interfaces";
interface IInitState {
  loading: boolean;
  data: IBuildTest[];
  error: string;
  filtersData: any;
}

const initialState = {
  loading: false,
  data: [],
  error: "",
  filtersData: "",
};

const updatebuildTestSlice = createSlice({
  name: "updateBuildTest",
  initialState,
  reducers: {
    updateBuildTestRequest(
      state: IInitState,
      action: PayloadAction<IUpdateBuildTest>
    ) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    updateBuildTestSuccess(state: IInitState, action: PayloadAction<any[]>) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { updateBuildTestRequest, updateBuildTestSuccess } =
updatebuildTestSlice.actions;
export default updatebuildTestSlice.reducer;
