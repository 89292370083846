import { takeLatest, put, call } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { PayloadAction } from "@reduxjs/toolkit";
import { IAssignmentListReqBody, IAssignmentIdReqBody } from "./interfaces";
import assignmentService from "./service";
import { ResponseGenerator } from "interfaces";
import {
  assignmentListRequest,
  assignmentListSuccess,
  assignmentDataRequest,
  assignmentDataSuccess,
} from "./slices/assignment-list-slice";
import {
  assignmentDeliveryListRequest,
  assignmentDeliveryListSuccess,
} from "./slices/delivery-list-slice";
import {
  getAssignmentDeliveryRequest,
  getAssignmentDeliverySuccess,
  updateAssignmentDeliveryRequest,
} from "pages/assignments/slices/update-delivery-slice";
import {
  createAssignementRequest,
  createAssignementSuccess,
} from "./slices/create-assignment-slice";
import {
  assignmentDeliveryDataSuccess,
  assignmentDeliveryDataRequest,
} from "./slices/delivery-data-slice";
import {snackbarRequest} from 'shared/molecules/snackbar/snackbar-slice'

export const userLogoutAction = () => ({ type: "USER_LOGOUT" });



function* logoutWorker() {
  try {
    yield put(replace("/login"));
  } catch (e) {}
}

//--------------------------Assignments--------------------------------

function* createAssignmentWorker(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      assignmentService.createAssignmentListApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(snackbarRequest({message: action.payload.assignment_id ? 'updated successfully' : 'created successfully'}))
      yield put(createAssignementSuccess(res.data.data));
      let id = "";
      if (action.payload.assignment_id) id = action.payload.assignment_id;
      else id = res.data.data.assignmentId;
      yield put(replace(`/assignments/edit/${id}?step=1`));
    }
    else{
      console.log("upload failed in assignment",res.data.message)
      yield put(snackbarRequest({message:res.data.message,type:"error"}))
    }
  } catch (error) {}
}

function* getAssignmentDataWorker(
  action: PayloadAction<{ assignment_id: string }>
) {
  try {
    const res: ResponseGenerator = yield call(
      assignmentService.getAssignmentDataApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(assignmentDataSuccess(res.data.data));
    }
  } catch (e) {}
}

function* getAssignmentListWorker(
  action: PayloadAction<IAssignmentListReqBody>
) {
  try {
    const res: ResponseGenerator = yield call(
      assignmentService.getAssignmentListApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(assignmentListSuccess(res.data.data));
    }
  } catch (e) {}
}

function* deleteAssignmentWorker(action: PayloadAction<IAssignmentIdReqBody>) {
  try {
    const res: ResponseGenerator = yield call(
      assignmentService.deleteAssignmentApi,
      { assignment_id: action.payload.assignment_id }
    );
    if (res && res.status === 200 && res.data.status === "success") {
      yield put(assignmentListRequest(action.payload.filtersData));
    }
  } catch (e) {}
}

//--------------------------Assignments Delivery-----------------------

function* updateAssignmentDeliveryWorker(action: any) {
  try {
    const { reqBody, handleDrawerClose } = action.payload;
    const requestBody = {
      ad_id: reqBody.ad_id,
      assignment_id: reqBody.assignment_id,
      board_id: reqBody.board_id,
      class_id: reqBody.class_id,
      academic_year_id: reqBody.academic_year_id,
      assignment_start_date: reqBody.assignment_start_date,
      assignment_end_date: reqBody.assignment_end_date,
      solution_visibility_date: reqBody.solution_visibility_date,
      batch_list: reqBody.batch_id,
      reservation_status_list: reqBody.reservation_status_id,
      sub_batch_list: reqBody.sub_batch_id,
      state_list: reqBody.state_id,
      college_list: reqBody.college_id,
    };
    const res: ResponseGenerator = yield call(
      assignmentService.updateAssignmentDeliveryApi,
      requestBody
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(snackbarRequest({message:'updated successfully'}))
      handleDrawerClose();
      yield put(
        assignmentDeliveryListRequest({ assignment_id: reqBody.assignment_id })
      );
    }
    else{
      yield put(snackbarRequest({message:res.data.message,type:"error"}))
    }
  } catch (e) {}
}

const convertToIdFormat = (res: any) => ({
  ad_id: res.ad_id,
  assignment_id: res.assignment_id,
  academic_year_id: res.academic_year_id,
  assignment_end_date: res.assignment_end_date,
  assignment_start_date: res.assignment_start_date,
  batch_id: res.batch_id_list,
  board_id: res.board_id,
  class_id: res.class_id,
  college_id: res.college_id_list,
  reservation_status_id: res.reservation_status_id_list,
  solution_visibility_date: res.solution_visibility_date,
  state_id: res.state_id_list,
  sub_batch_id: res.sub_batch_id_list,
});

function* getAssignmentDeliveryWorker(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      assignmentService.getAssignmentDeliveryApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      const formattedRes = convertToIdFormat(res.data.data);
      yield put(getAssignmentDeliverySuccess(formattedRes));
    }
  } catch (e) {}
}
function* getAssignmentDeliveryDataWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      assignmentService.getAssignmentDeliveryDataApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(assignmentDeliveryDataSuccess(res.data.data));
    }
  } catch (e) {}
}

function* getAssignmentDeliveryListWorker(
  action: PayloadAction<IAssignmentIdReqBody>
) {
  try {
    const res: ResponseGenerator = yield call(
      assignmentService.getAssignmentDeliveryListApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(assignmentDeliveryListSuccess(res.data.data));
    }
  } catch (e) {}
}

function* deleteAssignmentDeliveryWorker(
  action: PayloadAction<{ assignment_id: string; ad_id: string }>
) {
  try {
    const res: ResponseGenerator = yield call(
      assignmentService.deleteAssignmentDeliveryApi,
      action.payload
    );
    if (res && res.status === 200 && res.data.status === "success") {
      yield put(assignmentDeliveryListRequest(action.payload));
    }
  } catch (e) {}
}

//-----------------------Assignments List Worker------------------------
export default function* assignmentsSaga() {
  yield takeLatest("USER_LOGOUT", logoutWorker);
  yield takeLatest("DELETE_ASSIGNMENT", deleteAssignmentWorker);
  yield takeLatest(
    "DELETE_ASSIGNMENT_DELIVERY",
    deleteAssignmentDeliveryWorker
  );
  yield takeLatest(assignmentListRequest.type, getAssignmentListWorker);
  yield takeLatest(assignmentDataRequest.type, getAssignmentDataWorker);
  yield takeLatest(
    assignmentDeliveryListRequest.type,
    getAssignmentDeliveryListWorker
  );
  yield takeLatest(
    getAssignmentDeliveryRequest.type,
    getAssignmentDeliveryWorker
  );
  yield takeLatest(
    updateAssignmentDeliveryRequest.type,
    updateAssignmentDeliveryWorker
  );
  yield takeLatest(createAssignementRequest.type, createAssignmentWorker);
  yield takeLatest(
    assignmentDeliveryDataRequest.type,
    getAssignmentDeliveryDataWorker
  );
}