import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILoginInitState, ITokens, CredsState } from "./interfaces";
const initialState: ILoginInitState = { isLoading: false, data: {}, error: "" };

const gmailLoginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    gmailLoginRequest(state: ILoginInitState, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    gmailLoginSuccess(state: ILoginInitState) {
      state.isLoading = false;
    },
    gmailLoginError(state: ILoginInitState, action: PayloadAction<any>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { gmailLoginRequest, gmailLoginSuccess, gmailLoginError } =
  gmailLoginSlice.actions;

export default gmailLoginSlice.reducer;
