import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IBuildTestListReqBody } from "../interfaces";
interface IInitState {
  loading: boolean;
  data: IBuildTest[];
  error: string;
  questionById: any;
  questionList: any;
  dupFormId: string;
  sideBarOpen: boolean;
}

const initialState = {
  loading: false,
  data: [],
  error: "",
  questionById: false,
  questionList: false,
  dupFormId: "",
  sideBarOpen: true,
};

const subQuestionGroupItemListSlice: any = createSlice({
  name: "subQuestionGroupItemList",
  initialState,
  reducers: {
    getSubQuestionGroupItemListRequest(
      state: IInitState,
      action: PayloadAction<any>
    ) {
      state.loading = true;
    },
    getSubQuestionGroupItemListSuccess(
      state: IInitState,
      action: PayloadAction<any>
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.questionById = action.payload.questionById;
      state.questionList = action.payload.questionList;
    },
    updateQuestionList(state: IInitState, action: PayloadAction<any>) {
      state.questionList = action.payload;
    },
    updateQuestionById(state: IInitState, action: PayloadAction<any>) {
      state.questionById = action.payload;
    },
    getDupFormId(state: IInitState, action: PayloadAction<any>) {
      state.dupFormId = action.payload;
    },
    getSideBarOpenStatus(state: IInitState, action: PayloadAction<any>) {
      state.sideBarOpen = action.payload;
    },
  },
});

export const {
  getSubQuestionGroupItemListRequest,
  getSubQuestionGroupItemListSuccess,
  updateQuestionById,
  updateQuestionList,
  getDupFormId,
  getSideBarOpenStatus,
} = subQuestionGroupItemListSlice.actions;
export default subQuestionGroupItemListSlice.reducer;
