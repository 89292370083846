import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme=>({
    logo:{
        height: '70vh',
        width:'100%',    
     },
     header:{
        fontSize:'3rem', 
        fontWeight:800
     },
     content:{
        fontSize:'1.125rem', 
        fontWeight:500, 
        color:'#64748b'
     }
}))