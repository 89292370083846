import { makeStyles } from "@mui/styles";

export const menuStyles = {
  color: "black",
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 40,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "#fffff",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

export const useStyles = makeStyles({
  title: {
    fontSize:'15px', 
    fontWeight:"bold"
  },
  mail:{
    color: "#a8a8a8", 
    fontSize:'14px',
    fontWeight:500
  },
  avatar:{
    width: 90, 
    height: 90,
    margin: "30px 0 20px 0"
  },
  badge:{
    color: "#ffffff", 
    cursor:'pointer'
  },
  container:{
    padding: '10px 24px 0px 24px', 
    marginBottom:'40px'
  }
});
