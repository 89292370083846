import { endpoints } from "./constants";
import request from "utils/axios/service";
import { IAnnouncementListReqBody, IAnnouncementIdReqBody, ICreateAnouncementListReqBody, ICreateAnnouncementDeliveryReqBody } from "./interfaces";

const getRequiredEndpoint = (data: any) => {
  if (data.announcement_id) return endpoints.UPDATE_ANNOUNCEMENT;
  return endpoints.CREATE_ANNOUNCEMENT;
};

export default class AnnouncementService {
  static createAnnouncementApi(payload: ICreateAnouncementListReqBody) {
     const url = getRequiredEndpoint(payload);
    return request.post(url, payload);
  }
  static getAnnouncementListApi(payload: IAnnouncementListReqBody) {
    return request.get(endpoints.ANNOUNCEMENT_LIST, payload);
  }
  static deleteAnnouncementApi(payload: IAnnouncementIdReqBody) {
    return request.post(endpoints.DELETE_ANNOUNCEMENT, payload);
  }
  static deleteAnnouncementDeliveryApi(payload: IAnnouncementIdReqBody) {
    return request.post(endpoints.DELETE_ANNOUNCEMENT_DELIVERY, payload);
  }
  static createAnnouncementDeliveriesApi(payload: ICreateAnnouncementDeliveryReqBody) {
    return request.post(endpoints.CREATE_ANNOUNCEMENT_DELIVERY, payload);
  }
  static getAnnouncementDeliveryListApi(payload: IAnnouncementListReqBody) {
    return request.get(endpoints.ANNOUNCEMENT_DELIVERY_LIST, payload);
  }
  static getAnnouncementByIdApi(payload: IAnnouncementIdReqBody) {
    return request.get(endpoints.GET_ANNOUNCEMENT_BY_ID, payload);
  }
  static getAnnouncementDeliveryByIdApi(payload: IAnnouncementIdReqBody) {
    return request.get(endpoints.GET_ANNOUNCEMENT_DELIVERY_BY_ID, payload);
  }
  static updateAnnouncementDeliveryListApi(payload: IAnnouncementIdReqBody) {
    return request.post(endpoints.UPDATE_ANNOUNCEMENT_DELIVERY, payload);
  }
}
