import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, ICreateSubGroup } from "../interfaces";
interface IInitState {
  loading: boolean;
  data: any;
  error: string;
  questionData: any;
}

const initialState = {
  loading: false,
  data: {},
  error: "",
  questionData: "",
};

const createSubQuestionGroupSlice = createSlice({
  name: "createSubQuestionGroup",
  initialState,
  reducers: {
    createSubQuestionGroupRequest(
      state: IInitState,
      action: PayloadAction<ICreateSubGroup>
    ) {
      state.loading = true;
      // state.filtersData = action.payload;
    },
    createSubQuestionGroupSuccess(
      state: IInitState,
      action: PayloadAction<any[]>
    ) {
      state.loading = false;
      state.data = action.payload;
      state.questionData=action.payload
    },
  },
});

export const { createSubQuestionGroupRequest, createSubQuestionGroupSuccess } =
  createSubQuestionGroupSlice.actions;
export default createSubQuestionGroupSlice.reducer;
