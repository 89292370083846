import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILibrary, ICreateResourceListReqBody, IInitState } from "../interfaces";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

const createResourceSlice = createSlice({
  name: "createResource",
  initialState,
  reducers: {
    createResourceRequest(
      state: IInitState,
      action: PayloadAction<ICreateResourceListReqBody>
    ) {
      state.loading = true;
    },
    createResourceSuccess(
      state: IInitState,
      action: PayloadAction<any[]>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { createResourceRequest, createResourceSuccess } =
  createResourceSlice.actions;
export default createResourceSlice.reducer;
