import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  data: false,
  error: false,
};

const featureConfigSlice = createSlice({
  name: "featureConfig",
  initialState,
  reducers: {
    featureConfigRequest(state = initialState) {
      state.isLoading = true;
      state.error = false;
    },
    featureConfigSuccess(state = initialState, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

export const { featureConfigRequest, featureConfigSuccess } =
  featureConfigSlice.actions;

export default featureConfigSlice.reducer;
