import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITermExam, IListAssignmentReqBody, IInitState } from "../interfaces";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

const listAssignmentSlice = createSlice({
  name: "termExamList",
  initialState,
  reducers: {
    listAssignmentRequest(
      state: IInitState,
      action: PayloadAction<IListAssignmentReqBody>
    ) {
      state.loading = true;
    },
    listAssignmentSuccess(
      state: IInitState,
      action: PayloadAction<ITermExam[]>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { listAssignmentRequest, listAssignmentSuccess } =
  listAssignmentSlice.actions;
export default listAssignmentSlice.reducer;
