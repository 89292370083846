import { endpoints } from "./constants";
import request from "utils/axios/service";
import history from "utils/history";
import { externalUsers } from "app-component/constants";
export default class LoginService {
  loginApi(payload: any) {
    return request.post(endpoints.LOGIN, payload);
  }
  gmailLoginApi(payload: any) {
    return request.get(endpoints.GMAIL_LOGIN, {});
  }
  async getMetaDataApi(screen: any = "", userName = "") {
    const res: any = await request.get(endpoints.META_DATA, {});
    if (
      res &&
      res.status === 200 &&
      res.data &&
      res.data.status === "success"
    ) {
      if (localStorage.getItem("token") && typeof screen === "function") {
        await screen(res.data.data);
        if (history.location.pathname === "/")
          history.replace("/assignments/create");
        // await localStorage.setItem("metadata", JSON.stringify(res.data.data));
      }
      if (screen === "login" && externalUsers[userName])
        setTimeout(() => history.push("/hierarchies/view-hierarchies"), 500);
      else if (screen === "login")
        setTimeout(() => history.push("/assignments/create"), 2000);

      return res.data.data;
    }
  }
}
