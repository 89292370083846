import { endpoints } from "./constants";
import {
  IScheduleListReqBody,
  ICreateMonthlyScheduleFieldTypes,
  IMonthlyScheduleDeleteReqBody
} from "./interfaces";
import request from "utils/axios/service";

const getCreateUpdateEndpoint = (reqBody: ICreateMonthlyScheduleFieldTypes) => {
  if (reqBody.schedule_id) return endpoints.UPDATE_MONTHLY_SCHEDULE;
  return endpoints.CREATE_MONTHLY_SCHEDULE;
};


export default class MonthlyScheduleService {
  static monthlyScheduleListApi(payload: IScheduleListReqBody) {
    return request.get(endpoints.MONTHLY_SCHEDULE_LIST, payload);
  }
  static getMonthlyScheduleDataApi(payload: ICreateMonthlyScheduleFieldTypes) {
    return request.get(endpoints.MONTHLY_SCHEDULE_BY_ID, payload);
  }
  static createMonthlyScheduleApi(payload: ICreateMonthlyScheduleFieldTypes) {
    const endpoint = getCreateUpdateEndpoint(payload);
    return request.post(endpoint, payload);
  }
  static deleteMonthlyScheduleApi(payload: IMonthlyScheduleDeleteReqBody) {
    return request.post(endpoints.DELETE_MONTHLY_SCHEDULE, payload);
  }
}

