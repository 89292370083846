import * as Yup from "yup";
import { questionFormDefaultValues } from "./routes/question-grouping/helpers";
import LibraryService from "./service";
import { getItem, setItem } from "utils/helpers";

export const buildTestCols = [
  "ID",
  "Created Date",
  "Test Name",
  "Test ID",
  "Exam Model",
  "Status",
  "Actions",
];

export interface BuildTestProps {
  created_date: string;
  test_name: string;
  test_id: string;
  exam_model_name: string;
  status: number;
  actions: string;
}

export const buildTestProperties = [
  {
    id: "start_date",
    type: "date-picker",
    label: "Start Date",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 4 },
    required: true,
  },
  {
    id: "end_date",
    type: "date-picker",
    label: "End Date",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 4 },
    required: true,
  },
  {
    id: "exam_model_name",
    type: "multi-select",
    label: "Select Exam Model",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 4 },
    options: [],
    list: "exam_model_list",
    keyValue: { label: "exam_model_name", value: "exam_model_id" },
  },
  {
    id: "warm_up_delivery_id",
    type: "text",
    label: "Warm-Up Delivery Id",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 4 },
    required: true,
  },
];

const subDays = (days) => {
  let date: any = new Date();
  date.setDate(date.getDate() - days);
  return date;
};

const initDateObj: any = {
  from: subDays(7),
  to: new Date(),
};

export const buildFormInitialValues: any = {
  start_date: initDateObj.from,
  end_date: initDateObj.to,
  exam_model_name: "",
  warm_up_delivery_id: "",
  test_name: "",
};

export const buildFormValidationSchema = Yup.object().shape({
  start_date: Yup.date().required("start date is required"),
  end_date: Yup.date().required("end date is required"),
  exam_model_name: Yup.array().required("exam model name is required"),
  warm_up_delivery_id: Yup.string(),
  test_name: Yup.string(),
});

export const buildFormListInitialValues: any = {
  start_date: initDateObj.from,
  end_date: initDateObj.to,
  exam_model_name: "",
  test_name: "",
};

export const buildFormListValidationSchema = Yup.object().shape({
  start_date: Yup.date().required("start date is required"),
  end_date: Yup.date().required("end date is required"),
  exam_model_name: Yup.array().required("exam model name is required"),
  test_name: Yup.string(),
});

//test creation
export const normalizeQuestionRes = (data) => {
  let [segmentList, segmentById, questionItemById] = [[], {}, {}];
  if (!data || data.length === 0)
    return { segmentList, segmentById, questionItemById };

  let count = 0;
  segmentList = data.map((item) => {
    let segmentId = item.section_name;
    let questionList = item?.test_item_list?.map((qItem, index) => {
      let qId = qItem.item_object_id || qItem.question_id;
      questionItemById[qId] = { ...qItem, isModified: false };
      return qId;
    });
    segmentById[segmentId] = {
      ...item,
      test_item_list: questionList,
      startsWith: count + 1,
    };

    count = count + parseInt(item.total_questions);
    return segmentId;
  });

  return { segmentList, segmentById, questionItemById };
};

export const normalizeGroupRes = (data) => {
  let [groupList, groupById, subGroupById] = [[], {}, {}];
  if (!data || data.length === 0) return { groupList, groupById, subGroupById };

  groupList = data.map((item) => {
    let groupId = item.subquestion_group_name;
    let subGroupList = item?.item_list?.map((gItem, index) => {
      let qId = gItem.item_object_id;
      subGroupById[qId] = { ...gItem, isModified: false, groupId };
      return qId;
    });
    groupById[groupId] = { ...item, item_list: subGroupList };

    return groupId;
  });

  return { groupList, groupById, subGroupById };
};

//question group
export const normalizeSubGroupResponse = (data) => {
  let [subGroupList, subGroupById]: any = [[], {}];
  if (!data) return { subGroupList, subGroupById };

  if (data?.sub_questions_list?.length)
    subGroupList = data?.sub_questions_list?.map((item) => {
      let subGroupId = item.sub_question_group_id;
      subGroupById[subGroupId] = { ...item, tabId: subGroupId };
      return subGroupId;
    });
  else {
    const timeStamp = new Date().getTime();
    subGroupList = [timeStamp];
    subGroupById[timeStamp] = {
      tabId: timeStamp,
      sub_question_group_id: "",
      sub_question_group_name: "",
      total_questions_count: 0,
      isNewSubGroup: true,
    };
  }

  return { subGroupList, subGroupById };
};

export const normalizeQuestionListResponse = (data) => {
  let [questionList, questionById]: any = [[], {}];
  if (!data) return { questionList, questionById };
  if (data?.length) {
    questionList = data?.map((item) => {
      let questionId = item.question_id;
      questionById[questionId] = item;
      return questionId;
    });
  } else {
    const timeStamp = new Date().getTime();
    questionList = [timeStamp];
    questionById[timeStamp] = {
      ...questionFormDefaultValues,
      tempQuestionId: timeStamp,
      isNewQuestion: true,
      isActive: true,
    };
  }

  return { questionList, questionById };
};

export const getHighestValue = (list) => {
  if (!list && !list.length) return 0;
  return list.reduce((acc, curr) => {
    const val = parseInt(curr?.split("_")[1] || "0");
    if (val > acc) return val;
    return acc;
  }, 0);
};

export const callHierarchyListApi = async (metaData, updateMetadata) => {
  const res: any = await LibraryService.getHierarchyByBatch({
    batch_id: process.env.REACT_APP_GENERIC_BATCH_ID,
  });
  if (res && res.status === 200 && res.data.status === "success") {
    let metadataList = { ...metaData };
    metadataList["subject_chapter_topic_list"] = res.data.data;
    updateMetadata(metadataList);
  }
};
