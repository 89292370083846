export const QUESTION_TYPES = {
  SINGLE_SELECT: "Single select",
  MULTI_SELECT: "Multi select",
  NUMERIC: "Numeric",
  GRID: "2 column grid",
  PARAGRAPH: "Paragraph",
};

export const questionFormDefaultValues: any = {
  question_id: "",
  item_uri: "",
  question_number: 0,
  package_id: "",
  package_name: "",
  batch_id_list: [],
  subject_id: "",
  subject_name: "",
  chapter_id: "",
  chapter_name: "",
  topic_id: "",
  topic_name: "",
  class_id_list: [],
  question_type_id: "",
  question_type_name: "",
  question_pool_id: "",
  question_pool_name: "",
  complexity_id: "",
  complexity_name: "",
  source_id: "",
  source_name: "",
  recommended_time: null,
};





