// import * as Yup from "yup";
// import {
//   nameValidationOptional,
//   urlValidationOptional,
// } from "utils/form-validation";

// export const mandatoryFields = [
//   { id: "class_id" },
//   { id: "term" },
//   { id: "exam_id" },
//   { id: "pdf_url" },
//   { id: "question_count" },
//   { id: "duration" },
//   { id: "max_marks" },
// ];
// export const termExamInitialValues: any = {
//   class_id: "",
//   term: "",
//   exam_id: "",
//   pdf_url: "",
//   quesion_count: "",
//   duration: "",
//   max_marks: "",
//   solution_pdf_name: "",
//   solution_pdf_url: "",
//   solution_video_name: "",
//   // solution_video_url: "",
//   // jw_media_id: "",
//   solution_video_duration: "",
//   thumbnail_url: "",
// };

// export const termExamValidationSchema =(status:boolean)=>{ 
//   return Yup.object().shape({
//   class_id: Yup.string().required("Class is required"),
//   term: Yup.string().required("Term is required"),
//   exam_id: Yup.string().required("Exam is required"),
//   pdf_url: Yup.string().required("PDF url is required"),
//   question_count: Yup.number()
//     .typeError("Number of Quesions are required")
//     .max(500, "The Number of questions should be less than 500")
//     .positive("Invalid Number")
//     .min(1, "The Number of questions should be more than 0"),
//   duration: Yup.number()
//     .typeError("Duration is required")
//     .max(180, "The duration should be less than 180")
//     .positive("Invalid Number")
//     .min(1, "The Number of questions should be more than 0"),
//   max_marks: Yup.number()
//     .typeError("Maximum marks required")
//     .max(1000, "The marks should be less than 1000")
//     .positive("Invalid Number")
//     .min(1, "The Number of questions should be more than 0"),
//   solution_pdf_name: Yup.string()
//     .min(2, "The name is too short")
//     .matches(nameValidationOptional, "Invalid name formate")
//     .max(200, "The name is too long"),
//   solution_video_name: Yup.string()
//     .min(2, "The name is too short")
//     .matches(nameValidationOptional, "Invalid name formate")
//     .max(200, "The name is too long"),
//   solution_video_url:status? Yup.string():Yup.string().matches(
//     urlValidationOptional,
//     "Invalid url formate"
//   ),
//   jw_media_id: Yup.string().min(2, "Please enter media id"),
//   thumbnail_url: Yup.string().matches(
//     urlValidationOptional,
//     "Invalid url formate"
//   ),
//   video_duration: Yup.number()
//     .typeError("")
//     .max(1000, "The duration should be less than 180 minutes")
//     .positive("Invalid Number")
//     .min(1, "Invalid Number"),
// })};

// export const getTermExamProperties = (type) => [
//   {
//     id: "class_id",
//     type: "single-select-dependency",
//     label: "Class *",
//     className: "select-styles",
//     breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
//     options: [],
//     readOnly: type === "edit" ? true : false,
//     list: "class_course_batch_data",
//     keyValue: { label: "class_name", value: "class_id" },
//     dependentFields: ["term", "exam_id"],
//   },
//   {
//     id: "term",
//     type: "single-select-dependency",
//     label: "Term *",
//     className: "select-styles",
//     breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
//     isDependent: true,
//     options: [],
//     list: "term_list",
//     readOnly: type === "edit" ? true : false,
//     keyValue: { label: "term", value: "term" },
//     fieldKeyLists: {
//       class_course_batch_data: "class_id",
//     },
//     dependentFields: ["exam_id"],
//   },
//   {
//     id: "exam_id",
//     type: "single-select-dependency",
//     label: "Term Exam Name *",
//     className: "select-styles",
//     breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
//     isDependent: true,
//     options: [],
//     list: "exam_list",
//     readOnly: type === "edit" ? true : false,
//     keyValue: { label: "exam_name", value: "exam_id" },
//     fieldKeyLists: {
//       class_course_batch_data: "class_id",
//       term_list: "term",
//     },
//   },
//   {
//     id: "pdf_url",
//     type: "upload",
//     label: "Upload term exam pdf file *",
//     className: "upload-styles",
//     breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
//     acceptTypes: ".pdf",
//     fileType: [".pdf"],
//     fields: { class_id: "" },
//     feature: "term-exams",
//   },
//   {
//     id: "question_count",
//     type: "number",
//     label: "No of questions *",
//     className: "number-styles",
//     breakpoints: { xs: 12, sm: 6, md: 4, lg: 4 },
//   },
//   {
//     id: "duration",
//     type: "number",
//     label: "Duration in Minutes *",
//     className: "number-styles",
//     breakpoints: { xs: 12, sm: 6, md: 4, lg: 4 },
//   },
//   {
//     id: "max_marks",
//     type: "number",
//     label: "Total Marks *",
//     className: "number-styles",
//     breakpoints: { xs: 12, sm: 6, md: 4, lg: 4 },
//   },
// ];

// export const termExamProperties = [
//   {
//     id: "class_id",
//     type: "single-select-dependency",
//     label: "Class",
//     className: "select-styles",
//     breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
//     options: [],
//     list: "class_course_batch_data",
//     keyValue: { label: "class_name", value: "class_id" },
//     dependentFields: ["term", "exam_id"],
//   },
//   {
//     id: "term",
//     type: "single-select-dependency",
//     label: "Term",
//     className: "select-styles",
//     breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
//     isDependent: true,
//     options: [],
//     list: "term_list",
//     keyValue: { label: "term", value: "term" },
//     fieldKeyLists: {
//       class_course_batch_data: "class_id",
//     },
//     dependentFields: ["exam_id"],
//   },
//   {
//     id: "exam_id",
//     type: "single-select-dependency",
//     label: "Term Exam Name",
//     className: "select-styles",
//     breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
//     isDependent: true,
//     options: [],
//     list: "exam_list",
//     keyValue: { label: "exam_name", value: "exam_id" },
//     fieldKeyLists: {
//       class_course_batch_data: "class_id",
//       term_list: "term",
//     },
//   },
//   {
//     id: "pdf_url",
//     type: "upload",
//     label: "Upload Term Exam",
//     className: "upload-styles",
//     breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
//     acceptTypes: ".pdf",
//     fileType: [".pdf"],
//     fields: { class_id: "" },
//     feature: "term-exams",
//   },
//   {
//     id: "question_count",
//     type: "number",
//     label: "No of questions",
//     className: "number-styles",
//     breakpoints: { xs: 12, sm: 6, md: 4, lg: 4 },
//   },
//   {
//     id: "duration",
//     type: "number",
//     label: "Duration in Minutes",
//     className: "number-styles",
//     breakpoints: { xs: 12, sm: 6, md: 4, lg: 4 },
//   },
//   {
//     id: "max_marks",
//     type: "number",
//     label: "Total Marks",
//     className: "number-styles",
//     breakpoints: { xs: 12, sm: 6, md: 4, lg: 4 },
//   },
// ];

// export const termExamSolutionPdf = [
//   {
//     id: "solution_pdf_name",
//     type: "text",
//     label: "Name of the Solution Pdf",
//     className: "name-styles",
//     breakpoints: { xs: 12, sm: 6, md: 4, lg: 6 },
//   },
//   {
//     id: "solution_pdf_url",
//     type: "upload",
//     label: "Upload solution pdf file",
//     className: "select-styles",
//     breakpoints: { xs: 12, sm: 6, md: 4, lg: 6 },
//     acceptTypes: ".pdf",
//     fileType: [".pdf"],
//     fields: { class_id: "", solution_pdf_name: "" },
//   },
// ];

// export const termExamSolutionVideo = (status:boolean)=>{
//   return [
//   {
//     id: "solution_video_name",
//     type: "text",
//     label: "Name of the Solution Video",
//     className: "name-styles",
//     breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
//   },
//   {
//     id: "solution_video_url",
//     type: status?'text':'url',
//     label:status?'Media ID':'Upload Solution Video Url',
//     className: "name-styles",
//     breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
//   },
//   {
//     id: "video_duration",
//     type: "number",
//     label: "Video Duration in Minutes",
//     className: "number-styles",
//     breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
//   },
//   {
//     id: "thumbnail_url",
//     type: "text",
//     label: "Upload Video Thumbnail Url",
//     className: "name-styles",
//     breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
//   },
// ]};

// // export const termExamSolutionVideoMedia = [
// //   {
// //     id: "solution_video_name",
// //     type: "text",
// //     label: "Name of the Solution Video",
// //     className: "name-styles",
// //     breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
// //   },
// //   {
// //     id: "jw_media_id",
// //     type: "text",
// //     label: "Media ID",
// //     className: "name-styles",
// //     breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
// //   },
// //   {
// //     id: "video_duration",
// //     type: "number",
// //     label: "Video Duration in Minutes",
// //     className: "number-styles",
// //     breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
// //   },
// //   {
// //     id: "thumbnail_url",
// //     type: "text",
// //     label: "Upload Video Thumbnail Url",
// //     className: "name-styles",
// //     breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
// //   },
// // ];

// //reset form values
// export const resetFormValues = (
//   setValue: any,
//   params: any,
//   termExamData: any
// ) => {
//   if (params?.descriptive_exam_id) {
//     setValue("class_id", termExamData.class_id);
//     setValue("term", termExamData.term);
//     setValue("exam_id", termExamData.exam_id);
//     setValue("duration", termExamData.duration);
//     setValue("question_count", termExamData.question_count);
//     setValue("max_marks", termExamData.max_marks);
//     setValue("pdf_url", termExamData.pdf_url);
//     setValue("solution_pdf_name", termExamData.solution_pdf_name);
//     setValue("solution_pdf_url", termExamData.solution_pdf_url);
//     setValue(
//       "solution_video_name",
//       termExamData?.solution_video?.solution_video_name
//     );
//     setValue("jw_media_id", termExamData?.solution_video?.jw_media_id);
//     setValue(
//       "solution_video_url",
//       termExamData?.solution_video?.is_drm
//         ? termExamData?.solution_video?.jw_media_id
//         : termExamData?.solution_video?.solution_video_url || ""
//     );
//     setValue("video_duration", termExamData?.solution_video?.duration);
//     setValue("thumbnail_url", termExamData?.solution_video?.thumbnail_url);
//   } else {
//     setValue("class_id", "");
//     setValue("term", "");
//     setValue("exam_id", "");
//     setValue("duration", "");
//     setValue("question_count", "");
//     setValue("max_marks", "");
//     setValue("pdf_url", "");
//     setValue("solution_pdf_name", "");
//     setValue("solution_pdf_url", "");
//     setValue("solution_video_name", "");
//     setValue("solution_video_url", "");
//     setValue("jw_media_id", "");
//     setValue("video_duration", "");
//     setValue("thumbnail_url", "");
//   }
// };


import * as Yup from "yup";
import {
  nameValidationOptional,
  urlValidationOptional,
} from "utils/form-validation";
import { endpointsMd } from "utils/metadata-split/constant";

export const mandatoryFields = [
  { id: "class_id" },
  { id: "term" },
  { id: "exam_id" },
  { id: "pdf_url" },
  { id: "question_count" },
  { id: "duration" },
  { id: "max_marks" },
];
export const termExamInitialValues: any = {
  class_id: "",
  term: "",
  exam_id: "",
  pdf_url: "",
  quesion_count: "",
  duration: "",
  max_marks: "",
  solution_pdf_name: "",
  solution_pdf_url: "",
  solution_video_name: "",
  solution_video_url: "",
  solution_video_duration: "",
  thumbnail_url: "",
};

export const termExamValidationSchema = Yup.object().shape({
  class_id: Yup.string().required("Class is required"),
  term: Yup.string().required("Term is required"),
  exam_id: Yup.string().required("Exam is required"),
  pdf_url: Yup.string().required("PDF url is required"),
  question_count: Yup.number()
    .typeError("Number of Quesions are required")
    .max(500, "The Number of questions should be less than 500")
    .positive("Invalid Number")
    .min(1, "The Number of questions should be more than 0"),
  duration: Yup.number()
    .typeError("Duration is required")
    .max(180, "The duration should be less than 180")
    .positive("Invalid Number")
    .min(1, "The Number of questions should be more than 0"),
  max_marks: Yup.number()
    .typeError("Maximum marks required")
    .max(1000, "The marks should be less than 1000")
    .positive("Invalid Number")
    .min(1, "The Number of questions should be more than 0"),
  solution_pdf_name: Yup.string()
    .min(2, "The name is too short")
    .matches(nameValidationOptional, "Invalid name formate")
    .max(200, "The name is too long"),
  solution_video_name: Yup.string()
    .min(2, "The name is too short")
    .matches(nameValidationOptional, "Invalid name formate")
    .max(200, "The name is too long"),
  solution_video_url: Yup.string().matches(
    urlValidationOptional,
    "Invalid url formate"
  ),
  thumbnail_url: Yup.string().matches(
    urlValidationOptional,
    "Invalid url formate"
  ),
  video_duration: Yup.number()
    .typeError("")
    .max(1000, "The duration should be less than 180 minutes")
    .positive("Invalid Number")
    .min(1, "Invalid Number"),
});

export const getTermExamProperties = (type,payload) => [
  {
    id: "class_id",
    type: "single-select-dependency",
    label: "Class *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    readOnly: type === "edit" ? true : false,
    list: "class_course_batch_data",
    keyValue: { label: "class_name", value: "class_id" },
   // dependentFields: ["term", "exam_id"],
    dependentFields: ["term"],
  },
  {
    id: "term",
    type: "single-select-dependency",
    label: "Term *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    isDependent: true,
    options: [],
    list: "term_list",
    hasApiCall: true,
     requestList: async () => {
       return {class_id:payload().class_id};
    },
     endpoint: endpointsMd.GET_TERM_EXAM_LIST,
     changeValueId: "class_id",
    // keyValue: { label: "term_exam_name", value: "term_exam_id" },
     keyValue: { label: "term", value: "term" },
    readOnly: type === "edit" ? true : false,
    //keyValue: { label: "term", value: "term" },
    fieldKeyLists: {
      //class_course_batch_data: "class_id",
    },
    dependentFields: ["exam_id"],
  },
  {
    id: "exam_id",
    type: "single-select-dependency",
    label: "Term Exam Name *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    isDependent: true, 
    options: [],
    //list: "exam_list",
    list: "term_exam_list",
    // onlyApiCall: true,
    // requestList: async (payload) => {
    //   return {};
    // },
    // endpoint:endpointsMd.GET_EXAM_LIST,
    readOnly: type === "edit" ? true : false,
   // keyValue: { label: "exam_name", value: "exam_id" },
    //keyValue: { label: "term_exam_name", value: "term_exam_id" },
    keyValue: { label: "term_exam_name", value: "sbd_id" },
    fieldKeyLists: {
      // class_course_batch_data: "class_id",
       term_list: "term",
    },
  },
  {
    id: "pdf_url",
    type: "upload",
    label: "Upload term exam pdf file *",
    className: "upload-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    acceptTypes: ".pdf",
    fileType: [".pdf"],
    fields: { class_id: "" },
    feature: "term-exams",
  },
  {
    id: "question_count",
    type: "number",
    label: "No of questions *",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 4 },
  },
  {
    id: "duration",
    type: "number",
    label: "Duration in Minutes *",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 4 },
  },
  {
    id: "max_marks",
    type: "number",
    label: "Total Marks *",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 4 },
  },
];

export const termExamProperties = [
  {
    id: "class_id",
    type: "single-select-dependency",
    label: "Class",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "class_course_batch_data",
    keyValue: { label: "class_name", value: "class_id" },
   // dependentFields: ["term", "exam_id"],
    dependentFields: ["term",],
  },
  {
    id: "term",
    type: "single-select-dependency",
    label: "Term",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    isDependent: true,
    options: [],
    list: "term_list",
    hasApiCall: true,
    requestList: async (payload) => {
      return {class_id:payload.class_id};
   },
    endpoint: endpointsMd.GET_TERM_EXAM_LIST,
    changeValueId: "class_id",
    //keyValue: { label: "term_exam_name", value: "term_exam_id" },
    keyValue: { label: "term", value: "term" },
    fieldKeyLists: {
      // class_course_batch_data: "class_id",
    },
    dependentFields: ["exam_id"],
  },
  {
    id: "exam_id",
    type: "single-select-dependency",
    label: "Term Exam Name",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    isDependent: true,
    options: [],
    // onlyApiCall: true,
    // requestList: async (payload) => {
    //   return {};
    // },
    // endpoint:endpointsMd.GET_EXAM_LIST,
    //list: "exam_list",
    list: "term_exam_list",
    //keyValue: { label: "exam_name", value: "exam_id" },
    keyValue: { label: "term_exam_name", value: "term_exam_id" },
    fieldKeyLists: {
      // class_course_batch_data: "class_id",
       term_list: "term",
    },
  },
  {
    id: "pdf_url",
    type: "upload",
    label: "Upload Term Exam",
    className: "upload-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    acceptTypes: ".pdf",
    fileType: [".pdf"],
    fields: { class_id: "" },
    feature: "term-exams",
  },
  {
    id: "question_count",
    type: "number",
    label: "No of questions",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 4 },
  },
  {
    id: "duration",
    type: "number",
    label: "Duration in Minutes",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 4 },
  },
  {
    id: "max_marks",
    type: "number",
    label: "Total Marks",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 4 },
  },
];

export const termExamSolutionPdf = [
  {
    id: "solution_pdf_name",
    type: "text",
    label: "Name of the Solution Pdf",
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 6 },
  },
  {
    id: "solution_pdf_url",
    type: "upload",
    label: "Upload solution pdf file",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 6 },
    acceptTypes: ".pdf",
    fileType: [".pdf"],
    fields: { class_id: "", solution_pdf_name: "" },
  },
];

export const termExamSolutionVideo = [
  {
    id: "solution_video_name",
    type: "text",
    label: "Name of the Solution Video",
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
  },
  {
    id: "solution_video_url",
    type: "text",
    label: "Upload Solution Video Url",
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
  },
  {
    id: "video_duration",
    type: "number",
    label: "Video Duration in Minutes",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
  },
  {
    id: "thumbnail_url",
    type: "text",
    label: "Upload Video Thumbnail Url",
    className: "name-styles",
    breakpoints: { xs: 12, sm: 6, md: 6, lg: 3 },
  },
];

//reset form values
export const resetFormValues = (
  setValue: any,
  params: any,
  termExamData: any
) => {
  if (params?.descriptive_exam_id) {
    setValue("class_id", termExamData.class_id);
    setValue("term", termExamData.term);
    setValue("exam_id", termExamData.exam_id);
    setValue("duration", termExamData.duration);
    setValue("question_count", termExamData.question_count);
    setValue("max_marks", termExamData.max_marks);
    setValue("pdf_url", termExamData.pdf_url);
    setValue("solution_pdf_name", termExamData.solution_pdf_name);
    setValue("solution_pdf_url", termExamData.solution_pdf_url);
    setValue(
      "solution_video_name",
      termExamData?.solution_video?.solution_video_name
    );
    setValue(
      "solution_video_url",
      termExamData?.solution_video?.solution_video_url
    );
    setValue("video_duration", termExamData?.solution_video?.duration);
    setValue("thumbnail_url", termExamData?.solution_video?.thumbnail_url);
  } else {
    setValue("class_id", "");
    setValue("term", "");
    setValue("exam_id", "");
    setValue("duration", "");
    setValue("question_count", "");
    setValue("max_marks", "");
    setValue("pdf_url", "");
    setValue("solution_pdf_name", "");
    setValue("solution_pdf_url", "");
    setValue("solution_video_name", "");
    setValue("solution_video_url", "");
    setValue("video_duration", "");
    setValue("thumbnail_url", "");
  }
};