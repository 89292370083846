import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, ICreateQuestionGroup } from "../interfaces";
interface IInitState {
  loading: boolean;
  createData: any;
  error: string;
}

const initialState = {
  loading: false,
  createData: {},
  error: "",
};

const createQuestionGroupSlice = createSlice({
  name: "createQuestionGroup",
  initialState,
  reducers: {
    createQuestionGroupRequest(
      state: IInitState,
      action: PayloadAction<ICreateQuestionGroup>
    ) {
      state.loading = true;
    },
    createQuestionGroupSuccess(
      state: IInitState,
      action: PayloadAction<any[]>
    ) {
      state.loading = false;
      state.createData = action.payload;
    },
  },
});

export const { createQuestionGroupRequest, createQuestionGroupSuccess } =
  createQuestionGroupSlice.actions;
export default createQuestionGroupSlice.reducer;
