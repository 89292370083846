import { takeLatest, put, call } from "redux-saga/effects";
import { replace } from "connected-react-router";
import { PayloadAction } from "@reduxjs/toolkit";
import { ILibraryListReqBody, ILibraryIdReqBody } from "./interfaces";
import libraryService from "./service";
import { getItem } from "utils/helpers";
import { ResponseGenerator } from "interfaces";
import {
  libraryListRequest,
  libraryListSuccess,
} from "./slices/library-resource-list-slice";
import {
  libraryVideoListRequest,
  libraryVideoListSuccess,
} from "./slices/library-video-list-slice";
import {
  createVideoRequest,
  createVideoSuccess,
} from "./slices/create-video-slice";
import {
  createResourceRequest,
  createResourceSuccess,
} from "./slices/create-resource-slice";

import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import {
  libraryFlipbookListRequest,
  libraryFlipbookListSuccess,
} from "./slices/library-flipbook-list-slice";

//----------------------Library Video--------------------------
function* createLibraryVideosWorker(
  action: PayloadAction<ILibraryListReqBody>
) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.createLibraryApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(snackbarRequest({ message: "created successfully" }));
      yield put(createVideoSuccess(res.data.data));
      const libraryFilters: any = getItem("libraryFilters");
      yield put(
        libraryVideoListRequest({
          ...libraryFilters,
          library_type: "video",
        })
      );
      yield put(replace("/library?feature=list&type=video"));
    }
  } catch (e) {}
}

function* getLibraryVideosListWorker(
  action: PayloadAction<ILibraryListReqBody>
) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.getLibraryListApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(libraryVideoListSuccess(res.data.data));
    }
  } catch (e) {}
}

function* deleteLibraryVideosListWorker(
  action: PayloadAction<ILibraryIdReqBody>
) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.deleteLibraryVideoApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(snackbarRequest({ message: "deleted successfully" }));
      const libraryFilters: any = getItem("libraryFilters");
      yield put(
        libraryVideoListRequest({
          ...libraryFilters,
          library_type: "video",
        })
      );
    }
  } catch (e) {}
}

//----------------------Library Resource--------------------------
function* createLibraryResourceWorker(
  action: PayloadAction<ILibraryListReqBody>
) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.createLibraryApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(snackbarRequest({ message: "created successfully" }));
      yield put(createResourceSuccess(res.data.data));
      const libraryFilters: any = getItem("libraryFilters");
      yield put(
        libraryListRequest({
          ...libraryFilters,
          library_type: "resource",
        })
      );
      yield put(replace("/library?feature=list&type=resource"));
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (e) {}
}

function* getLibraryListWorker(action: PayloadAction<ILibraryListReqBody>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.getLibraryListApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(libraryListSuccess(res.data.data));
    }
  } catch (e) {}
}

function* getFlipbookListWorker(action: PayloadAction<ILibraryListReqBody>) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.getLibraryListApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(libraryFlipbookListSuccess(res.data.data));
    }
  } catch (e) {}
}

function* deleteLibraryResourceListWorker(
  action: PayloadAction<ILibraryIdReqBody>
) {
  try {
    const res: ResponseGenerator = yield call(
      libraryService.deleteLibraryResourceApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(snackbarRequest({ message: " deleted successfully" }));
      const libraryFilters: any = getItem("libraryFilters");
      yield put(
        libraryListRequest({
          ...libraryFilters,
          library_type: "resource",
        })
      );
    }
  } catch (e) {}
}

//----------------------Library List Worker-----------------------------
export default function* librarysSaga() {
  yield takeLatest("DELETE_RESOURCE_LIBRARY", deleteLibraryResourceListWorker);
  yield takeLatest("DELETE_VIDEO_LIBRARY", deleteLibraryVideosListWorker);
  yield takeLatest(libraryListRequest.type, getLibraryListWorker);
  yield takeLatest(libraryVideoListRequest.type, getLibraryVideosListWorker);
  yield takeLatest(createVideoRequest.type, createLibraryVideosWorker);
  yield takeLatest(createResourceRequest.type, createLibraryResourceWorker);
  yield takeLatest(libraryFlipbookListRequest.type, getFlipbookListWorker);
}
