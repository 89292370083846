import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IBuildTestListReqBody } from "../interfaces";
interface IInitState {
  loading: boolean;
  dataList: IBuildTest[];
  error: string;
  filtersData: any;
}

const initialState = {
  loading: false,
  dataList: [],
  error: "",
  filtersData: "",
};

const subQuestionGroupListSlice = createSlice({
  name: "subQuestionGroupList",
  initialState,
  reducers: {
    getSubQuestionGroupListRequest(
      state: IInitState,
      action: PayloadAction<any>
    ) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    getSubQuestionGroupListSuccess(
      state: IInitState,
      action: PayloadAction<any[]>
    ) {
      state.loading = false;
      state.dataList = action.payload;
    },
  },
});

export const {
  getSubQuestionGroupListRequest,
  getSubQuestionGroupListSuccess,
} = subQuestionGroupListSlice.actions;
export default subQuestionGroupListSlice.reducer;
