import { endpoints } from "./constants";
// import request from "utils/axios/service";
import axios from "axios";
import request from "utils/axios/service";

class LearnService {
  getChapterTopicListApi(payload: any) {
    return request.get(endpoints.COURSE_SUBJECT_LIST, payload);
  }
  postLockChaptersApi(payload) {
    return request.post(endpoints.LOCK_CHAPTERS, payload);
  }
  postDisableChaptersApi(payload) {
    return request.post(endpoints.DISABLE_CHAPTERS, payload);
  }

  createSummary(payload) {
    return request.post(endpoints.CREATE_SUMMARY, payload);
  }
  getVideo(payload) {
    return request.get(endpoints.VIDEO, payload);
  }
  getQa(payload) {
    return request.get(endpoints.QA, payload);
  }
  getSynopsis(payload) {
    return request.get(endpoints.SYNOPSIS, payload);
  }
  getSummary(payload) {
    return request.get(endpoints.SUMMARY, payload);
  }
  getLearnResource(payload) {
    return request.get(endpoints.LEARNRESOURCE, payload);
  }
  getSynopsisContent(payload) {
    return request.get(endpoints.GET_SYNOPSIS_CONTENT, payload);
  }
  getQAContent(payload) {
    return request.get(endpoints.GET_QA_CONTENT, payload);
  }
  getSummaryContent(payload) {
    return request.get(endpoints.GET_SUMMARY_CONTENT, payload);
  }

  deleteQa(payload) {
    return request.post(endpoints.DELETE_QA, payload);
  }
  deleteSynopsis(payload) {
    return request.post(endpoints.DELETE_SYNOPSIS, payload);
  }

  deleteSummary(payload) {
    return request.post(endpoints.DELETE_SUMMARY, payload);
  }
  deleteResource(payload) {
    return request.post(endpoints.DELETE_LEARNRESOURCE(""), payload);
  }
  deleteVideo(payload) {
    return request.delete(endpoints.DELETE_VIDEO(payload.content_id), {});
  }
  postUploadResource(payload) {
    return request.post(endpoints.UPLOAD_RESOURCE, payload);
  }
  postUploadVideo(payload) {
    return request.post(endpoints.UPLOAD_VIDEO, payload);
  }
  getCourseSyllabusData(payload) {
    return request.get(endpoints.GET_COURSE_SYLLABUS_DATA, payload);
  }
  createQA(payload) {
    return request.post(endpoints.CREATE_QA, payload);
  }
  createSynopsis(payload) {
    return request.post(endpoints.CREATE_SYNOPSIS, payload);
  }

  updateVideo(payload) {
    return request.post(endpoints.UPDATE_VIDEO, payload);
  }
  updateResources(payload) {
    return request.post(endpoints.UPDATE_RESOURCES, payload);
  }
  updateSynopsis(payload) {
    return request.post(endpoints.UPDATE_SYNOPSIS, payload);
  }
  updateQA(payload) {
    return request.post(endpoints.UPDATE_QA, payload);
  }
  updateSummary(payload) {
    return request.post(endpoints.UPDATE_SUMMARY, payload);
  }
  createEmbibeResource(payload) {
    return request.post(endpoints.CREATE_EMBIBE_RESOURCE, payload);
  }
  getEmbibeResourceList(payload){
    return request.get(endpoints.EMBIBE_RESOURCE_LIST, payload);
  }
}
export default LearnService;
