export const microServices = {
  NCMSV1: "ncms/v1/",
  NCMSV2: process.env.REACT_APP_NCMS_MICROSERVICE,
};

export const endpoints = {
  LOGIN: `${microServices.NCMSV1}auth/login`,
  GMAIL_LOGIN: `${microServices.NCMSV1}auth/google`,
  META_DATA: `${microServices.NCMSV2}/usage/get-metadata`,
};
