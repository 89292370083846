import request from "utils/axios/service";
import { endpointsMd } from "./constant";
import { endpoints as endpointWD } from "pages/warm-up-delivery/constants";
 import { endpoints } from "pages/build-test/constants";



export const getStateListData = async (cb) => {
    try {
      const res: any = await request.get(endpointsMd.GET_STATE_LIST, {});
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"state_list");
        return [res.data.data,"state_list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
  };

export const getTemplateTypeListData = async (cb) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_TEMPLATE_TYPE_LIST, {});
      if (res && res.status === 200 && res.data.status === "success") {
      //  const dummyRes = await cb(res.data.data,"template_type_list");
        return [res.data.data,"template_type_list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
  };

// export const getCustomTemplateListData = async (cb) => { 
//     try {
//       const res: any = await request.get(endpointsMd.GET_CUSTOM_TEMPLATE_LIST, {template_type_id:""});
//       if (res && res.status === 200 && res.data.status === "success") {
//       //  const dummyRes = await cb(res.data.data,"custom_template_list");
//         return [res.data.data,"custom_template_list"]
//       }
//     } catch (error) {
//       //throw error;
//       console.log(error)
//     }
//   };
// export const getStandardTemplateTypeListData = async (cb) => { 
//     try {
//       const res: any = await request.get(endpointsMd.GET_TEMPLATE_TYPE_LIST, {});
//       if (res && res.status === 200 && res.data.status === "success") {
//       //  const dummyRes = await cb(res.data.data,"standard_template_list");
//         return [res.data.data,"standard_template_list"]
//       }
//     } catch (error) {
//       //throw error;
//       console.log(error)
//     }
//   };

export const getQuestionTypeListData = async (cb) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_QUESTION_TYPE_LIST, {});
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"question_type_list");
        return [res.data.data,"question_type_list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
  };

export const getBoardListData = async (cb) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_BOARD_LIST, {});
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"board_list");
        return [res.data.data,"board_list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};

export const getSubjectListData = async (cb,payload={}) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_SUBJECT_LIST,payload);
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"subject_list");
        return [res.data.data,"subject_list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};

export const getCollegeListData = async (cb,payload={}) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_COLLEGE_LIST,payload);
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"college_list");
        return [res.data.data,"college_list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};

export const getExamModelListData = async (cb,payload={}) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_EXAM_MODEL_LIST,payload);
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"college_list");
        return [res.data.data,"exam_model_list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};

export const getPatternMaskListData = async (cb,payload={}) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_PATTERN_MASK_LIST,payload);
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"college_list");
        return [res.data.data,"pattern_mask_list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};

export const getMarkingSchmaListData = async (cb,payload={}) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_MARKING_SCHMA_LIST,payload);
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"college_list");
        return [res.data.data,"marking_schema_list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};

export const getCnapsLevelListData = async (cb,payload={}) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_CNAPS_LEVEL_LIST,payload);
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"college_list");
        return [res.data.data,"cnaps_level_list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};

export const getRegistrationStatusListData = async (cb,payload={}) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_REGISTRATION_STATUS_LIST,payload);
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"college_list");
        return [res.data.data,"reservation_status_list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};

export const getPackageListData = async (cb,payload={}) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_PACKAGE_LIST,payload);
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"package_list");
        return [res.data.data,"package_list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};

export const getClassListData = async (cb,payload={}) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_CLASS_LIST,payload);
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"class_course_batch_data");
        return [res.data.data,"class_course_batch_data"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};

export const getAcademicYearListData = async (cb,payload={}) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_ACADEMIC_YEAR_LIST,payload);
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"academic_year_list");
        return [res.data.data,"academic_year_list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};

export const getBatchListData = async (cb,payload={}) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_BATCH_LIST,payload);
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"batch_list");
        return [res.data.data,"batch_list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};
export const getSubBatchListData = async (cb,payload={}) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_SUB_BATCH_LIST,payload);
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"sub_batch_list");
        return [res.data.data,"sub_batch_list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};
export const getCourseListData = async (cb,payload={}) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_COURSE_LIST,payload);
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"course_list");
        return [res.data.data,"course_list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};
export const getCourseListDependentData = async (cb,payload={}) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_COURSE_LIST,payload);
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"course_list");
        return [res.data.data,"course_list_dep"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};

export const getTemplateListData = async (cb,payload={}) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_TEMPLATE_LIST,payload);
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"course_list");
        return [res.data.data,"list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};

export const getResourceTypeListData = async (cb,payload={}) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_RESOURCE_TYPE_LIST,payload);
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"resource_type_list");
        return [res.data.data,"resource_type_list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};

export const getTemplateListFromTypeBranchData = async (cb,payload={}) => { 
    try {
      const res: any = await request.get(endpointsMd.GET_TEMPLATE_LIST,payload);
      if (res && res.status === 200 && res.data.status === "success") {
       // const dummyRes = await cb(res.data.data,"list");
        return [res.data.data,"list"]
      }
    } catch (error) {
      //throw error;
      console.log(error)
    }
};


export const getBatchSubjectList=async()=>{
  try{
    const res: any = await request.get(endpoints.GET_HIEARCHY_SUBJECTS_BY_BATCH,{batch_id: process.env.REACT_APP_GENERIC_BATCH_ID });
    if (res && res.status === 200 && res.data.status === "success") {
      return [res.data.data,"subject_chapter_topic_list"]
    }
  }
  catch(error){
  console.log(error)
  }
}

export const getChapterList=async(params)=>{
  try{
    const res: any = await request.get(endpoints.GET_HIEARCHY_CHAPTER_BY_BATCH,params);
    if (res && res.status === 200 && res.data.status === "success") {
      return [res.data.data,"chapter_list"]
    }
  }
  catch(error){
  console.log(error)
  }
}

export const getSubTopicList=async(params)=>{
  try{
    const res: any = await request.get(endpoints.GET_HIEARCHY_TOPIC_BY_BATCH,params);
    if (res && res.status === 200 && res.data.status === "success") {
      return [res.data.data,"topic_list"]
    }
  }
  catch(error){
  console.log(error)
  }
}

export const getSubjectChapterList=async(params)=>{
  try{
    const res: any = await request.get(endpointWD.GET_SUBJECT_CHAPTER_DATA,params);
    if (res && res.status === 200 && res.data.status === "success") {
      return [res.data.data,"subject_chapter_list"]
    }
  }
  catch(error){
  console.log(error)
  }
}




  const updateMetaData=(listData,metaKey)=>{
   // let metadataList = {...metaData};
   // metadataList[metaKey] = listData;
   // dispatch(metadataSuccess(metadataList))
  }

 