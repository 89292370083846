import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ILibrary, ILibraryListReqBody, IInitState } from "../interfaces";
import { getItem } from "utils/helpers";

const libraryFilters = getItem("libraryFilters");
const initialState = {
  loading: false,
  data: [],
  error: "",
  filtersData: libraryFilters ? libraryFilters : "",
};

const libraryFlipbookListSlice = createSlice({
  name: "libraryList",
  initialState,
  reducers: {
    libraryFlipbookListRequest(
      state: IInitState,
      action: PayloadAction<ILibraryListReqBody>
    ) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    libraryFlipbookListSuccess(
      state: IInitState,
      action: PayloadAction<ILibrary[]>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { libraryFlipbookListRequest, libraryFlipbookListSuccess } =
  libraryFlipbookListSlice.actions;
export default libraryFlipbookListSlice.reducer;
