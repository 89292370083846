export const actionTypes = {
  GET_COURSE_SUBJECT_LIST: "GET_COURSE_SUBJECT_LIST",
};
export const microServices = {
  NCMSV1: "ncms/v1",
  NCMSV2: process.env.REACT_APP_NCMS_MICROSERVICE,
};


export const endpoints = {
  COURSE_SUBJECT_LIST: `${microServices.NCMSV1}/usage/course-chapter-list`,
  LOCK_CHAPTERS: `${microServices.NCMSV2}/lock-chapters/update`,
  DISABLE_CHAPTERS: `${microServices.NCMSV2}/hide-chapters/update`,
  VIDEO: `${microServices.NCMSV2}/video/list`,
  QA: `${microServices.NCMSV2}/qa/get-list`,
  SYNOPSIS: `${microServices.NCMSV2}/synopsis/get-list`,
  SUMMARY: `${microServices.NCMSV2}/summary/get-list`,
  LEARNRESOURCE: `${microServices.NCMSV2}/learn-resource/list`,
  GET_COURSE_SYLLABUS_DATA: `${microServices.NCMSV2}/usage/course-syllabus-metadata`,
  UPLOAD_RESOURCE: `${microServices.NCMSV2}/learn-resource/create`,
  UPLOAD_VIDEO: `${microServices.NCMSV2}/video/create`,
  CREATE_SUMMARY: `${microServices.NCMSV2}/summary/create`,
  CREATE_QA: `${microServices.NCMSV2}/qa/create`,
  CREATE_SYNOPSIS: `${microServices.NCMSV2}/synopsis/create`,
  GET_SYNOPSIS_CONTENT: `${microServices.NCMSV2}/synopsis/get-content`,
  GET_QA_CONTENT: `${microServices.NCMSV2}/qa/get-content`,
  GET_SUMMARY_CONTENT: `${microServices.NCMSV2}/summary/get-content`,


  //delete
  DELETE_QA: `${microServices.NCMSV2}/qa/delete`,
  DELETE_SYNOPSIS: `${microServices.NCMSV2}/synopsis/delete`,
  DELETE_SUMMARY: `${microServices.NCMSV2}/summary/delete`,
  DELETE_LEARNRESOURCE: (id) => `${microServices.NCMSV2}/learn-resource/delete/${id}`,
  DELETE_VIDEO: (id) => `${microServices.NCMSV2}/video/delete/${id}`,
  //update
  UPDATE_VIDEO: `${microServices.NCMSV2}/video/update`,
  UPDATE_SYNOPSIS: `${microServices.NCMSV2}/synopsis/update`,
  UPDATE_RESOURCES: `${microServices.NCMSV2}/learn-resource/update`,
  UPDATE_QA: `${microServices.NCMSV2}/qa/update`,
  UPDATE_SUMMARY: `${microServices.NCMSV2}/summary/update`,
  //by id
  GET_VIDEO_DATA: `${microServices.NCMSV2}/video/video-data`,
  GET_RESOURCE_DATA: `${microServices.NCMSV2}/learn-resource/get-details-by-id`,
  //embibe
  CREATE_EMBIBE_RESOURCE:`${microServices.NCMSV2}/embibe/create-resource`,
  EMBIBE_RESOURCE_LIST:`${microServices.NCMSV2}/embibe/resource-list`,
  EMBIBE_RESOURCE_DATA:`${microServices.NCMSV2}/embibe/resource-data`,
  UPDATE_EMBIBE_RESOURCE:`${microServices.NCMSV2}/embibe/update-resource`,
  DELETE_EMBIBE_RESOURCE:`${microServices.NCMSV2}/embibe/delete-resource`,
};
