import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAnnouncement, IAnnouncementByIdReqBody, IAnnouncementData } from "../interfaces";

interface IInitState {
  loading: boolean;
  data: any;
  error: string;
}

const initialState = {
  loading: false,
  data: {},
  error: "",
};

const announcementByIdSlice = createSlice({
  name: "announcementById",
  initialState,
  reducers: {
    getAnnouncementByIdRequest(
      state: IInitState,
      action: PayloadAction<IAnnouncementByIdReqBody>
    ) {
      state.loading = true;
    },
    getAnnouncementByIdSuccess(
      state: IInitState,
      action: PayloadAction<IAnnouncement[]>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
    resetAnnouncementById(state: IInitState) {
      state.data = [];
    },
  },
});

export const {
  getAnnouncementByIdRequest,
  getAnnouncementByIdSuccess,
  resetAnnouncementById,
} = announcementByIdSlice.actions;
export default announcementByIdSlice.reducer;
