import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {ICreateWarmUpDeliveryReqBody } from '../interfaces'


interface IInitState {
  loading: boolean;
  data: any;
  error: string;
}

const initialState = {
  loading: false,
  data: "",
  error: "",
};

const createWarmUpDeliverySlice = createSlice({
  name: "createwarmupdelivery",
  initialState,
  reducers: {
    createWarmUpDeliveryRequest(
      state: IInitState,
      action: PayloadAction<ICreateWarmUpDeliveryReqBody>
    ) {
      state.loading = true;
    },
    createWarmUpDeliverySuccess(state: IInitState, action) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { createWarmUpDeliveryRequest, createWarmUpDeliverySuccess } =
createWarmUpDeliverySlice.actions;
export default createWarmUpDeliverySlice.reducer;
