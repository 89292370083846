import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {IAssignment, IAssignmentListReqBody} from 'pages/assignments/interfaces'
import { assignmentInitialValues } from "../routes/create-assignment/assignment-form/helper";
import {IInitState} from 'pages/assignments/interfaces'

const initialState = {
  loading: false,
  dataLoading: false,
  filtersData: { start_date: "", end_date: "", class_id: "", subject_id: "" },
  data: [],
  error: "",
  assignmentData: assignmentInitialValues,
};

const assignmentListSlice = createSlice({
  name: "assignmentList",
  initialState,
  reducers: {
    assignmentListRequest(
      state: IInitState,
      action: PayloadAction<IAssignmentListReqBody>
    ) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    assignmentDataRequest(
      state: IInitState,
      action: PayloadAction<{ assignment_id: string | number }>
    ) {
      state.dataLoading = true;
    },
    resetAssignmentData(state: IInitState) {
      state.assignmentData = assignmentInitialValues;
    },
    assignmentDataSuccess(state: IInitState, action: any) {
      state.dataLoading = false;
      state.assignmentData = action.payload;
    },
    assignmentListSuccess(
      state: IInitState,
      action: PayloadAction<IAssignment[]>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
    resetAssignmentList(state){
      state.data=[]
    }
  },
});

export const {
  assignmentListRequest,
  assignmentListSuccess,
  assignmentDataRequest,
  assignmentDataSuccess,
  resetAssignmentData,
  resetAssignmentList
} = assignmentListSlice.actions;
export default assignmentListSlice.reducer;
