import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: [],
  error: "",
  filtersData: "",
};

const deliveryDataSlice = createSlice({
  name: "assignmentDeliveryData",
  initialState,
  reducers: {
    assignmentDeliveryDataRequest(state, action) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    assignmentDeliveryDataSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    resetDeliveryData(state){
      state.data=[]
    }
  },
});

export const { assignmentDeliveryDataRequest, assignmentDeliveryDataSuccess, resetDeliveryData } =
  deliveryDataSlice.actions;

export default deliveryDataSlice.reducer;
