import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IBuildTestListReqBody } from "../interfaces";
interface IInitState {
  loading: boolean;
  validateData: {};
  error: string;
  filtersData: any;
}

const initialState = {
  loading: false,
  validateData: {},
  error: "",
  filtersData: "",
};

const validateWarmUpSlice = createSlice({
  name: "ValidateWarmUpId",
  initialState,
  reducers: {
    validateWarmUpDeliveryIdRequest(
      state: IInitState,
      action: PayloadAction<any>
    ) {
      state.loading = true;
    },
    validateWarmUpDeliveryIdSuccess(
      state: IInitState,
      action: PayloadAction<any[]>
    ) {
      state.loading = false;
      state.validateData = action.payload;
    },
    getValidateWarmUpDataError(state: any, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    makeErrorEmpty(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = "";
    },
  },
});

export const {
  validateWarmUpDeliveryIdRequest,
  validateWarmUpDeliveryIdSuccess,
  getValidateWarmUpDataError,
  makeErrorEmpty,
} = validateWarmUpSlice.actions;
export default validateWarmUpSlice.reducer;
