import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { takeLatest, put, call, putResolve } from "redux-saga/effects";
import HierarchiesService from "./service";
import {
  getViewHierarchyMetricsRequest,
  getViewHierarchyMetricsSuccess,
} from "./slices/get-hierarchy-metrics-slice";
import {
  getMapHierarchyListRequest,
  getMapHierarchyListSuccess,
  getMapHierarchyListMessage,
  getMapHierarchyListError,
  getMapHierrachyListNoMappingId,
} from "./slices/get-map-hierarchy-list";
import { ResponseGenerator } from "interfaces";
import {
  createHierarchyMapRequest,
  createHierarchyMapSuccess,
  getMapHierarchyErrorMessage,
} from "./slices/create-hierarchy-map";
import {
  getMappedListByPracticeRequest,
  getMappedListByPracticeSuccess,
} from "./slices/get-mapped-list-by-practice-format";

function* getViewHierarchyMetricsWorker(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      HierarchiesService.getViewHierarchyMetrics,
      action.payload
    );
    if (res.status == 200 && res.data.status == "success") {
      yield put(getViewHierarchyMetricsSuccess(res.data.data));
    } else {
      yield put(snackbarRequest({ message: res.data.message }));
    }
  } catch (e) {}
}

function* getMapHierarchyListWorker(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      HierarchiesService.getMapHierarchyListData,
      action.payload
    );
    if (res.status == 200 && res.data.status == "success") {
      yield put(getMapHierarchyListSuccess(res.data.data));
      yield put(getMapHierarchyListMessage(res.data.message));
      yield snackbarRequest({ message: res.data.message });
    } else {
      yield put(getMapHierarchyListError(res.data.message));
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (e) {}
}

function* createHierarchyMapWorker(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      HierarchiesService.createHierarchyMap,
      action.payload
    );
    if (res.status == 200 && res.data.status == "success") {
      yield put(getMapHierarchyErrorMessage(res.data.message));
      yield put(createHierarchyMapSuccess(res.data.data));
    } else if (res.data.status == "Error") {
      yield put(getMapHierarchyErrorMessage(res.data.message));
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (e) {}
}

function* getMappedListByPracticeWorker(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      HierarchiesService.getMappedListByPracticeFormat,
      action.payload
    );
    if (res.status == 200 && res.data.status == "success") {
      yield put(getMappedListByPracticeSuccess(res.data.data));
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch {}
}

export default function* hierarchiesSaga() {
  yield takeLatest(
    getViewHierarchyMetricsRequest.type,
    getViewHierarchyMetricsWorker
  );
  yield takeLatest(getMapHierarchyListRequest.type, getMapHierarchyListWorker);
  yield takeLatest(createHierarchyMapRequest.type, createHierarchyMapWorker);
  yield takeLatest(
    getMappedListByPracticeRequest.type,
    getMappedListByPracticeWorker
  );
}
