import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitState {
  loading: boolean;
  idsData: any;
  error: string;
}

const initialState = {
  loading: false,
  error: "",
  idsData: [],
};

const getMappedListByPracticeSlice = createSlice({
  name: "hierarchymetrics",
  initialState,
  reducers: {
    getMappedListByPracticeRequest(
      state: IInitState,
      action: PayloadAction<any>
    ) {
      state.loading = true;
    },
    getMappedListByPracticeSuccess(
      state: IInitState,
      action: PayloadAction<any>
    ) {
      state.loading = false;
      state.idsData = action.payload;
    },
  },
});

export const {
  getMappedListByPracticeRequest,
  getMappedListByPracticeSuccess,
}: any = getMappedListByPracticeSlice.actions;
export default getMappedListByPracticeSlice.reducer;
