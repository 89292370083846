import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: [],
  examDetails: {},
  examsList: [],
  error: "",
  previewQuestions: {},
};

const previewDataSlice = createSlice({
  name: "previewData",
  initialState,
  reducers: {
    getPreviewDataRequest(state, action) {
      state.loading = true;
    },
    getPreviewDataSuccess(state, action) {
      state.data = action.payload;
      state.loading = false;
    },
    setPreviewData(state, action) {
      state.data = action.payload;
    },
    setExamDetailsData(state, action) {
      state.examDetails = action.payload;
    },
    setExamDetailsList(state, action) {
      state.examsList = action.payload;
    },
    setPreviewQuestions(state, action) {
      state.previewQuestions = action.payload;
    },
  },
});

export const {
  getPreviewDataRequest,
  getPreviewDataSuccess,
  setPreviewData,
  setExamDetailsData,
  setExamDetailsList,
  setPreviewQuestions,
} = previewDataSlice.actions;

export default previewDataSlice.reducer;
