import { lazyLoad } from "utils/loadable";

const HierarchiesPage = lazyLoad(
  () => import("./index"),
  (module:any) => module.default?module.default : module.HierarchiesPage,
  {
    fallback: (
      <div>
        <p>Loading....</p>
      </div>
    ),
  }
);

export default HierarchiesPage;
