import { createSlice } from "@reduxjs/toolkit";
import { IInitState } from "pages/assignments/interfaces";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

const classListSlice = createSlice({
  name: "classList",
  initialState,
  reducers: {
    classListRequest(state: IInitState) {
      state.loading = true;
    },
    classListSuccess(state: IInitState, action) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { classListRequest, classListSuccess } = classListSlice.actions;
export default classListSlice.reducer;
