import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IGetQuestionDetails } from "../interfaces";
interface IInitState {
  loading: boolean;
  data: any;
  subGroupList: any;
  subGroupById: any;
  error: string;
  filtersData: any;
}

const initialState = {
  loading: false,
  data: {},
  subGroupList: false,
  subGroupById: false,
  error: "",
  filtersData: "",
};

const getQuestionGroupListDetailsByIDSlice: any = createSlice({
  name: "getQuestionDetails",
  initialState,
  reducers: {
    getQuestionGroupListDetailsByIDRequest(
      state: IInitState,
      action: PayloadAction<IGetQuestionDetails>
    ) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    getQuestionGroupListDetailsByIDSuccess(
      state: IInitState,
      action: PayloadAction<any>
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.subGroupById = action.payload.subGroupById;
      state.subGroupList = action.payload.subGroupList;
    },
    addNewSubGroup(state: IInitState, action: PayloadAction<any>) {
      state.subGroupById = action.payload.subGroupById;
      state.subGroupList = action.payload.subGroupList;
    },
    updateSubGroup(state: IInitState, action: PayloadAction<any>) {
      state.subGroupById = action.payload.subGroupById;
      state.subGroupList = action.payload.subGroupList;
    },
  },
});

export const {
  getQuestionGroupListDetailsByIDRequest,
  getQuestionGroupListDetailsByIDSuccess,
  addNewSubGroup,
  updateSubGroup,
} = getQuestionGroupListDetailsByIDSlice.actions;
export default getQuestionGroupListDetailsByIDSlice.reducer;
