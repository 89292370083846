import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IActivitiesListState,
  IActivitiesListReqBody,
  IActivitiesFunsheetData,
} from "pages/activities/interfaces";

const initialState: IActivitiesListState = {
  loading: false,
  data: [],
  error: "",
  filtersData: {
    start_date: "",
    class_id: "",
    end_date: "",
    activity_type: "",
  },
};

const funSheetListSlice = createSlice({
  name: "funSheetList",
  initialState,
  reducers: {
    funSheetListRequest(state, action: PayloadAction<IActivitiesListReqBody>) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    funSheetListSuccess(
      state,
      action: PayloadAction<IActivitiesFunsheetData[]>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
    resetFunSheetList(state) {
      state.data = [];
      state.filtersData = {
        start_date: "",
        class_id: "",
        end_date: "",
        activity_type: "",
      };
    },
  },
});

export const { funSheetListRequest, funSheetListSuccess, resetFunSheetList } =
  funSheetListSlice.actions;
export default funSheetListSlice.reducer;
