import { createSlice } from "@reduxjs/toolkit";
// import { deliveryInitialValues } from "./helper";

import {deliveryInitialValues} from '../routes/create-term-exam/term-exam-delivery/helper'

const initialState = {
  loading: false,
  updateLoading: false,
  deliveryData: deliveryInitialValues,
  error: "",
};

const termExamDeliveryUpdateSlice = createSlice({
  name: "termExamDeliveryUpdate",
  initialState: initialState,
  reducers: {
    updateTermExamDeliveryRequest(state, action) {
      state.updateLoading = true;
    },
    getTermExamDeliveryRequest(state, action) {
      state.loading = true;
    },
    getTermExamDeliverySuccess(state, action) {
      state.loading = false;
      state.deliveryData = action.payload;
    },
  },
});
export const {
  getTermExamDeliveryRequest,
  getTermExamDeliverySuccess,
  updateTermExamDeliveryRequest,
} = termExamDeliveryUpdateSlice.actions;
export default termExamDeliveryUpdateSlice.reducer;
