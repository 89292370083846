import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IActivitiesMagazineDetailsInitialState,
  IActivitiesMagazineData,
  IActivitiesMagazineDetailsReqBody,
} from "pages/activities/interfaces";

const initialState: IActivitiesMagazineDetailsInitialState = {
  loading: false,
  data: {},
  error: "",
};

const magazineDetailsSlice = createSlice({
  name: "magazineDetails",
  initialState,
  reducers: {
    magazineDetailsRequest(
      state,
      action: PayloadAction<IActivitiesMagazineDetailsReqBody>
    ) {
      state.loading = true;
    },
    magazineDetailsSuccess(
      state,
      action: PayloadAction<IActivitiesMagazineData>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
    resetmagazineDetails(state){
      state.data = {}
    }
  },
});

export const { magazineDetailsRequest, magazineDetailsSuccess, resetmagazineDetails } =
  magazineDetailsSlice.actions;
export default magazineDetailsSlice.reducer;
