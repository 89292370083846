import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitState {
  isLoading: boolean;
  data: any;
  error: any;
}

const initialState: IInitState = {
  isLoading: false,
  data: false,
  error: false,
};

const questionGroupItemListSlice = createSlice({
  name: "questionGroupItemList",
  initialState: initialState,
  reducers: {
    questionGroupItemListRequest(
      state: IInitState,
      action: PayloadAction<any>
    ) {
      state.isLoading = true;
      state.data = action.payload;
    },
    questionGroupItemListSuccess(
      state: IInitState,
      action: PayloadAction<any>
    ) {
      state.isLoading = false;
      state.data = action.payload;
    },
    updateSubGroupById(state: IInitState, action: PayloadAction<any>) {
      state.data.subGroupById = action.payload;
    },
  },
});

export const {
  questionGroupItemListRequest,
  questionGroupItemListSuccess,
  updateSubGroupById,
} = questionGroupItemListSlice.actions;

export default questionGroupItemListSlice.reducer;
