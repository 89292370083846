import { lazyLoad } from "utils/loadable";

const LoginPage = lazyLoad(
  () => import("./index"),
  (module) => module.LoginPage,
  {
    fallback: (
      <div>
        <p>Loading....</p>
      </div>
    ),
  }
);

export default LoginPage;
