import React, { useEffect } from "react";
import { getEnableFeatures } from "../sidebar/helpers";
import NavItem from "shared/atoms/nav-item";
import NavCollapse from "shared/molecules/nav-collapse";
import List from "@mui/material/List";
import Profile from "../sidebar/profile";
import { useAppSelector } from "hooks/redux";

function Panel(props: any) {
  const { data } = useAppSelector((state) => state.featureConfig);
  const { panelStatus } = props;

  const features = getEnableFeatures(data);

  return (
    <div
      style={{
        backgroundColor: "#0F172B",
        color: "#fff",
        height: "100%",
      }}
    >
      <List component="nav" aria-labelledby="nested-list-subheader">
        <Profile hideUser={true} hideLogo={false} />
        {features.length
          ? features.map((item: any) => {
              if (item.type === "item")
                return <NavItem key={item.name} navItemData={item} />;
              return (
                <NavCollapse
                  key={item.name}
                  navItemData={item}
                  panelStatus={panelStatus}
                />
              );
            })
          : null}
      </List>
    </div>
  );
}

export default Panel;
