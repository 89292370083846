import { takeLatest, call, put } from "redux-saga/effects";
import { ResponseGenerator } from "interfaces";
import {
  IScheduleListReqBody,
  IMonthlyScheduleDeleteReqBody,
} from "./interfaces";
import { PayloadAction } from "@reduxjs/toolkit";
import monthlyScheduleService from "./service";
import { actionTypes } from "./constants";
import {
  scheduleListRequest,
  scheduleListSuccess,
  getScheduleDataRequest,
  getScheduleDataSuccess,
} from "./slices/schedule-list-slice";
import {
  createMonthlyScheduleRequest,
  createMonthlyScheduleSuccess,
} from "./slices/create-montly-schedule-slice";

import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { replace } from "connected-react-router";

import { ICreateMontlyScheduleWorker } from "pages/monthly-schedule/interfaces";

//---------------------Monthly Schedule-----------------------------

function* createMonthlyScheduleWorker(
  action: PayloadAction<ICreateMontlyScheduleWorker>
) {
  try {
    const { reqBody, setValue, fieldValues } = action.payload;
    const res: ResponseGenerator = yield call(
      monthlyScheduleService.createMonthlyScheduleApi,
      reqBody
    );
    if (res && res.status === 200 && res.data.status === "success") {
      yield put(
        snackbarRequest({
          message: reqBody.schedule_id
            ? "updated successfully"
            : "created successfully",
        })
      );
      yield put(createMonthlyScheduleSuccess(res.data.data));
      if (!reqBody.schedule_id) {
        setValue("academic_year_id", "");
        setValue("batch_id", "");
        setValue("board_id", "");
        setValue("class_id", "");
        setValue("course_id", "");
        setValue("pdf_name", "");
        setValue("state_id", "");
        setValue("week_number", "");
        setValue("sub_batch_id", "");
        setValue("start_date", undefined);
        setValue("end_date", undefined);
      }
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
    yield put(
      replace(
        `/monthly-schedule/list/${fieldValues.class_id}/${fieldValues.course_id}/${fieldValues.batch_id}`
      )
    );
  } catch (e) {
    console.log("check error", e);
  }
}

function* scheduleListWorker(action: PayloadAction<IScheduleListReqBody>) {
  try {
    const res: ResponseGenerator = yield call(
      monthlyScheduleService.monthlyScheduleListApi,
      action.payload
    );
    yield put(scheduleListSuccess(res.data.data));
  } catch (e) {
    console.log("check error", e);
  }
}

function* getScheduleDataWorker(
  action: PayloadAction<ICreateMontlyScheduleWorker>
) {
  try {
    const { reqBody, setValue } = action.payload;
    const res: ResponseGenerator = yield call(
      monthlyScheduleService.getMonthlyScheduleDataApi,
      reqBody
    );
    if (res && res.status === 200 && res.data.status === "success") {
      const { data } = res.data;
      yield put(getScheduleDataSuccess(data));
      setValue("academic_year_id", data.academic_year_id);
      setValue("batch_id", data.batch_id);
      setValue("board_id", data.board_id);
      setValue("class_id", data.class_id);
      setValue("course_id", data.course_id);
      setValue("pdf_name", data.pdf_name);
      setValue("state_id", data.state_list);
      setValue("week_number", data.week_number);
      setValue("start_date", data.start_date);
      setValue("end_date", data.end_date);
      setValue("sub_batch_id", data.sub_batch_list);
    }
  } catch (e) {
    console.log("check error", e);
  }
}

function* deleteMonthlySchedule(
  action: PayloadAction<IMonthlyScheduleDeleteReqBody>
) {
  try {
    const res: ResponseGenerator = yield call(
      monthlyScheduleService.deleteMonthlyScheduleApi,
      action.payload
    );
    if (res && res.status === 200 && res.data.status === "success") {
      yield put(snackbarRequest({ message: "deleted successfully" }));
      const { batch_id, class_id, course_id } = action.payload;
      yield put(
        scheduleListRequest({
          batch_id: batch_id,
          class_id: class_id,
          course_id: course_id,
        })
      );
    }
  } catch (e) {}
}

//---------------------Monthly Schedule List Worker-----------------------------

export default function* monthlyScheduleSaga() {
  yield takeLatest(actionTypes.DELETE_MONTHLY_SCHEDULE, deleteMonthlySchedule);
  yield takeLatest(scheduleListRequest.type, scheduleListWorker);
  yield takeLatest(getScheduleDataRequest.type, getScheduleDataWorker);
  yield takeLatest(
    createMonthlyScheduleRequest.type,
    createMonthlyScheduleWorker
  );
}
