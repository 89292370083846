import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IChapterTopicList, IChapterTopicListRequestBody, ILockChapter, ITopicListInitialState } from "../interfaces";

const initialState = {
  loading: false,
  error: "",
  data: [],
  lockChapterLoading: false,
  lockChapterEror: "",
};


const disableChapterSlice = createSlice({
  name: "learn",
  initialState,
  reducers: {
    chapterDisableListRequest(state: ITopicListInitialState, action: PayloadAction<IChapterTopicList>) {
      state.loading = true;
    },
    chapterDisableListSuccess(state: ITopicListInitialState, action: PayloadAction<IChapterTopicListRequestBody>) {
      state.data = action.payload;
    },
    postDisableChaptersRequest(state: ITopicListInitialState, action: PayloadAction<ILockChapter>) {
      state.lockChapterLoading = true;
    },
    postDisableChaptersSuccess(state: ITopicListInitialState) {
      return state;
    },
    resetTopicList(state: ITopicListInitialState) {
      state.data = []
    }
  },
}
);
export const {
    chapterDisableListRequest,
    chapterDisableListSuccess,
    postDisableChaptersRequest,
    postDisableChaptersSuccess,
  resetTopicList
} = disableChapterSlice.actions;
export default disableChapterSlice.reducer;