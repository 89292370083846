import {endpoints} from './constants'
import request from "utils/axios/service";
import {IActivitiesCreateFunsheetReqBody, IActivitiesUpdateFunsheetReqBody, IActivitiesCreateMagazineReqBody, IActivitiesUpdateMagazineReqBody, IActivitiesListReqBody, IActivitiesMagazineDetailsReqBody, IActivitesFunsheetDetailsReqBody} from 'pages/activities/interfaces'


export default class ActivitiesService {

    static createFunSheetApi(payload:IActivitiesCreateFunsheetReqBody) {
      return request.post(endpoints.CREATE_FUN_SHEET, payload);
    }
    static getFunSheetList(payload:IActivitiesListReqBody){
      return request.get(endpoints.FUN_SHEET_LIST, payload);
    }
    static getFunSheetDetails(payload:IActivitesFunsheetDetailsReqBody){
      return request.get(endpoints.GET_FUN_SHEET_DETAILS, payload);
    }
    static updateFunSheetApi(payload:IActivitiesUpdateFunsheetReqBody){
      return request.post(endpoints.UPDATE_FUN_SHEET, payload);
    }

    static createMagazineApi(payload:IActivitiesCreateMagazineReqBody){
      return request.post(endpoints.CREATE_MAGAZINE, payload)
    }
    static getMagazineListApi(payload:IActivitiesListReqBody){
      return request.get(endpoints.MAGAZINE_LIST, payload)
    }
    static updateMagazineApi(payload:IActivitiesUpdateMagazineReqBody){
      return request.post(endpoints.UPDATE_MAGAZINE, payload)
    }
    static getMagazineDetailsApi(payload:IActivitiesMagazineDetailsReqBody){
      return request.get(endpoints.GET_MAGAZINE_DETAILS, payload)
    }
    
  }