import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import loginReducer from "pages/login/slices";
import gmailLoginReducer from "pages/login/gmail-slice";
import monthlyScheduleListReducer from "pages/monthly-schedule/slices/schedule-list-slice";
import assignmentListReducer from "pages/assignments/slices/assignment-list-slice";
import assignmentDeliveryListSliceReducer from "pages/assignments/slices/delivery-list-slice";
import termExamListReducer from "pages/term-exams/slices/term-exam-list-slices";
import termExamDeliveryListSliceReducer from "pages/term-exams/slices/deliver-list-slice";
import libraryResourceListReducer from "pages/library/slices/library-resource-list-slice";
import libraryVideoListReducer from "pages/library/slices/library-video-list-slice";
import announcementListReducer from "pages/announcements/slices/announcement-list-slice";

import history from "../history";
import classCourseMetaSliceReducer from "pages/assignments/slices/class-course-meta-slice";
import createAssignementListSliceReducer from "pages/assignments/slices/assignment-list-slice";
import subjectListSliceReducer from "pages/assignments/slices/subject-list-slice";

import listAssignmentSliceReducer from "pages/term-exams/slices/list-assignment-slice";
import newListAssignmentSliceReducer from "pages/term-exams/slices/new-list-assignment";

import createMonthlyScheduleReducer from "pages/monthly-schedule/slices/create-montly-schedule-slice";
import createTermExamReducer from "pages/term-exams/slices/create-term-exam-slice";

import createAnnouncementReducer from "pages/announcements/slices/create-announcement-slice";
import assignmentDeliveryReducer from "pages/assignments/slices/update-delivery-slice";

import createLibraryVideoReducer from "pages/library/slices/create-video-slice";
import createLibraryResourceReducer from "pages/library/slices/create-resource-slice";

import termExamDeliveryReducer from "pages/term-exams/slices/update-term-exam-delivery";

import announcementDeliveryListSliceReducer from "pages/announcements/slices/delivery-list-slice";
import termExamDeliveryDataReducer from "pages/term-exams/slices/delivery-data-slice";
import assignmentDeliveryDataReducer from "pages/assignments/slices/delivery-data-slice";

import buildTestListReducer from "pages/build-test/slices/buil-test-list-slice";
import buildDeliveryListReducer from "pages/build-test/slices/build-delivery-list-slice";
import createBuildDeliveryReducer from "pages/build-test/slices/create-build-delivery-slice";
import createBuildTestReducer from "pages/build-test/slices/create-build-test-slice";
import getAnnouncementByIdReducer from "pages/announcements/slices/get-announcement-by-id-slice";
import getAnnouncementDeliveryByIdReducer from "pages/announcements/slices/get-announcement-delivery-by-id-slice";
import getBuildTestDeliveryByIdReducer from "pages/build-test/slices/get-delivery-by-id";
import getBuildTestAnswerKeyReducer from "pages/build-test/slices/get-answer-key";
import refreshDeliveryReducer from "pages/build-test/slices/refresh-delivery-request";
import testDetailsByIdSlice from "pages/build-test/slices/test-details-by-id-slice";
import questionGroupItemListSlice from "pages/build-test/slices/question-group-item-list-slice";
import viewQuestionSlice from "pages/build-test/slices/view-question-details";
import createQuestionSlice from "pages/build-test/slices/question-creation-slice";
import questionEditorsSlice from "pages/build-test/slices/question-editors-slice";
import getOqbQuestionPropertiesByIDSlice from "pages/build-test/slices/fetch-oqb-question-slice";

// preview
import previewDataReducer from "pages/term-exams/slices/get-preview-data-slice";

//learn
import chapterTopicsListReducer from "pages/learn/slices/chapter-topics-list-slice";
import disableChapterListReducer from "pages/learn/slices/disable-chapter-list-slice";

import learnListReducer from "pages/learn/slices/learn-list-slice";
import uploadLearnTopicsSlice from "pages/learn/slices/upload-learn-topics-slice";
import updateLearnSlice from "pages/learn/slices/update-learn-slice";

import snackbarReducer from "shared/molecules/snackbar/snackbar-slice";

import previewSliceReducer from "pages/build-test/slices/preview-slice";
import questionGroupListSlice from "pages/build-test/slices/get-question-group-list";
import subQuestionGroupListSlice from "pages/build-test/slices/get-sub-question-group-list";
import subQuestionGroupItemListSlice from "pages/build-test/slices/get-sub-question-item-list";
import getQuestionDetailsByID from "pages/build-test/slices/get-question-details-by-id";
import createSubQuestionGroupItemSlice from "pages/build-test/slices/create-sub-question-group-item-slice";
import createSubQuestionGroupSlice from "pages/build-test/slices/create-sub-question-group";
import updateQuestionGroupSlice from "pages/build-test/slices/update-question-group-slice";
import updateSubQuestionGroupSlice from "pages/build-test/slices/update-sub-question-group-id-slice";
import getQuestionGroupListDetailsByIDSlice from "pages/build-test/slices/get-question-group-list-details-by-id";
import updateSubQuestionGroupItemSlice from "pages/build-test/slices/update-sub-question-group-item";
import createQuestionGroupSlice from "pages/build-test/slices/create-question-group-slice";
import addContentSlice from "pages/build-test/slices/add-content-slice";
import saveSectionWiseQuestionsSlice from "pages/build-test/slices/save-section-wise-questions";
import paragraphQuestionsSlice from "pages/build-test/slices/paragraph-question";

import warmUpTestListSlice from "pages/warm-up-delivery/slices/warm-up-list-slice";
import warmUpTestDeliveryListSlice from "pages/warm-up-delivery/slices/warm-up-delivery-slice";
import createWarmUpDeliverySlice from "pages/warm-up-delivery/slices/create-warm-up-slice";
import validateWarmUpSlice from "pages/build-test/slices/validate-warm-up-slice";
import validateTestIdSlice from "pages/build-test/slices/validate-test-id";
//fun sheets
import funSheetDetailsSliceReducer from "pages/activities/slices/fun-sheet-details.slice";
import funSheetListSliceReducer from "pages/activities/slices/fun-sheet-list-slice";
import updateWarmUpDeliverySlice from "pages/warm-up-delivery/slices/update-warm-up-slice";

//magazine
import magazineListReducer from "pages/activities/slices/magazine-list-slice";
import magazineDetailsReducer from "pages/activities/slices/magazine-details-slice";
import hierarchyMetricsSliceReducer from "pages/hierarchies/slices/get-hierarchy-metrics-slice";
import mapHierarchyListSliceReducer from "pages/hierarchies/slices/get-map-hierarchy-list";
import createHierarchyMapSliceReducer from "pages/hierarchies/slices/create-hierarchy-map";
import getMappedListByPracticeSliceReducer from "pages/hierarchies/slices/get-mapped-list-by-practice-format";
import TemplateListSlice from "pages/build-test/slices/get-template-list-slice";
import TemplateDetailsSlice from "pages/build-test/slices/get-template-details";
import deleteSubQuestionGroupSlice from "pages/build-test/slices/delete-sub-question-group";
import getHierarchyStatusSlice from "pages/build-test/slices/get-hierarchy-status-slice";

//feature config
import featureConfigSlice from "app-component/slices/feature-config-slice";
import metadataSliceReducer from "app-component/slices/metadata-slice";

const createRootReducer = combineReducers({
  router: connectRouter(history),
  metadataReducer: metadataSliceReducer,
  auth: loginReducer,
  gmailLoginReducer,
  assignmentList: assignmentListReducer,
  monthlyScheduleList: monthlyScheduleListReducer,
  assignmentDeliveryList: assignmentDeliveryListSliceReducer,
  classCourseMetaList: classCourseMetaSliceReducer,
  createAssignmentList: createAssignementListSliceReducer,
  subjectList: subjectListSliceReducer,
  termExamList: termExamListReducer,
  termExamDeliveryList: termExamDeliveryListSliceReducer,
  libraryList: libraryResourceListReducer,
  libraryVideoList: libraryVideoListReducer,
  anouncementList: announcementListReducer,
  listAssignment: listAssignmentSliceReducer,
  newListAssignment: newListAssignmentSliceReducer,
  createMonthlySchedule: createMonthlyScheduleReducer,
  createTermExam: createTermExamReducer,
  createAnnouncement: createAnnouncementReducer,
  createLibarayVideo: createLibraryVideoReducer,
  createLibraryResource: createLibraryResourceReducer,
  assignmentDelivery: assignmentDeliveryReducer,
  termExamDelivery: termExamDeliveryReducer,
  announcementDeliveryList: announcementDeliveryListSliceReducer,
  termExamDeliveryData: termExamDeliveryDataReducer,
  assignmentDeliveryData: assignmentDeliveryDataReducer,
  previewDataReducer: previewDataReducer,
  buildTestList: buildTestListReducer,
  buildDeliveryList: buildDeliveryListReducer,
  createBuildDelivery: createBuildDeliveryReducer,
  createBuildTest: createBuildTestReducer,
  validateWarmUp: validateWarmUpSlice,
  chapterTopicsListReducer: chapterTopicsListReducer,
  disableChapterListReducer:disableChapterListReducer,
  learnListReducer: learnListReducer,
  getAnnouncementById: getAnnouncementByIdReducer,
  getAnnouncementDeliveryById: getAnnouncementDeliveryByIdReducer,
  getBuildTestDeliveryById: getBuildTestDeliveryByIdReducer,
  getBuildTestAnswerKey: getBuildTestAnswerKeyReducer,
  uploadLearnTopicsReducer: uploadLearnTopicsSlice,
  updateLearnList: updateLearnSlice,
  snackBar: snackbarReducer,
  previewSliceReducer: previewSliceReducer,
  warmUpTestList: warmUpTestListSlice,
  warmUpTestDeliveryList: warmUpTestDeliveryListSlice,
  createWarmUpDelivery: createWarmUpDeliverySlice,
  updateWarmUpDelivery: updateWarmUpDeliverySlice,
  funSheetDetailsSliceReducer: funSheetDetailsSliceReducer,
  funSheetListSliceReducer: funSheetListSliceReducer,
  magazineListReducer: magazineListReducer,
  magazineDetailsReducer: magazineDetailsReducer,
  refreshDeliveryReducer: refreshDeliveryReducer,
  hierarchyMetricsList: hierarchyMetricsSliceReducer,
  mapHierarchyList: mapHierarchyListSliceReducer,
  createHierarchyMapSlice: createHierarchyMapSliceReducer,
  getMappedListByPracticeSlice: getMappedListByPracticeSliceReducer,
  TemplateListSlice: TemplateListSlice,
  TemplateDetailsSlice: TemplateDetailsSlice,
  testDetailsByIdSlice,
  questionGroupItemListSlice,
  viewQuestionSlice,
  questionGroupListSlice: questionGroupListSlice,
  subQuestionGroupListSlice: subQuestionGroupListSlice,
  subQuestionGroupItemListSlice: subQuestionGroupItemListSlice,
  getQuestionDetailsByID: getQuestionDetailsByID,
  createSubQuestionGroupItemSlice: createSubQuestionGroupItemSlice,
  createSubQuestionGroupSlice: createSubQuestionGroupSlice,
  updateQuestionGroupSlice: updateQuestionGroupSlice,
  updateSubQuestionGroupSlice: updateSubQuestionGroupSlice,
  getQuestionGroupListDetailsByIDSlice: getQuestionGroupListDetailsByIDSlice,
  updateSubQuestionGroupItemSlice: updateSubQuestionGroupItemSlice,
  createQuestionGroupSlice: createQuestionGroupSlice,
  createQuestionSlice,
  questionEditorsSlice,
  addContentSlice,
  getOqbQuestionPropertiesByIDSlice,
  paragraphQuestionsSlice,
  saveSectionWiseQuestionsSlice,
  featureConfig: featureConfigSlice,
  deleteSubQuestionGroupSlice,
  getHierarchyStatus: getHierarchyStatusSlice,
  validateTestId: validateTestIdSlice
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "USER_LOGOUT") {
    localStorage.removeItem("token");
    localStorage.removeItem("metadata");
    return createRootReducer(undefined, action);
  }

  return createRootReducer(state, action);
};
export default rootReducer;
// export default createRootReducer;
