import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Document, Page, pdfjs, PDFDownloadLink } from "react-pdf";
import Spinner from "react-bootstrap/Spinner";
import { useFocus, useScroll } from "./hooks/index";
import { FaExpand, FaCompress, FaDownload, FaPrint } from "react-icons/fa";
import PropTypes from "prop-types";
import { saveAs } from 'file-saver';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Wrap = styled.div`
  /* overflow-y: hidden; */
  background-color: rgb(82, 86, 89);
`;
const Header = styled.div`
  display: flex;
  height: 55px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 20px; */
  box-shadow: 0 -8px 20px rgba(0, 0, 0, 0.4);
  background-color: rgb(50, 54, 57);
  color: white;
  position: sticky;
  top: 0;
  transition: 0.5s;
  z-index: 999;
`;
const PdfWrap = styled.div`
  min-height: calc(100vh - 55px);
  overflow-y: auto;
  display: flex;
  justify-content: center;
  margin: 8px 0 4px;
  user-select: none;
`;
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: white;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
`;

const ArrowWrap = styled.div`
  padding: 12px;
`;
const Arrow = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
`;
const LeftArrow = styled(Arrow)`
  transform: rotate(180deg);
`;
const RightArrow = styled(Arrow)``;

const PageInfo = styled.span`
  font-size: 14px;
  color: #4f5982;
`;

const Back = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  cursor: pointer;
  margin-right: 24px;
`;

const Title = styled.h3`
  font-size: 14px;
  padding-left: 25px;
  min-width: 200px;
`;

const PageNumbersWrapper = styled.div`
  font-size: 14px;
`;

const PdfActionsWrapper = styled.div`
  min-width: 200px;
  cursor:pointer;
  display: flex;
  justify-content: flex-end;
  .download-icon{
    margin-right:40px;
  }
  svg {
    margin: 6px;
  }
`;

const PdfControlsSideBar = styled.div`
  position: relative;
`;

const ZoomInButton = styled.button`
  width: 40px;
  height: 40px;
  margin-bottom: 15px;
  border-radius: 20px;
  font-weight: bold;
  border: none;
  color: gray;
  font-size: 20px;
  &:focus {
    outline: none;
  }
  &:active,
  &:focus {
    color: "red";
  }
`;
const ZoomOutButton = styled(ZoomInButton)``;
const FitToWidthButton = styled.button`
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 20px;
  color: gray;
  border: none;
  &:focus {
    outline: none;
  }
  svg {
    margin-top: -2.3px;
  }
  &:active,
  &:focus {
    color: "red";
  }
`;

const ControlsWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  right: 40px;
  bottom: 30px;
`;

const StyledPage = styled(Page)`
  transition: 1s ease;
  margin-bottom: 20px;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.5);
`;

const LoaderWrapper = styled.div`
  font-family: Quicksand;
  color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 500px;
`;

const StyledPageInput = styled.input`
  width: 30px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 12px;
  margin-right: 5px;
  text-align: center;
  &:focus {
    outline: none;
  }
`;

const StyledSlash = styled.span`
  font-size: 11px;
  margin-right: 5px;
`;

const PDFViewer = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [fitToWidth, setFitToWidth] = useState(false);
  const [inputPageNo, setInputPageNo] = useState(0);
  const [inputRef, setFocus] = useFocus();
  const [isPageInputEnabled, setIsPageInputEnabled] = useState(false);
  const [jumpToRef, setScroll] = useScroll();
  const [getWindowSize, setGetWindowSize] = useState(0);
  const [defaultScale, setDefaultScale] = useState(0.6);
  const [inputEntryStarted, setInputEntryStarted] = useState(false);
  const FIT_PDF = {
    TO_WIDTH: getWindowSize - 30,
    TO_PAGE: 768,
  };

  let link = "";
  const onDocumentLoadSuccess = ({ numPages }) => {
    if (numPages >= 1) setInputPageNo(1);
    setNumPages(numPages);
  };

  const params = props.match.params.id.split("`~`");
  const loc = props.location.pathname.split("`~`");
  const pdfName = loc[2] ? loc[2] : "";
  const noOfPages = loc[3] ? loc[3] : 200;
  let tempArr = Array.apply(null, Array(parseInt(noOfPages + 1)));
  let refsArray = tempArr.map(() => React.createRef());

  link = params
    ? `https://${params[0]}.s3.ap-south-1.amazonaws.com/${loc[1]}`
    : "";

  const handleFitToWidth = () => {
    setFitToWidth(!fitToWidth);
    setDefaultScale(0.6);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //  ? Input page handling

    if (refsArray[inputPageNo].current) {
      refsArray[inputPageNo].current.scrollIntoView({ block: "center" });
    }
  };

  useEffect(() => {
    function handleResize() {
      setGetWindowSize(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleZoomIn = () => {
    if (defaultScale === 0.8999999999999999) {
      setFitToWidth(true);
    }
    if (defaultScale < 0.9) {
      setDefaultScale(defaultScale + 0.1);
    }
  };
  const handleZoomOut = () => {
    setFitToWidth(false);
    if (defaultScale > 0.2) {
      setDefaultScale(defaultScale - 0.1);
    }
  };

  const handleInputPageChange = (e) => {
    if (e.target.value <= numPages) {
      setInputPageNo(e.target.value);
    }
  };

  const handlePageNumberCheck = (e, num) => {
    let currentViewedpage = 1;
    let currentPage = document.getElementsByClassName("react-pdf__Page");
    currentPage = Array.from(currentPage);
    let pageTopValues = [];
    currentPage.map((each, index) => {
      pageTopValues.push(each.getBoundingClientRect().top);
    });
    let nArr = pageTopValues.filter(function (v) {
      return v < 0;
    });
    if (nArr.length > 1) {
      let maxVal = Math.max(...nArr);
      currentViewedpage = pageTopValues.indexOf(maxVal);
    } else {
      currentViewedpage = pageTopValues.indexOf(Math.min(...pageTopValues));
    }
    setInputPageNo(currentViewedpage + 1);
  };

  useEffect(() => {
    document.addEventListener("scroll", handlePageNumberCheck);
    return () => {
      document.removeEventListener("scroll", handlePageNumberCheck);
    };
  }, []);

  const getLink = () => {
    if (!params) return "";
    const arr = loc[1].split("/");
    let path = loc[1];
    if (arr[arr.length - 1] == "") {
      arr.pop();
      path = arr.join("/");
    }
    return `https://${params[0]}.s3.ap-south-1.amazonaws.com/${path}`;
  };
  const downloadPdf = () => {
    if (getLink()) {
      let arr = getLink().split("/");
      const fileName = arr.length ? arr[arr.length - 2] : "";
      saveAs(getLink(), fileName);
    }
  }
  return (
    <>
      <Wrap>
        <Header>
          <Title>{pdfName}</Title>
          <form onSubmit={handleSubmit}>
            <PageNumbersWrapper>
              <StyledPageInput
                type="text"
                ref={inputRef}
                maxLength={numPages}
                onChange={handleInputPageChange}
                value={inputPageNo}
              />
              <StyledSlash>/</StyledSlash>
              {numPages || 0}
            </PageNumbersWrapper>
          </form>
          <PdfActionsWrapper>
            {props.hasDownload && <FileDownloadOutlinedIcon onClick={downloadPdf} className="download-icon" />}
          </PdfActionsWrapper>
        </Header>
        <PdfWrap>
          <Document
            file={getLink()}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <LoaderWrapper>
                <Spinner animation="border" role="status" />
              </LoaderWrapper>
            }
          >
            {/* If input is not enabled */}
            {!isPageInputEnabled &&
              Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map((page) => {
                  return (
                    <>
                      <div ref={refsArray[page]}>
                        <StyledPage
                          key={`page_${page}`}
                          width={
                            (getWindowSize - 35) *
                            (fitToWidth ? 1 : defaultScale)
                          }
                          pageNumber={page}
                          loading={<span />}
                        />
                      </div>
                    </>
                  );
                })}
          </Document>
        </PdfWrap>
      </Wrap>
      <PdfControlsSideBar>
        <ControlsWrapper>
          <FitToWidthButton onClick={handleFitToWidth}>
            {fitToWidth && <FaCompress />}
            {!fitToWidth && <FaExpand />}
          </FitToWidthButton>
          <ZoomInButton onClick={handleZoomIn} disabled={defaultScale > 0.9}>
            +
          </ZoomInButton>
          <ZoomOutButton onClick={handleZoomOut} disabled={defaultScale < 0.2}>
            -
          </ZoomOutButton>
        </ControlsWrapper>
      </PdfControlsSideBar>
    </>
  );
};
export default PDFViewer;

PDFViewer.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

PDFViewer.defaultProps = {
  match: {},
  location: {},
  hasDownload:true
};