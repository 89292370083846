import { endpoints } from "./constants";
import request from "utils/axios/service";
import { IBuildTestListReqBody } from "./interfaces";

export default class LibraryService {
  static getBuildTestListApi(payload: any) {
    return request.get(endpoints.BUILD_TEST_LIST, payload);
  }
  static getBuildTestDataApi(payload: any) {
    return request.get(endpoints.TEST_BY_ID, payload);
  }
  static updateExcelTestDataApi(payload: any) {
    return request.post(endpoints.UPDATE_EXCEL_TEST_ITEM, payload);
  }
  static updateBuildTestDataApi(payload: any) {
    return request.post(endpoints.UPDATE_TEST_ITEM, payload);
  }
  static getBuildDeliveryListApi(payload: IBuildTestListReqBody) {
    return request.get(endpoints.BUILD_DELIVERY_LIST, payload);
  }
  static createBuildDeliveryApi(payload: IBuildTestListReqBody) {
    return request.post(endpoints.CREATE_BUILD_DELIVERY, payload);
  }
  static updateBuildDeliveryApi(payload: IBuildTestListReqBody) {
    return request.post(endpoints.UPDATE_BUILD_DELIVERY, payload);
  }
  static createTemplateApi(payload: any) {
    return request.post(endpoints.CREATE_EXCEL_TEMPLATE, payload);
  }
  static createExcelTestItemApi(payload: any) {
    return request.post(endpoints.CREATE_EXCEL_TEST_ITEM, payload);
  }
  static createBuildTestApi(payload: any) {
    return request.post(endpoints.CREATE_BUILD_TEST, payload);
  }
  static createTestItemApi(payload: any) {
    return request.post(endpoints.CREATE_TEST_ITEM, payload);
  }
  static storeTestApi(payload: any) {
    return request.post(endpoints.STORE_TEST, payload);
  }
  static getDeliveryById(payload: any) {
    return request.get(endpoints.GET_DELIVERY_BY_ID, payload);
  }
  static getAnswerKey(payload: any) {
    return request.get(endpoints.GET_ANSWER_KEY, payload);
  }
  static getPreviewDataApi(payload: any) {
    return request.get(endpoints.GET_PREVIEW_DATA_API, payload);
  }
  static createNewTemplateApi(payload: any) {
    return request.post(endpoints.CREATE_TEMPLATE_API, payload);
  }
  static getValidateWarmUp(payload: any) {
    return request.get(endpoints.VALIDATE_WARM_UP_ID, payload);
  }
  static validateTestId(payload: any) {
    return request.get(endpoints.VAIDATE_TEST_ID, payload);
  }
  static refreshDelivery(payload: any) {
    return request.post(endpoints.REFRESH_DELIVERY, payload);
  }
  static getTemplateList(payload: any) {
    return request.get(endpoints.TEMPLATE_LIST, payload);
  }
  static getTemplateDetails(payload: any) {
    return request.get(endpoints.TEMPLATE_DETAILS, payload);
  }
  static getTestPreviewDetails(payload: any) {
    return request.get(endpoints.PREVIEW_TEST_DATA, payload);
  }
  static getTestDetailsById(payload: any) {
    return request.get(endpoints.GET_TEST_DETAILS, payload);
  }
  static getTestPreviewData(payload: any) {
    return request.get(endpoints.GET_TEST_PREVIEW, payload);
  }
  static updateTestForm(payload: any) {
    return request.post(endpoints.UPDATE_TEST, payload);
  }
  static checkDuplicateTemplate(payload: any) {
    return request.get(endpoints.CHECK_DUPLICATE_TEMPLATE, payload);
  }
  static getQuestionGroupItemList(payload: any) {
    return request.get(endpoints.GET_QUESTION_GROUP_ITEM_LIST, payload);
  }
  static saveSectionWiseQuestions(payload: any) {
    const url =
      payload.type === "create"
        ? endpoints.SAVE_SECTION_WISE_QUESTIONS
        : endpoints.UPDATE_SECTION_WISE_QUESTIONS;
    return request.post(url, payload);
  }
  static viewQuestionDetails(payload: any) {
    return request.get(endpoints.VIEW_QUESTION_DETAILS, payload);
  }
  static getQuestionGroupList(payload: any) {
    return request.get(endpoints.QUESTION_GROUP_LIST, payload);
  }
  static createQuestionGroup(payload: any) {
    return request.post(endpoints.CREATE_QUESTION_GROUP, payload);
  }
  static getSubQuestionGroupList(payload: any) {
    return request.get(endpoints.SUB_QUESTION_GROUP_LIST, payload);
  }
  static getSubQuestionGroupItemList(payload: any) {
    return request.get(endpoints.SUB_QUESTION_GROUP_ITEM_LIST, payload);
  }
  static getQuestionDetailsByOqbId(payload: any) {
    return request.get(endpoints.GET_QUESTION_DETAILS, payload);
  }
  static createSubQuestionGroup(paylod: any) {
    return request.post(endpoints.CREATE_SUB_QUESTION_GROUP, paylod);
  }
  static createSubQuestionGroupItem(payload: any) {
    return request.post(endpoints.CREATE_SUB_QUESTION_GROUP_ITEM, payload);
  }
  static updateSubQuestionGroupItem(payload: any) {
    return request.post(endpoints.UPDATE_SUB_QUESTION_GROUP_ITEM, payload);
  }
  static updateQuestionGroup(payload: any) {
    return request.post(endpoints.UPDATE_QUESTION_GROUP, payload);
  }
  static updateSubQuestionGroup(paylod: any) {
    return request.post(endpoints.UPDATE_SUB_QUESTION_GROUP, paylod);
  }
  static deleteSubQuestionGroup(payload: any) {
    return request.get(endpoints.DELETE_SUB_QUESTION_GROUP, payload);
  }
  static addQuestionContent(payload: any) {
    return request.post(endpoints.ADD_CONTENT, payload);
  }
  static getQuestionGroupListDetailsById(payload: any) {
    return request.get(endpoints.GET_QUESTION_GROUP_LIST_DETAILS, payload);
  }
  static fetOqbQuestionData(payload: any) {
    return request.post(endpoints.FETCH_OQB_QUESTION, payload);
  }
  static getHierarchyByBatch(payload: any) {
    return request.get(endpoints.GET_HIEARCHY_BY_BATCH, payload)
  }
}
