import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IChapterTopicList, IChapterTopicListRequestBody, ILockChapter, ITopicListInitialState } from "../interfaces";

const initialState = {
  loading: false,
  error: "",
  data: [],
  lockChapterLoading: false,
  lockChapterEror: "",
};


const chapterTopicsSlice = createSlice({
  name: "learn",
  initialState,
  reducers: {
    chapterTopicListRequest(state: ITopicListInitialState, action: PayloadAction<IChapterTopicList>) {
      state.loading = true;
    },
    chapterTopicListSuccess(state: ITopicListInitialState, action: PayloadAction<IChapterTopicListRequestBody>) {
      state.data = action.payload;
    },
    postLockChaptersRequest(state: ITopicListInitialState, action: PayloadAction<ILockChapter>) {
      state.lockChapterLoading = true;
    },
    postLockChaptersSuccess(state: ITopicListInitialState) {
      return state;
    },
    resetTopicList(state: ITopicListInitialState) {
      state.data = []
    }
  },
}
);
export const {
  chapterTopicListRequest,
  chapterTopicListSuccess,
  postLockChaptersRequest,
  postLockChaptersSuccess,
  resetTopicList
} = chapterTopicsSlice.actions;
export default chapterTopicsSlice.reducer;
