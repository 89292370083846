export const microServices = {
  NCMSV2: "ncms/v2",
  NCMSV1: "ncms/v1",
};

export const endpoints = {
  VIEW_HIERARCHY_METRICS: `${microServices.NCMSV2}/practice-hierarchy/view-hierarchy/get-hierarchy-metrics`,
  SUBJECT_CHAPTER_DATA: `${microServices.NCMSV2}/usage/get-subject-chapter-metadata`,
  MAP_HIERARCHY_LIST: `${microServices.NCMSV2}/practice-hierarchy/map-hierarchy/get-mapped-hierarchy-list`,
  CREATE_HIERARCHY_MAP: `${microServices.NCMSV2}/practice-hierarchy/map-hierarchy/create-hierarchy-map`,
  DOWNLOAD_HIERARCHY_METRICS: `${microServices.NCMSV2}/practice-hierarchy/view-hierarchy/download-hierarchy-metrics`,
  GET_QUESION_DETAILS: `${microServices.NCMSV1}/item/get-item-details`,
  GET_MAPPED_LIST_BY_PRACTICE_FORMAT: `${microServices.NCMSV2}/practice-hierarchy/view-hierarchy/get-mapped-item-list`,
};
