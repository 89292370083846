import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitState {
  isLoading: boolean;
  questionData: any;
  replaceQuestionItem: string | number;
  error: any;
}

const initialState: IInitState = {
  isLoading: false,
  questionData: false,
  replaceQuestionItem: 0,
  error: false,
};

const viewQuestionSlice = createSlice({
  name: "viewQuestion",
  initialState: initialState,
  reducers: {
    viewQuestionRequest(state: IInitState, action: PayloadAction<any>) {
      state.isLoading = true;
      state.questionData = action.payload;
    },
    viewQuestionSuccess(state: IInitState, action: PayloadAction<any>) {
      state.isLoading = false;
      state.questionData = action.payload;
    },
  },
});

export const {
    viewQuestionRequest,
    viewQuestionSuccess,
} = viewQuestionSlice.actions;

export default viewQuestionSlice.reducer;
