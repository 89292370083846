import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  choiceCount: 0,
  subQuestions: {},
  optionById: {},
  numberOfSubQuestions: "",
};

const paragraphQuestionsSlice = createSlice({
  name: "paragraphQuestions",
  initialState,
  reducers: {
    updateSubQuestions(state, action) {
      state.subQuestions = action.payload;
    },
    updateChoiceCount(state, action) {
      state.choiceCount = action.payload;
    },
    updateOptions(state, action) {
      state.optionById = action.payload;
    },
    updateNumberOfSubQuestions(state, action) {
      state.numberOfSubQuestions = action.payload;
    },
    resetParagraphValues(state) {
      state.choiceCount = 0;
      state.subQuestions = {};
      state.optionById = {};
      state.numberOfSubQuestions = "";
    },
  },
});

export const {
  updateSubQuestions,
  updateChoiceCount,
  updateOptions,
  updateNumberOfSubQuestions,
  resetParagraphValues,
} = paragraphQuestionsSlice.actions;
export default paragraphQuestionsSlice.reducer;
