import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { IAnnouncementListReqBody, IAnnouncementIdReqBody } from "./interfaces";
import announcementService from "./service";
import { ResponseGenerator } from "interfaces";
import { replace } from "connected-react-router";

import {
  announcementListRequest,
  announcementListSuccess,
} from "./slices/announcement-list-slice";
import {
  announcementDeliveryListRequest,
  announcementDeliveryListSuccess,
} from "./slices/delivery-list-slice";
import {
  createAnnouncementRequest,
  createAnnouncementSuccess,
} from "./slices/create-announcement-slice";
import {
  getAnnouncementByIdRequest,
  getAnnouncementByIdSuccess,
} from "pages/announcements/slices/get-announcement-by-id-slice";
import {
  getAnnouncementDeliveryByIdRequest,
  getAnnouncementDeliveryByIdSuccess,
} from "pages/announcements/slices/get-announcement-delivery-by-id-slice";

import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";

export const userLogoutAction = () => ({ type: "USER_LOGOUT" });

//-----------------------Announcements--------------------------
function* createAnnouncementWorker(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      announcementService.createAnnouncementApi,
      action.payload
    );

    if (res.status === 200 && res.data.status === "success") {
      yield put(
        snackbarRequest({
          message: action.payload.announcement_id
            ? "updated successfully"
            : "created successfully",
        })
      );
      yield put(createAnnouncementSuccess(res.data.data));
      const announcementId = action.payload.announcement_id
        ? action.payload.announcement_id
        : res.data.data.announcement_id;
      yield put(replace(`/announcements/edit/${announcementId}?step=1`));
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (error) {}
}

function* getAnnouncementListWorker(
  action: PayloadAction<IAnnouncementListReqBody>
) {
  try {
    const res: ResponseGenerator = yield call(
      announcementService.getAnnouncementListApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(announcementListSuccess(res.data.data));
    }
  } catch (e) {}
}

function* getAnnouncementByIdWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      announcementService.getAnnouncementByIdApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(getAnnouncementByIdSuccess(res.data.data));
    }
  } catch (e) {}
}

function* deleteAnnouncementWorker(
  action: PayloadAction<IAnnouncementIdReqBody>
) {
  try {
    const res: ResponseGenerator = yield call(
      announcementService.deleteAnnouncementApi,
      action.payload
    );
    if (res && res.status === 200 && res.data.status === "success") {
      yield put(snackbarRequest({ message: "deleted successfully" }));
      const { start_date, end_date } = action.payload;
      yield put(
        announcementListRequest({ start_date: start_date, end_date: end_date })
      );
    }
  } catch (e) {}
}

//-----------------------Announcements Delivery--------------------------
function* getAnnouncementDeliveryListWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      announcementService.getAnnouncementDeliveryListApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(announcementDeliveryListSuccess(res.data.data));
    }
  } catch (e) {}
}

function* getAnnouncementDeliveryByIdWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      announcementService.getAnnouncementDeliveryByIdApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(getAnnouncementDeliveryByIdSuccess(res.data.data));
    }
  } catch (e) {}
}

function* deleteAnnouncementDeliveryWorker(
  action: PayloadAction<IAnnouncementIdReqBody>
) {
  try {
    const res: ResponseGenerator = yield call(
      announcementService.deleteAnnouncementDeliveryApi,
      action.payload
    );
    if (res && res.status === 200 && res.data.status === "success") {
      yield put(snackbarRequest({ message: "deleted successfully" }));
      const { announcement_id } = action.payload;
      yield put(
        announcementDeliveryListRequest({ announcement_id: announcement_id })
      );
    }
  } catch (e) {}
}

//-----------------------Announcements List Worker--------------------------
export default function* announcementsSaga() {
  yield takeLatest("DELETE_ANNOUNCEMENT", deleteAnnouncementWorker);
  yield takeLatest(
    "DELETE_ANNOUNCEMENT_DELIVERY",
    deleteAnnouncementDeliveryWorker
  );
  yield takeLatest(announcementListRequest.type, getAnnouncementListWorker);
  yield takeLatest(createAnnouncementRequest.type, createAnnouncementWorker);
  yield takeLatest(
    announcementDeliveryListRequest.type,
    getAnnouncementDeliveryListWorker
  );
  yield takeLatest(getAnnouncementByIdRequest.type, getAnnouncementByIdWorker);
  yield takeLatest(
    getAnnouncementDeliveryByIdRequest.type,
    getAnnouncementDeliveryByIdWorker
  );
}
