import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IUpdateSubGroup } from "../interfaces";
interface IInitState {
  loading: boolean;
  updateSubGroupData: any;
  error: string;
  filtersData: any;
}

const initialState = {
  loading: false,
  updateSubGroupData: {},
  error: "",
  filtersData: "",
};

const updateSubQuestionGroupSlice = createSlice({
  name: "updateQuestionGroup",
  initialState,
  reducers: {
    updateSubQuestionGroupRequest(
      state: IInitState,
      action: PayloadAction<IUpdateSubGroup>
    ) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    updateSubQuestionGroupSuccess(
      state: IInitState,
      action: PayloadAction<any[]>
    ) {
      state.loading = false;
      state.updateSubGroupData = action.payload;
    },
  },
});

export const { updateSubQuestionGroupRequest, updateSubQuestionGroupSuccess } =
  updateSubQuestionGroupSlice.actions;
export default updateSubQuestionGroupSlice.reducer;
