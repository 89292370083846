import { Grid, Box } from "@mui/material";
import ErrorLogo from "assets/404.jpg";
import { useStyles } from "./styles";
import { Link } from "react-router-dom";

function Error() {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <img src={ErrorLogo} alt="" className={classes.logo} />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Box className={classes.header}>Ooops... 404!</Box>
        <Box className={classes.content}>
          The page you requested could not be found.
        </Box>
      </Grid>
      <Grid sx={{ textAlign: "center", padding: 1 }} xs={12}>
        <Link to="/" style={{ color: "#1976d2" }}>
          <Box>Back To Dashboard</Box>
        </Link>
      </Grid>
    </Grid>
  );
}

export default Error;
