import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IBuildTestListReqBody } from "../interfaces";
interface IInitState {
  loading: boolean;
  templatedetails: object;
  error: string;
  filtersData: any;
}

const initialState = {
  loading: false,
  templatedetails: {},
  error: "",
  filtersData: "",
};

const TemplateDetailsSlice = createSlice({
  name: "buildDeliveryList",
  initialState,
  reducers: {
    getTemplateDetailsRequest(state: IInitState, action: PayloadAction<any>) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    getTemplateDetailsSuccess(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.templatedetails = action.payload;
    },
  },
});

export const { getTemplateDetailsRequest, getTemplateDetailsSuccess } =
  TemplateDetailsSlice.actions;
export default TemplateDetailsSlice.reducer;
