import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAnnouncement, IAnnouncementListReqBody , IInitState, IFiltersData} from "pages/announcements/interfaces";

const initialState = {
  loading: false,
  data: [],
  filtersData: {
    start_date: "",
    end_date: "",
  },
  error: "",
};

const announcementListSlice = createSlice({
  name: "announcementList",
  initialState,
  reducers: {
    announcementListRequest(
      state: IInitState,
      action: PayloadAction<IFiltersData>
    ) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    announcementListSuccess(
      state: IInitState,
      action: PayloadAction<IAnnouncement[]>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
    resetAnnouncementList(state) {
      state.data = [];
    },
  },
});

export const {
  announcementListRequest,
  announcementListSuccess,
  resetAnnouncementList,
} = announcementListSlice.actions;
export default announcementListSlice.reducer;
