import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {IInitState, ICreateMonthlyScheduleReqest, ICreateMonthlyScheduleSuccess } from "../interfaces";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

const createMonthlyScheduleSlice = createSlice({
  name: "createmonthlyschedule",
  initialState,
  reducers: {
    createMonthlyScheduleRequest(state: IInitState, action: PayloadAction<ICreateMonthlyScheduleReqest>) {
      state.loading = true;
    },
    createMonthlyScheduleSuccess(state: IInitState, action: PayloadAction<ICreateMonthlyScheduleSuccess>) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { createMonthlyScheduleRequest, createMonthlyScheduleSuccess } =
  createMonthlyScheduleSlice.actions;
export default createMonthlyScheduleSlice.reducer;
