import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import activitiesService from "./service";
import {
  funSheetListRequest,
  funSheetListSuccess,
} from "./slices/fun-sheet-list-slice";
import {
  funSheetDetailsRequest,
  funSheetDetailsSuccess,
} from "./slices/fun-sheet-details.slice";
import {
  magazineListRequest,
  magazineListSuccess,
} from "./slices/magazine-list-slice";
import {
  magazineDetailsRequest,
  magazineDetailsSuccess,
} from "./slices/magazine-details-slice";
import { ResponseGenerator } from "interfaces";

import {
  IActivitiesListReqBody,
  IActivitiesMagazineDetailsReqBody,
  IActivitesFunsheetDetailsReqBody,
} from "./interfaces";

function* getFunSheetListWorker(action: PayloadAction<IActivitiesListReqBody>) {
  try {
    const res: ResponseGenerator = yield call(
      activitiesService.getFunSheetList,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(funSheetListSuccess(res.data.data));
    } else {
      yield put(funSheetListSuccess(res.data.data));
    }
  } catch (e) {}
}

function* getFunSheetDetailsWorker(
  action: PayloadAction<IActivitesFunsheetDetailsReqBody>
) {
  try {
    const res: ResponseGenerator = yield call(
      activitiesService.getFunSheetDetails,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(funSheetDetailsSuccess(res.data.data));
    }
  } catch (e) {}
}

function* getMagazineListWorker(action: PayloadAction<IActivitiesListReqBody>) {
  try {
    const res: ResponseGenerator = yield call(
      activitiesService.getMagazineListApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(magazineListSuccess(res.data.data));
    }
  } catch (e) {
    console.log(e);
  }
}

function* getMagazineDetailsWorker(
  action: PayloadAction<IActivitiesMagazineDetailsReqBody>
) {
  try {
    const res: ResponseGenerator = yield call(
      activitiesService.getMagazineDetailsApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(magazineDetailsSuccess(res.data.data));
    }
  } catch (e) {}
}

export default function* activitiesSaga() {
  yield takeLatest(funSheetListRequest.type, getFunSheetListWorker);
  yield takeLatest(funSheetDetailsRequest.type, getFunSheetDetailsWorker);

  yield takeLatest(magazineListRequest.type, getMagazineListWorker);
  yield takeLatest(magazineDetailsRequest.type, getMagazineDetailsWorker);
}
