import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IInitState {
  loading: boolean;
  listData: any;
  error: string;
  message: string;
  noMappingId: any;
}

const initialState = {
  loading: false,
  error: "",
  listData: [],
  message: "",
  noMappingId: {},
};

const mapHierarchyListSlice = createSlice({
  name: "hierarchymetrics",
  initialState,
  reducers: {
    getMapHierarchyListRequest(state: IInitState, action: PayloadAction<any>) {
      state.loading = true;
    },
    getMapHierarchyListSuccess(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.listData = action.payload;
      state.message = "";
      state.error = "";
    },
    getMapHierarchyListMessage(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.message = action.payload;
      state.error = "";
    },
    getMapHierarchyListError(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getMapHierrachyListNoMappingId(
      state: IInitState,
      action: PayloadAction<any>
    ) {
      state.loading = false;
      state.noMappingId = action.payload;
    },
  },
});

export const {
  getMapHierarchyListRequest,
  getMapHierarchyListSuccess,
  getMapHierarchyListMessage,
  getMapHierarchyListError,
  getMapHierrachyListNoMappingId,
}: any = mapHierarchyListSlice.actions;
export default mapHierarchyListSlice.reducer;
