import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IInitState {
  loading: boolean;
  createData: any;
  error: string;
}

const initialState = {
  loading: false,
  error: "",
  createData: {},
};

const createHierarchyMapSlice = createSlice({
  name: "hierarchymetrics",
  initialState,
  reducers: {
    createHierarchyMapRequest(state: IInitState, action: PayloadAction<any>) {
      state.loading = true;
    },
    createHierarchyMapSuccess(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.createData = action.payload;
      state.error = "";
    },
    getMapHierarchyErrorMessage(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  createHierarchyMapRequest,
  createHierarchyMapSuccess,
  getMapHierarchyErrorMessage,
} = createHierarchyMapSlice.actions;
export default createHierarchyMapSlice.reducer;
