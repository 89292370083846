import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITermExam, ITermExamListReqBody, IInitState } from "../interfaces";

const initialState = {
  loading: false,
  data: [],
  error: "",
};
const createTermExamSlice = createSlice({
  name: "createtermexam",
  initialState,
  reducers: {
    createTermExamRequest(
      state: IInitState,
      action: PayloadAction<ITermExamListReqBody>
    ) {
      state.loading = true;
    },
    createTermExamSuccess(
      state: IInitState,
      action: PayloadAction<ITermExam[]>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { createTermExamRequest, createTermExamSuccess } =
  createTermExamSlice.actions;
export default createTermExamSlice.reducer;
