import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, ICreateBuildDelivery } from "../interfaces";

interface IInitState {
  loading: boolean;
  data: IBuildTest[];
  error: string;
  filtersData: any;
}

const initialState = {
  loading: false,
  data: [],
  error: "",
  filtersData: '',
};

const createbuildDeliverySlice = createSlice({
  name: "createBuildDelivery",
  initialState,
  reducers: {
    createBuildDeliveryRequest(
      state: IInitState,
      action: PayloadAction<ICreateBuildDelivery>
    ) {
      state.loading = true;
      state.filtersData = action.payload
    },
    createBuildDeliverySuccess(
      state: IInitState,
      action: PayloadAction<any[]>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { createBuildDeliveryRequest, createBuildDeliverySuccess } =
  createbuildDeliverySlice.actions;
export default createbuildDeliverySlice.reducer;
