import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IBuildTestListReqBody } from "../interfaces";
interface IInitState {
  loading: boolean;
  templatedata: IBuildTest[];
  error: boolean;
  filtersData: any;
}

const initialState = {
  loading: false,
  templatedata: [],
  error: false,
  filtersData: {
    template_name: "",
    exam: "",
    branch_type: [],
  },
};

const TemplateListSlice = createSlice({
  name: "buildDeliveryList",
  initialState,
  reducers: {
    getTemplateListRequest(state: IInitState, action: PayloadAction<any[]>) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    getTemplateListSuccess(state: IInitState, action: PayloadAction<any[]>) {
      state.loading = false;
      state.templatedata = action.payload;
    },
    getTemplateListError(state: IInitState, action: PayloadAction<any[]>) {
      state.loading = false;
      state.error = true;
    },
    resetTemplateList(state) {
      state.templatedata = [];
      state.filtersData = {
        template_name: "",
        exam: "",
        branch_type: [],
      };
    },
  },
});

export const {
  getTemplateListRequest,
  getTemplateListSuccess,
  getTemplateListError,
} = TemplateListSlice.actions;
export default TemplateListSlice.reducer;
