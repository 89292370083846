import { endpoints } from "./constants";
import request from "utils/axios/service";
import {
  IAssignmentListReqBody,
  IAssignmentIdReqBody,
  IDeleteReqBody,
} from "./interfaces";
type AssignmentId = Pick<IAssignmentIdReqBody, "assignment_id">;

const getCreateDeliveryFormat = (payload: any) => {
  const res = payload.delivery_list.map((item: any) => ({
    ...item,
    reservation_status_list: item.reservation_status_id,
    batch_list: item.batch_id,
    sub_batch_list: item.sub_batch_id,
    state_list: item.state_id,
    college_list: item.college_id,
  }));
  return { assignment_id: payload.assignment_id, delivery_list: res };
};

const getRequiredEndpoint = (data: any) => {
  if (data.assignment_id) return endpoints.UPDATE_ASSIGNMENT;
  return endpoints.CREATE_ASSIGNMENT;
};


export default class AssignmentService {
  static createAssignmentListApi(payload: any) {
    const url = getRequiredEndpoint(payload);
    return request.post(url, payload);
  }
  static getAssignmentDataApi(payload: AssignmentId) {
    return request.get(endpoints.GET_ASSIGNMENT_DATA_BY_ID, payload);
  }
  static getAssignmentListApi(payload: IAssignmentListReqBody) {
    return request.get(endpoints.ASSIGNMENT_LIST, payload);
  }
  static deleteAssignmentApi(payload: AssignmentId) {
    return request.post(endpoints.DELETE_ASSIGNMENT, payload);
  }
  static deleteAssignmentDeliveryApi(payload: IDeleteReqBody) {
    return request.post(endpoints.DELETE_ASSIGNMENT_DELIVERY, payload);
  }
  static createAssignmentDeliveriesApi(payload: any) {
    const reqBody = getCreateDeliveryFormat(payload);
    return request.post(endpoints.CREATE_ASSIGNMENT_DELIVERY, reqBody);
  }
  static getAssignmentDeliveryListApi(payload: IAssignmentIdReqBody) {
    return request.get(endpoints.ASSIGNMENT_DELIVERY_LIST, payload);
  }
  static getAssignmentDeliveryApi(payload: { delivery_id: string }) {
    return request.get(endpoints.GET_ASSIGNMENT_DELIVERY_BY_ID, payload);
  }
  static updateAssignmentDeliveryApi(payload: any) {
    return request.post(endpoints.UPDATE_ASSIGNMENT_DELIVERY, payload);
  }
  static getAssignmentDeliveryDataApi(payload: any) {
    return request.get(endpoints.GET_ASSIGNMENT_DELIVERY_DATA, payload);
  }
}
