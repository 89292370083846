import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IBuildTestListReqBody } from "../interfaces";
interface IInitState {
  loading: any;
  data: IBuildTest[];
  error: boolean;
  filtersData: any;
}

const initialState = {
  loading: false,
  data: [],
  error: false,
  filtersData: "",
};

const questionGroupListSlice = createSlice({
  name: "questionGroupList",
  initialState,
  reducers: {
    getQuestionGroupListRequest(state: IInitState, action: PayloadAction<any>) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    getQuestionGroupListSuccess(
      state: IInitState,
      action: PayloadAction<any[]>
    ) {
      state.loading = false;
      state.data = action.payload;
      state.error = false;
    },
    getQuestionListLoadingStatus(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = true;
    },
  },
});

export const {
  getQuestionGroupListRequest,
  getQuestionGroupListSuccess,
  getQuestionListLoadingStatus,
} = questionGroupListSlice.actions;
export default questionGroupListSlice.reducer;
