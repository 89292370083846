import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IBuildTestListReqBody } from "../interfaces";
interface IInitState {
  loading: boolean;
  data: IBuildTest[];
  error: boolean;
  filtersData: any;
}

const initialState = {
  loading: false,
  data: [],
  error: false,
  filtersData: "",
};

const buildTestListSlice = createSlice({
  name: "buildTestList",
  initialState,
  reducers: {
    getBuildTestListRequest(state: IInitState, action: PayloadAction<any>) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    getBuildTestListSuccess(state: IInitState, action: PayloadAction<any[]>) {
      state.loading = false;
      state.data = action.payload;
    },
    getBuildTestListError(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = true;
    },
  },
});

export const {
  getBuildTestListRequest,
  getBuildTestListSuccess,
  getBuildTestListError,
} = buildTestListSlice.actions;
export default buildTestListSlice.reducer;
