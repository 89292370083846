import { endpoints } from "./constants";
import request from "utils/axios/service";
export default class HierarchiesService {
  static getViewHierarchyMetrics(payload: any) {
    return request.get(endpoints.VIEW_HIERARCHY_METRICS, payload);
  }

  static getSubjectChapterData(payload: any) {
    return request.get(endpoints.SUBJECT_CHAPTER_DATA, payload);
  }
  static getMapHierarchyListData(payload: any) {
    return request.get(endpoints.MAP_HIERARCHY_LIST, payload);
  }
  static createHierarchyMap(payload: any) {
    return request.post(endpoints.CREATE_HIERARCHY_MAP, payload);
  }
  static downloadHierarchyMetrics(payload: any) {
    return request.get(endpoints.DOWNLOAD_HIERARCHY_METRICS, payload);
  }
  static getQuestionsDetailsApi(payload: any) {
    return request.get(endpoints.GET_QUESION_DETAILS, payload);
  }
  static getMappedListByPracticeFormat(payload: any) {
    return request.get(endpoints.GET_MAPPED_LIST_BY_PRACTICE_FORMAT, payload);
  }
}
