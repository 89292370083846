export const microServices = {
  TCMSV2: "tcms/v2/warm-up-delivery",
  TCMSV1: "tcms/v1",
};

export const endpoints = {
  WARM_UP_TEST_LIST: `${microServices.TCMSV2}/get-delivery-list`,
  GET_SUBJECT_CHAPTER_DATA: `${microServices.TCMSV1}/usage/get-subject-chapter-metadata`,
  CREATE_WARM_UP_DELIVERY: `${microServices.TCMSV2}/create-delivery`,
  GET_WARM_UP_DELIVERY_DETAILS: `${microServices.TCMSV2}/get-delivery-details`,
  META_DATA: `${microServices.TCMSV1}/usage/get-subject-chapter-metadata`,
  UPDATE_WARM_UP: `${microServices.TCMSV2}/update-delivery`,
};
