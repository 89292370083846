import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, ICreateSubGroup } from "../interfaces";
interface IInitState {
  loading: boolean;
  data: any;
  error: string;
  // questionData: any;
}

const initialState = {
  loading: false,
  data: {},
  error: "",
  // questionData: "",
};

const deleteSubQuestionGroupSlice = createSlice({
  name: "createSubQuestionGroup",
  initialState,
  reducers: {
    deleteSubQuestionGroupRequest(
      state: IInitState,
      action: PayloadAction<any>
    ) {
      state.loading = true;
      // state.filtersData = action.payload;
    },
    deleteSubQuestionGroupSuccess(
      state: IInitState,
      action: PayloadAction<any>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
    deleteSubQuestionGroupError(
      state: IInitState,
      action: PayloadAction<any>
    ) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { deleteSubQuestionGroupRequest, deleteSubQuestionGroupSuccess } =
  deleteSubQuestionGroupSlice.actions;
export default deleteSubQuestionGroupSlice.reducer;
