import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hierachyStatus: false,
};

const getHierarchyStatus = createSlice({
  name: "hierarcyStatus",
  initialState,
  reducers: {
    setHierarchyStatus(state, action) {
      state.hierachyStatus = action.payload;
    },
  },
});

export const { setHierarchyStatus } = getHierarchyStatus.actions;

export default getHierarchyStatus.reducer;
