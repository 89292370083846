import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IUpdateQuestionGroup } from "../interfaces";
interface IInitState {
  loading: boolean;
  data: any;
  error: string;
  filtersData: any;
}

const initialState = {
  loading: false,
  data: {},
  error: "",
  filtersData: "",
};

const updateQuestionGroupSlice = createSlice({
  name: "updateQuestionGroup",
  initialState,
  reducers: {
    updateQuestionGroupRequest(
      state: IInitState,
      action: PayloadAction<IUpdateQuestionGroup>
    ) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    updateQuestionGroupSuccess(
      state: IInitState,
      action: PayloadAction<any[]>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { updateQuestionGroupRequest, updateQuestionGroupSuccess } =
  updateQuestionGroupSlice.actions;
export default updateQuestionGroupSlice.reducer;
