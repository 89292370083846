import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { ResponseGenerator } from "interfaces";

import WarmUpService from "./service";

import {
  getWarmUpTestListRequest,
  getWarmUpTestListSuccess,
} from "./slices/warm-up-list-slice";

import {
  createWarmUpDeliveryRequest,
  createWarmUpDeliverySuccess,
} from "./slices/create-warm-up-slice";
import { snackbarRequest } from "shared/molecules/snackbar/snackbar-slice";
import { replace } from "connected-react-router";
import { getWarmUpDeliveryDetailsRequest } from "./slices/warm-up-delivery-slice";
import { getWarmUpDeliveryDetailsSuccess } from "./slices/warm-up-delivery-slice";
import {
  updateWarmUpDeliveryRequest,
  updateWarmUpDeliverySuccess,
} from "./slices/update-warm-up-slice";

function* getWarmUpTestListWorker(action: PayloadAction<any>) {
  try {
    const res = yield call(WarmUpService.getWarmUpTestListApi, action.payload);
    if (res.status === 200 && res.data.status === "success") {
      yield put(getWarmUpTestListSuccess(res.data.data));
    }
  } catch (e) {}
}

function* getWarmUpDeliveryDetailsWorker(action: PayloadAction<any>) {
  try {
    const response = yield call(
      WarmUpService.getWarmUpDeliveryDetailsRequest,
      action.payload
    );
    if (response.status === 200 && response.data.status === "success") {
      yield put(getWarmUpDeliveryDetailsSuccess(response.data));
    }
  } catch {}
}

function* createWarmUpDeliveryWorker(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      WarmUpService.createWarmUpDeliveryApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(
        snackbarRequest({ message: "Warm Up Delivery created successfully" })
      );
      yield put(createWarmUpDeliverySuccess(res.data.data));
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (error) {}
}

function* updateWarmUpDeliveryRequestWorker(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      WarmUpService.updateWarmUpDeliveryDetails,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(
        snackbarRequest({ message: "Warm Up Delivery updated successfully" })
      );
      yield put(updateWarmUpDeliverySuccess(res.data));
      if (action.payload.delivery_id) {
        const reqBody: any = JSON.parse(
          sessionStorage.getItem("warmUpTestFilterData") || "{}"
        );
        yield put(getWarmUpTestListSuccess(reqBody));
      }
      yield put(replace(`/warm-up-delivery`));
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (e) {}
}

//-----------------------------Warm-up-list Worker--------------------------------

export default function* warmUpSaga() {
  yield takeLatest(getWarmUpTestListRequest.type, getWarmUpTestListWorker);
  yield takeLatest(
    createWarmUpDeliveryRequest.type,
    createWarmUpDeliveryWorker
  );
  yield takeLatest(
    getWarmUpDeliveryDetailsRequest.type,
    getWarmUpDeliveryDetailsWorker
  );
  yield takeLatest(
    updateWarmUpDeliveryRequest.type,
    updateWarmUpDeliveryRequestWorker
  );
}
