import * as Yup from "yup";
import { DeliveryFieldTypes } from "pages/assignments/interfaces";

export const mandatoryFields = [
  { id: "academic_year_id" },
  { id: "state_id" },
  { id: "college_id" },
  { id: "reservation_status_id" },
  { id: "class_id" },
  { id: "batch_id" },
  { id: "sub_batch_id" },
  { id: "assignment_start_date" },
  { id: "assignment_end_date" },
  { id: "solution_visibility_date" },
];

export const deliveryProperties = [
  {
    id: "academic_year_id",
    type: "normal-select",
    label: "Academics *",
    required: true,
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "academic_year_list",
    keyValue: { label: "year_name", value: "year_id" },
    //keyValue: { label: "academic_year", value: "academic_year_id" },
  },
  {
    id: "state_id",
    type: "multi-select-dependency",
    label: "State *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "state_list",
    required: true,
    keyValue: { label: "state_name", value: "state_id" },
    dependentFields: ["college_id"],
    dependentFlds: {
      state_list: "state_id",
      // college_list: "college_id",
    },
  },
  {
    id: "college_id",
    type: "multi-select-dependency",
    label: "Branch *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "college_list",
    keyValue: { label: "college_name", value: "college_id" },
    dependencyLabel: "Please fill state",
    isDependent: true,
    required: true,
    fieldKeyLists: {
      // state_list: "state_id",
    },
  },
  {
    id: "reservation_status_id",
    type: "multi-select",
    label: "Reservation *",
    className: "select-styles",
    required: true,
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "reservation_status_list",
    keyValue: { label: "registration_status_name", value: "registration_status_id" },
   // keyValue: { label: "reservation_status", value: "reservation_status_id" },
  },
  {
    id: "board_id",
    type: "normal-select",
    label: "Board",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "board_list",
    keyValue: { label: "board_name", value: "board_id" },
  },
  {
    id: "batch_id",
    type: "multi-select-dependency",
    label: "Batch *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    dependentFields: ["sub_batch_id"],
    isDependent: true,
    required: true,
    dependencyLabel: "Please fill class",
    fieldKeyLists: {
      // class_course_batch_data: "class_id" 
      },
    list: "batch_list",
    keyValue: { label: "batch_name", value: "batch_id" },
  },
  {
    id: "sub_batch_id",
    type: "multi-select-dependency",
    label: "Sub Batch *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    isDependent: true,
    required: true,
    dependencyLabel: "Please fill class and batch",
    fieldKeyLists: {
      // class_course_batch_data: "class_id",
      // batch_list: "batch_id",
    },
    list: "sub_batch_list",
    keyValue: { label: "sub_batch_name", value: "sub_batch_id" },
  },
  {
    id: "assignment_start_date",
    type: "date-time-picker",
    label: "Assignment Start Date",
    className: "number-styles",
    required: true,
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    maxDateTime: "assignment_end_date",
  },
  {
    id: "assignment_end_date",
    type: "date-time-picker",
    label: "Assignment End Date",
    className: "number-styles",
    required: true,
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    minDateTime: "assignment_start_date",
    maxDateTime: "solution_visibility_date",
  },
  {
    id: "solution_visibility_date",
    type: "date-time-picker",
    label: "Solution Visibility Date",
    className: "number-styles",
    required: true,
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    minDateTime: "assignment_start_date",
  },
];

export const deliveryInitialValues: DeliveryFieldTypes = {
  academic_year_id: "",
  state_id: [],
  college_id: [],
  reservation_status_id: [],
  board_id: "",
  class_id: "",
  batch_id: [],
  sub_batch_id: [],
  assignment_start_date: undefined,
  assignment_end_date: undefined,
  solution_visibility_date: undefined,
};

export const deliveryValidationSchema = Yup.object().shape({
  academic_year_id: Yup.string().required("Academic year is required"),
  state_id: Yup.array().required("State is required").min(1, "Value required"),
  college_id: Yup.array()
    .required("College is required")
    .min(1, "Value required"),
  reservation_status_id: Yup.array()
    .required("Reservation status is required")
    .min(1, "Value required"),
  class_id: Yup.string().required("Class is required"),
  batch_id: Yup.array().required("Batch is required").min(1, "Value required"),
  sub_batch_id: Yup.array()
    .required("Sub batch is required")
    .min(1, "Value required"),
  assignment_start_date: Yup.date().required(
    "Assignment start date is required"
  ),
  assignment_end_date: Yup.date().required("Assignment end date is required"),
  solution_visibility_date: Yup.date().required(
    "Solution visibility date is required"
  ),
});
