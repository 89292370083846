import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IUpdateSubGroupItem } from "../interfaces";
interface IInitState {
  loading: boolean;
  updateSubGroupItemData: any;
  error: string;
  filtersData: any;
}

const initialState = {
  loading: false,
  updateSubGroupItemData: {},
  error: "",
  filtersData: "",
};

const updateSubQuestionGroupItemSlice = createSlice({
  name: "updateQuestionGroup",
  initialState,
  reducers: {
    updateSubQuestionGroupItemRequest(
      state: IInitState,
      action: PayloadAction<IUpdateSubGroupItem>
    ) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    updateSubQuestionGroupItemSuccess(
      state: IInitState,
      action: PayloadAction<any[]>
    ) {
      state.loading = false;
      state.updateSubGroupItemData = action.payload;
    },
  },
});

export const { updateSubQuestionGroupItemRequest, updateSubQuestionGroupItemSuccess } =
  updateSubQuestionGroupItemSlice.actions;
export default updateSubQuestionGroupItemSlice.reducer;
