export const microServices = {
    NCMSV2:"ncms/v2"
};

export const endpointsMd = {
    GET_STATE_LIST: `${microServices.NCMSV2}/usage/get-state-list`,
    GET_ANNOUNCEMENT_TYPE_LIST: `${microServices.NCMSV2}/usage/get-announcement-type-list`,
    GET_EXAM_LIST: `${microServices.NCMSV2}/usage/get-exam-list`,
    GET_SOURCE_LIST: `${microServices.NCMSV2}/usage/get-source-list`,
    GET_DELIVERY_TYPE_LIST: `${microServices.NCMSV2}/usage/get-delivery-type-list`,
    GET_COMPLEXITY_LEVEL_LIST: `${microServices.NCMSV2}/usage/get-complexity-level-list`,
    GET_DIFFICULTY_LEVEL_LIST: `${microServices.NCMSV2}/usage/get-difficulty-level-list`,
    GET_JUMBLE_STATUS_LIST: `${microServices.NCMSV2}/usage/get-jumble-status-list`,
    GET_QUESTION_POOL_LIST: `${microServices.NCMSV2}/usage/get-question-pool-list`,
    GET_TEMPLATE_POOL_LIST: `${microServices.NCMSV2}/usage/get-template-pool-list`,
    GET_NTSE_EXAM_LIST: `${microServices.NCMSV2}/usage/get-ntse-exam-list`, 
    GET_ATTENDANCE_STATUS_LIST: `${microServices.NCMSV2}/usage/get-attendance-status-list`, 
    GET_BRANCH_TYPE_LIST: `${microServices.NCMSV2}/usage/get-branch-type-list`, 
    GET_EXAM_CATEGORY_LIST: `${microServices.NCMSV2}/usage/get-exam-category-list`, 
    GET_SUB_EXAM_CATEGORY_LIST: `${microServices.NCMSV2}/usage/get-exam-sub-category-list`, 
    GET_TEMPLATE_TYPE_LIST: `${microServices.NCMSV2}/usage/get-template-type-list`, 
    GET_QUESTION_TYPE_LIST: `${microServices.NCMSV2}/usage/get-question-type-list`, 
    GET_BOARD_LIST: `${microServices.NCMSV2}/usage/get-board-list`, 
    GET_SUBJECT_LIST: `${microServices.NCMSV2}/usage/get-subject-list`, 
    GET_COLLEGE_LIST: `${microServices.NCMSV2}/usage/get-college-list`, 
    GET_EXAM_MODEL_LIST: `${microServices.NCMSV2}/usage/get-exam-model-list`, 
    GET_PATTERN_MASK_LIST: `${microServices.NCMSV2}/usage/get-pattern-mask-list`, 
    GET_MARKING_SCHMA_LIST: `${microServices.NCMSV2}/usage/get-marking-schema-list `, 
    GET_CNAPS_LEVEL_LIST: `${microServices.NCMSV2}/usage/get-cnaps-level-list`, 
    GET_REGISTRATION_STATUS_LIST: `${microServices.NCMSV2}/usage/get-registration-status-list`, 
    GET_PACKAGE_LIST: `${microServices.NCMSV2}/usage/get-package-list`, 
    GET_CLASS_LIST: `${microServices.NCMSV2}/usage/get-class-list`, 
    GET_ACADEMIC_YEAR_LIST: `${microServices.NCMSV2}/usage/get-academic-year-list`, 
    GET_TERM_EXAM_LIST: `${microServices.NCMSV2}/usage/get-term-exam-list`, 
    GET_BATCH_LIST: `${microServices.NCMSV2}/usage/get-batch-list`, 
    GET_SUB_BATCH_LIST: `${microServices.NCMSV2}/usage/get-sub-batch-list`, 
    GET_COURSE_LIST: `${microServices.NCMSV2}/usage/get-course-list`, 
    GET_TEMPLATE_LIST: `${microServices.NCMSV2}/usage/get-template-list`, 
    GET_PROGRAM_LIST: `${microServices.NCMSV2}/usage/get-program-list`, 
    GET_RESOURCE_TYPE_LIST: `${microServices.NCMSV2}/usage/get-resource-type-list`, 
    GET_RESOURCE_TYPE_ID_LIST: `${microServices.NCMSV2}/embibe/resource-type-list`, 
}