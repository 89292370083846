import React from "react";
import {
  Box,
  Badge,
  Stack,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { menuStyles, useStyles } from "./styles";
import { useHistory } from "react-router";
import NcmsLogo from "assets/nCMS_logo.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TypographyHeading } from "shared/molecules/typography-layout";

export const profileTheme = createTheme({
  palette: {
    primary: { main: "rgb(0, 0, 0)" },
    background: { paper: "rgb(255, 255, 255)" },
  },
});

type profileProps = {
  hideLogo?: boolean;
  hideUser?: boolean;
  itemName?: any;
};

function Profile({ itemName, hideLogo, hideUser }: profileProps) {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSignOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("metadata");
    history.push("/login");
  };
  const handleChangePassword = () => {
    history.push("/change-password");
  };
  return (
    <ThemeProvider theme={profileTheme}>
      <div style={{ display: "flex" }}>
        <TypographyHeading sx={{ backgroundColor: "black" }}>
          {itemName}
        </TypographyHeading>
        <Box>
          {hideLogo === false && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: "100%", p: 2, pt: 2 }}
            >
              <img
                src={NcmsLogo}
                alt=""
                style={{ width: "80px", marginLeft: "6px" }}
              />
              {/* <Typography variant="subtitle1">CMS ADMIN</Typography> */}
            </Stack>
          )}
          {hideUser === false && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-evenly"
              sx={{ height: "56px" }}
            >
              <AccountCircleOutlinedIcon
                sx={{ mr: "5px" }}
                className={classes.badge}
              />
              <Box sx={{ mt: "5px" }}>
                <Typography
                  fontSize="14px"
                  style={{
                    width: "60px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  title={Capitalize(localStorage.getItem("userName"))}
                >
                  {Capitalize(localStorage.getItem("userName"))}
                </Typography>
                {/* <Typography fontSize="12px">Admin</Typography> */}
              </Box>
              <Badge
                color="success"
                variant="dot"
                invisible={true}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                sx={{ mr: "16px" }}
                onClick={handleClick}
              >
                <KeyboardArrowDownIcon className={classes.badge} />
              </Badge>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={menuStyles}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {/* <MenuItem>
                Signed in as <br />
                {localStorage.getItem("userName")}
              </MenuItem>
              <Divider /> */}
                <MenuItem onClick={() => handleSignOut()}>
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  Sign Out
                </MenuItem>
                {/* <MenuItem onClick={() => handleChangePassword()}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                Change Password
              </MenuItem> */}
              </Menu>
            </Stack>
          )}
        </Box>
        {/* <Stack justifyContent="center" alignItems="center">
          <Avatar
            alt="user avatar"
            src="https://www.leisurekart.com/wp-content/uploads/2018/05/Adnan-Khokhawala-Leisurekart-Review.png"
            className={classes.avatar}
          />
          <Typography className={classes.title}>Brian Hughes</Typography>
          <Typography className={classes.mail}>mail@gmail.com</Typography>
        </Stack> */}
      </div>
    </ThemeProvider>
  );
}

export default Profile;

const Capitalize = (value) => {
  return value && value[0]?.toUpperCase() + value?.slice(1);
};
