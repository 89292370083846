import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {ICreateWarmUpDeliveryReqBody } from '../interfaces'


interface IInitState {
  loading: boolean;
  updateData: any;
  error: string;
}

const initialState = {
  loading: false,
  updateData: {},
  error: "",
};

const updateWarmUpDeliverySlice = createSlice({
  name: "updatearmupdelivery",
  initialState,
  reducers: {
    updateWarmUpDeliveryRequest(
      state: IInitState,
      action: PayloadAction<ICreateWarmUpDeliveryReqBody>
    ) {
      state.loading = true;
    },
    updateWarmUpDeliverySuccess(state: IInitState, action) {
      state.loading = false;
      state.updateData = action.payload;
    },
  },
});

export const { updateWarmUpDeliveryRequest, updateWarmUpDeliverySuccess } =
updateWarmUpDeliverySlice.actions;
export default updateWarmUpDeliverySlice.reducer;
