export const microServices = {
  NCMSV1: "ncms/v1",
  NCMSV2: process.env.REACT_APP_NCMS_MICROSERVICE,
};

export const endpoints = {
  LIBRARY_CREATE: `${microServices.NCMSV2}/library/create`,
  LIBRARY_LIST: `${microServices.NCMSV2}/library/list`,
  UPDATE_LIBRARY: `${microServices.NCMSV2}/library/update`,
  DELETE_VIDEO_LIBRARY: `${microServices.NCMSV2}/library/delete`,
  GET_LIBRARY_DATA: `${microServices.NCMSV2}/library/get-details-by-id`,
  GET_COURSE_SYLLABUS_DATA: `${microServices.NCMSV1}/usage/course-syllabus-metadata`,
};
