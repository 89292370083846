const { REACT_APP_BUCKET_NAME } = process.env;
const awsConfig = {
  aws_project_region: "ap-south-1",
  identity_pool_id: "ap-south-1:cc094fe1-ec85-4fc8-9b4c-543d052e104f",
  aws_cognito_region: "ap-south-1",
  // "aws_user_pools_id": "us-west-2_ZrBlDAQWQ",
  // "aws_user_pools_web_client_id": "4jnkas9hqn2im7blmjge78i57e",
  oauth: {},
  aws_user_files_s3_bucket: REACT_APP_BUCKET_NAME,
  aws_user_files_s3_bucket_region: "ap-south-1",
};
export default awsConfig;
