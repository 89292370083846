import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'snackbar',
  initialState: {
    value: "",
    isShow: false,
    type: ""
  },
  reducers: {
    snackbarRequest: (state, action) => {
      if (action.payload !== "") {
        state.value = action.payload.message;
        state.isShow = true;
        state.type = action.payload.type;
      } else {
        state.isShow = false
      }
    },
  },
});

export const { snackbarRequest } = slice.actions;


export const showSnackBar: any = state => state.snackBar;

export default slice.reducer;
