import { createSlice } from "@reduxjs/toolkit";
import { deliveryInitialValues } from "../routes/create-assignment/assignment-delivery/helpers";

const initialState = {
  loading: false,
  updateLoading: false,
  deliveryData: deliveryInitialValues,
  error: "",
};

const assignmentDeliveryUpdateSlice = createSlice({
  name: "assignmentDeliveryUpdate",
  initialState: initialState,
  reducers: {
    updateAssignmentDeliveryRequest(state, action) {
      state.updateLoading = true;
    },
    getAssignmentDeliveryRequest(state, action) {
      state.loading = true;
    },
    getAssignmentDeliverySuccess(state, action) {
      state.loading = false;
      state.deliveryData = action.payload;
    },
  },
});
export const {
  getAssignmentDeliveryRequest,
  getAssignmentDeliverySuccess,
  updateAssignmentDeliveryRequest,
} = assignmentDeliveryUpdateSlice.actions;
export default assignmentDeliveryUpdateSlice.reducer;
