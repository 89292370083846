import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IGetAnswerKey } from "../interfaces";
interface IInitState {
  loading: boolean;
  data: IBuildTest[];
  error: string;
  filtersData:any;
}

const initialState = {
  loading: false,
  data: [],
  error: "",
  filtersData:'',
};

const getDeliveryByID = createSlice({
  name: "getDeliveryById",
  initialState,
  reducers: {
    devliveryByIdRequest(
      state: IInitState,
      action: PayloadAction<IGetAnswerKey>
    ) {
      state.loading = true;
      state.filtersData = action.payload
    },
    devliveryByIdSuccess(
      state: IInitState,
      action: PayloadAction<any[]>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { devliveryByIdRequest, devliveryByIdSuccess } =
  getDeliveryByID.actions;
export default getDeliveryByID.reducer;
