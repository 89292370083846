import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

const deliveryListSlice = createSlice({
  name: "deliveryList",
  initialState,
  reducers: {
    assignmentDeliveryListRequest(state, action) {
      state.loading = true;
    },
    assignmentDeliveryListSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    resetAssignementDeliveryList(state){
      state.data= []
    }
  },
});

export const { assignmentDeliveryListRequest, assignmentDeliveryListSuccess, resetAssignementDeliveryList } =
  deliveryListSlice.actions;

export default deliveryListSlice.reducer;
