import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAnnouncement,
  ICreateAnouncementListReqBody,
  IAnnouncementData,
} from "../interfaces";

interface IInitState {
  loading: boolean;
  data: any;
  error: string;
}

const initialState = {
  loading: false,
  data: [],
  error: "",
};

const createAnnouncementtSlice = createSlice({
  name: "createannouncement",
  initialState,
  reducers: {
    createAnnouncementRequest(
      state: IInitState,
      action: PayloadAction<ICreateAnouncementListReqBody>
    ) {
      state.loading = true;
    },
    createAnnouncementSuccess(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { createAnnouncementRequest, createAnnouncementSuccess } =
  createAnnouncementtSlice.actions;
export default createAnnouncementtSlice.reducer;
