export const microServices = {
  NCMSV1: "ncms/v1",
  TCMSV1: "tcms/v1",
  NCMSV2: process.env.REACT_APP_NCMS_MICROSERVICE,
};

export const endpoints = {
  DELETE_TERM_EXAM: `${microServices.NCMSV1}/descriptive/delete-descriptive-exam`,
  TERM_EXAM_LIST: `${microServices.NCMSV2}/descriptive/get-descriptive-exam-list`,
  TERM_EXAM_DELIVERY_LIST: `${microServices.NCMSV2}/descriptive/get-descriptive-exam-delivery-list`,
  CREATE_NEW_TERM_EXAM: `${microServices.NCMSV2}/descriptive/create-descriptive-exam`,
  GET_TERM_EXAM_BY_ID: `${microServices.NCMSV2}/descriptive/get-descriptive-exam-by-id`,
  GET_TERM_EXAM_DELIVERY_BY_ID: `${microServices.NCMSV2}/descriptive/get-descriptive-exam-delivery-by-id`,
  UPDATE_TERM_EXAM_DELIVERY: `${microServices.NCMSV2}/descriptive/update-descriptive-exam-delivery`,

  ASSIGNMENT_LIST: `${microServices.NCMSV1}/assignment/get-assignment-list`,
  GET_NEW_ASSIGNMENT_LIST: `${microServices.NCMSV2}/assignment/get-assignment-list`,

  CREATE_TERM_EXAM_DELIVERY: `${microServices.NCMSV2}/descriptive/create-descriptive-exam-delivery`,
  GET_TERM_EXAM_DELIVERY_DATA: `${microServices.NCMSV2}/descriptive/get-descriptive-exam-delivery-data`,
  DOWNLOAD_TERM_EXAM_DELIVERY_DATA: `${microServices.NCMSV2}/descriptive/download-descriptive-exam-delivery-data`,

  EXAM_PAPER_LIST: `${microServices.NCMSV2}/descriptive/get-descriptive-exam-paper-list`,
  GET_PREVIEW_DATA_API: `${microServices.TCMSV1}/usage/store-sbd-question-data`,
  DELETE_EXAM_PAPER: `${microServices.NCMSV2}/descriptive/delete-descriptive-exam-paper`,
  STORE_SBD_QUESTION_DATA: `${microServices.TCMSV1}/usage/store-sbd-question-data`,
  SAVE_EXAM_PAPER: `${microServices.NCMSV2}/descriptive/create-descriptive-exam-paper`,
};

export const PAPER_SIZES = {
  a4: "A4",
  custom: ["18cm", "27.5cm"],
};
