import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, ICreateSubGroupItem } from "../interfaces";
interface IInitState {
  loading: boolean;
  data: any;
  error: string;
  successMsg: any;
}

const initialState = {
  loading: false,
  data: {},
  error: "",
  successMsg: {},
};

const addContentSlice = createSlice({
  name: "addContent",
  initialState,
  reducers: {
    addContentRequest(state: IInitState, action: PayloadAction<any>) {
      state.loading = true;
      // state.filtersData = action.payload;
    },
    addContentSuccess(state: IInitState, action: PayloadAction<any[]>) {
      state.loading = false;
      state.data = action.payload;
    },
    addContentError(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
    },
    addContentSuccessMessage(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.successMsg = action.payload;
    },
  },
});

export const {
  addContentRequest,
  addContentSuccess,
  addContentSuccessMessage,
  addContentError,
} = addContentSlice.actions;
export default addContentSlice.reducer;
