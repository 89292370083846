import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  data: {
    activity_type_list: [
      {
        activity_type_id: "fun-sheet",
        activity_type_name: "Fun Sheet",
      },
      {
        activity_type_id: "magazine",
        activity_type_name: "Magazine",
      },
    ],
    assignment_type_list: [
      {
        assignment_type_id: "assignment",
        assignment_type_name: "Assignment",
      },
      {
        assignment_type_id: "interactive_scrolls",
        assignment_type_name: "Interactive Scrolls",
      },
      {
        assignment_type_id: "listening_comprehension",
        assignment_type_name: "Listening Comprehension",
      },
      {
        assignment_type_id: "live_worksheet",
        assignment_type_name: "Live Worksheet",
      },
    ],
    hierarchy_type_list: [
      {
        hierarchy_type_id: "jee",
        hierarchy_type_name: "JEE",
      },
      {
        hierarchy_type_id: "neet",
        hierarchy_type_name: "NEET",
      },
    ],
    custom_template_list:[
      { branch_type_id: "school",
      branch_type_name: "school",},
      { branch_type_id: "college",
      branch_type_name: "college",}
    ],
    standard_template_list:[
      { branch_type_id: "school",
      branch_type_name: "school",},
      { branch_type_id: "college",
      branch_type_name: "college",}
    ],
    misc_data:[
      {displayName: 'Student Details', value: 'admission_number', placeholder: "Please enter Admission Number"},
      {displayName: 'Test Details', value: 'test_uri', placeholder: "Please enter Test uri"},
      {displayName: 'Item', value: 'item_uri', placeholder: "Please enter Item uri or id"},
      {displayName: 'Delivery Details', value: 'delivery_uri', placeholder: "Please enter delivery uri"},
      {displayName: 'Delivery Execution Details', value: 'delivery_execution', placeholder: "Please enter admission number"},
      {displayName: 'Student On Going Test Details', value: 'student_ongoing_tests', placeholder: "Please enter admission number"},
      {displayName: 'Student Scheduled Test List', value: 'student_scheduled_test_list', placeholder: "Please enter admission number"},
      {displayName: 'Test Items', value: 'test_items', placeholder: "Please enter test id"},
      {displayName: 'College Details', value: 'college_name', placeholder: "Please enter college name"},
      {displayName: 'Batch Details', value: 'batch_name', placeholder: "Please enter batch name"},
      {displayName: 'Teacher Mapping Details', value: 'employee_id', placeholder: "Please enter teacher id"},
      {displayName: 'Teacher Details', value: 'teacher_id', placeholder: "Please enter teacher id"},
      {displayName: 'DeliveryExecution Counts', value: 'delivery_uri_for_count', placeholder: "Please enter delivery uri"},
      {displayName: 'Chapter Details', value: 'chapter_name', placeholder: "Please enter chapter name"},
      {displayName: 'Live Class Details', value: 'live_class_admission_number', placeholder: "Please enter admission number"},
      {displayName: 'Term Exam - Completed', value: 'admission_number_term_exam', placeholder: "Please enter admission number"},
      {displayName: 'Profile Update Details', value: 'admission_number_profile_update', placeholder: "Please enter admission number"},
      {displayName: 'Create Delivery Execution', value: 'create_delivery_execution', placeholder: "deliveryId_admissionNumber"},
      {displayName: 'Student Registration Data', value: 'student_registration_info', placeholder: 'Enter Admission number'},
      {displayName: 'Teacher Registration Data', value: 'teacher_registration_info', placeholder: "Enter Employee Id"},
      {displayName: 'Assignment Details', value: 'assignment_details_admission_number', placeholder: "Please enter admission number"},
      {displayName: 'Missed Test List', value: 'missed_test_admission_number', placeholder: "Please enter admission number"},
      {displayName: 'Registration Error 2 List', value: 'registration_error_2', placeholder: "Click on Submit"},
      {displayName: 'Student Teacher Mapping Details', value: 'assignment_student_teacher_mapping', placeholder: "Please enter admission number"},
      {displayName: 'Branch Teacher mapping', value: 'branch_teacher_mapping', placeholder: "Please Enter Branch Name"},
  ]
  },
  error: false,
  updatedAt: new Date().getTime(),
};

const metadataSlice = createSlice({
  name: "metadata",
  initialState,
  reducers: {
    metadataRequest(state = initialState) {
      state.isLoading = true;
    },
    metadataSuccess(state = initialState, action: any) {
      state.isLoading = false;
      state.updatedAt = new Date().getTime();
      state.data = action.payload;
    },
    metadataUpdate(state, action) {
      const { key, value } = action.payload;
      state.isLoading = false;
      state.data = { ...state.data, [key]: value };
      state.updatedAt = new Date().getTime();
    },
  },
});

export const { metadataSuccess, metadataRequest,metadataUpdate } = metadataSlice.actions;

export default metadataSlice.reducer;
