import { endpoints } from "./constants";
import request from "utils/axios/service";
import instance from 'utils/axios/instance'
import { ITermExamListReqBody, ITermExamIdReqBody, ICreateTermBody } from "./interfaces";
import {getTime} from 'utils/time-util/getTime'

const getCreateDeliveryFormat = (payload: any) => {
  const res = payload.delivery_list.map((item: any) => ({
    academic_year: item.academic_year_id,
    state_list: item.state_id,
    college_list: item.college_id,
    class_id: item.class_id,
    batch_list: item.batch_id,
    sub_batch_list: item.sub_batch_id,
    board_list: item.board_id,
    reservation_status_list: item.reservation_status_id,
    start_date_time: getTime.dateTime(item.exam_start_date),
    end_date_time: getTime.dateTime(item.submission_end_date),
    submit_start_date_time: getTime.dateTime(item.submission_start_date),
    solution_visibility_date_time: item.solution_visibility_date_time
      ? getTime.dateTime(item.solution_visibility_date_time)
      : "",
  }));
  return {exam_id:payload.exam_id,descriptive_exam_id: payload.descriptive_exam_id, delivery_list: res };
};

export default class TermExamService {
  deleteTermExamApi(payload: ITermExamIdReqBody) {
    return request.post(endpoints.DELETE_TERM_EXAM, payload);
  }
  getSavedPapersListApi(payload: any) {
    return request.get(endpoints.EXAM_PAPER_LIST, payload);
  }
  deleteSavedPaperApi(payload: any) {
    const url = `${endpoints.DELETE_EXAM_PAPER}/${payload.id}`;
    return request.delete(url, {});
  }
  getTermExamListApi(payload: ITermExamListReqBody) {
    return request.get(endpoints.TERM_EXAM_LIST, payload);
  }
  getTermExamDeliveryListApi(payload: ITermExamIdReqBody) {
    return request.get(endpoints.TERM_EXAM_DELIVERY_LIST, payload);
  }

  getAssignmentListApi(payload: ITermExamIdReqBody) {
    return request.post(endpoints.ASSIGNMENT_LIST, payload);
  }
  getNewAssignmentListApi(payload: ITermExamIdReqBody) {
    return request.get(endpoints.GET_NEW_ASSIGNMENT_LIST, payload);
  }

  getCreateNewTermExamApi(payload: ICreateTermBody) {
    return request.post(endpoints.CREATE_NEW_TERM_EXAM, payload);
  }
  getTermExamDataApi(payload: any) {
    return request.get(endpoints.GET_TERM_EXAM_BY_ID, payload);
  }
  getTermExamDeliveryApi(payload: any) {
    return request.get(endpoints.GET_TERM_EXAM_DELIVERY_BY_ID, payload);
  }
  updateTermExamDeliveryApi(payload: any) {
    return request.post(endpoints.UPDATE_TERM_EXAM_DELIVERY, payload);
  }

  createTermExamDeliveriesApi(payload: any) {
    const reqBody = getCreateDeliveryFormat(payload);
    return request.post(endpoints.CREATE_TERM_EXAM_DELIVERY, reqBody);
  }
  getTermExamDeliveryDataApi(payload: any) {
    return request.get(endpoints.GET_TERM_EXAM_DELIVERY_DATA, payload);
  }
  downloadTermExamDeliveryDataApi(payload: any) {
    return instance.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/${endpoints.DOWNLOAD_TERM_EXAM_DELIVERY_DATA}`,
      { params: payload }
    );
  }
  getPreviewDataApi(payload: any) {
    return request.post(endpoints.GET_PREVIEW_DATA_API, payload);
  }
  createExamPaper(payload) {
    return request.post(endpoints.SAVE_EXAM_PAPER, payload);
  }
}
