import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IFetchQuestionDetails } from "../interfaces";
interface IInitState {
  loading: boolean;
  oqbQuestionProperties: any;
  error: string;
  fetchId: string;
}

const initialState = {
  loading: false,
  oqbQuestionProperties: {},
  error: "",
  fetchId: "",
};

const getOqbQuestionPropertiesByIDSlice = createSlice({
  name: "getQuestionDetails",
  initialState,
  reducers: {
    getOqbQuestionPropertiesByIDRequest(
      state: IInitState,
      action: PayloadAction<IFetchQuestionDetails>
    ) {
      state.loading = true;
      // state.filtersData = action.payload
    },
    getOqbQuestionPropertiesByIDSuccess(
      state: IInitState,
      action: PayloadAction<any[]>
    ) {
      state.loading = false;
      state.oqbQuestionProperties = action.payload;
      state.error = "";
    },
    getOqbFetchError(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getOqbFetchFormId(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.fetchId = action.payload;
    },
  },
});

export const {
  getOqbQuestionPropertiesByIDRequest,
  getOqbQuestionPropertiesByIDSuccess,
  getOqbFetchFormId,
  getOqbFetchError,
} = getOqbQuestionPropertiesByIDSlice.actions;
export default getOqbQuestionPropertiesByIDSlice.reducer;
