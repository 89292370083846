import { saveAs } from "file-saver";

export const getQueryParam = (param: string, location: any) => {
  let str = location?.pathname?.includes(param)
    ? location.pathname
    : location.search;
  let result = str.match(new RegExp("(\\?|&)" + param + "(\\[\\])?=([^&]*)"));
  return result ? result[3]?.replaceAll("%22", "") : false;
};

export const isAuthorized = () => {
  return localStorage.getItem("token");
};

//localstorage
export const getItem = (key: string) => {
  return JSON.parse(localStorage.getItem(key) || "{}");
};

export const setItem = (key: string, value: string | string[] | any) => {
  localStorage.setItem(key, JSON.stringify(value) || "{}");
};

export const removeItem = (key: string, value: string | string[] | any) => {
  localStorage.setItem(key, JSON.stringify(value) || "{}");
};

//get mandatory field value
export const getMandatoryFieldValue = (key, mandatoryFields, frms: any) => {
  let arr: any = [];
  for (const { id } of mandatoryFields) {
    arr.push(frms[key][id]);
  }
  return arr;
};

//all fields must filled
export const checkMandatoryFields = (arr) => {
  if (!arr || arr.length === 0) return false;
  return arr.every((field: any) => {
    if (Array.isArray(field)) return field.length > 0;
    return field !== "";
  });
};

export const downloadXlsxFile = async (res, fileName) => {
  if (res && res.status === 200 && res.data) {
    const body = res && res.data ? res.data : [];
    let blob = new Blob([body], { type: "text/csv;charset=utf-8" });
    saveAs(blob, fileName);
  } else if (typeof res == "string") {
    let blob = await fetch(res).then((r) => r.blob());
    let newBlob = new Blob([blob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(newBlob, fileName);
  }
};

export const changeStyleTag = (data) => {
  return data && data.replaceAll("style=", "styles=");
};

const isDev = process.env.NODE_ENV !== "production";
export const DOMAIN = process.env.REACT_APP_DOMAIN;

export const objectDuplicationRemover = (data = [], key) => {
  const mainData: any =
    data && data.length > 0 && data.map((item: any) => [item[key], item]);
  const newData: any = mainData && mainData.length > 0 && new Map(mainData);
  return newData && [...newData.values()];
};

export const addImageStrToBase64Data = (dataString) => {
  if (!dataString) return "";
  return dataString.replaceAll(/data:png;base64/g, "data:image/png;base64");
};

export const isEmpty = (obj: any) => {
  if (obj !== null && obj !== undefined) {
    // for general objects
    if (typeof obj === "string") {
      if (obj.trim() === "" || obj == "null") {
        // for string
        return true;
      }

      return false;
    } else if (obj.length <= 0) {
      // for array
      return true;
    } else if (typeof obj === "object") {
      let keys = Object.keys(obj);
      let len = keys.length;
      if (len <= 0) {
        return true;
      }
      return false;
    }
    return false;
  }

  return true;
};