import { takeLatest, put, call, delay } from "redux-saga/effects";
import { ResponseGenerator } from "interfaces";
import {
  narayanaDummyFeatureConfig,
  externalDummyFeatureConfig,
} from "./helpers";
import { externalUsers } from "./constants";
import {
  featureConfigRequest,
  featureConfigSuccess,
} from "./slices/feature-config-slice";
import AppService from "./service";

function* getFeatureConfigWorker(action: any) {
  try {
    // const res: ResponseGenerator = yield call(
    //   AppService.getFeatureConfig,
    //   action.payload
    // );
    const username = yield localStorage.getItem("userName");
    const res: any = externalUsers[username]
      ? externalDummyFeatureConfig
      : narayanaDummyFeatureConfig;

    if (res.status === 200 && res.data.status === "success") {
      yield put(featureConfigSuccess(res.data.data));
    }
  } catch (e) {}
}

export default function* appSaga() {
  yield takeLatest(featureConfigRequest.type, getFeatureConfigWorker);
}
