import { IWarmUpTestListReqBody } from "./interfaces";
import { endpoints } from "./constants";
import request from "utils/axios/service";

export default class WarmUpService {
  static getWarmUpTestListApi(payload: IWarmUpTestListReqBody) {
    return request.get(endpoints.WARM_UP_TEST_LIST, payload);
  }

  static getSubjectChapterData(payload) {
    return request.get(endpoints.META_DATA, payload);
  }

  static getWarmUpDeliveryDetailsRequest(payload: any) {
    return request.get(endpoints.GET_WARM_UP_DELIVERY_DETAILS, payload);
  }

  static createWarmUpDeliveryApi(payload: any) {
    return request.post(endpoints.CREATE_WARM_UP_DELIVERY, payload);
  }

  static updateWarmUpDeliveryDetails(payload: any) {
    return request.post(endpoints.UPDATE_WARM_UP, payload);
  }
}
