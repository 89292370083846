import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system";

const useStyles = makeStyles((theme: Theme) => ({
  spacing :{
    "& .css-1t29gy6-MuiToolbar-root": {
        ['@media (min-width:600px)']: { // eslint-disable-line no-useless-computed-key
            minHeight:'40px'
        }
      },
  },
  formsWrap: {
    width: "100%",
  },
  padding: {
    "& .css-19kzrtu  ": {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}));

export default useStyles;
