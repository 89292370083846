import { endpoints } from "./constants";
import request from "utils/axios/service";
import { ILibraryListReqBody } from "./interfaces";

export default class LibraryService {
  static createLibraryApi(payload: ILibraryListReqBody) {
    return request.post(endpoints.LIBRARY_CREATE, payload);
  }
  static getLibraryListApi(payload: ILibraryListReqBody) {
    return request.get(endpoints.LIBRARY_LIST, payload);
  }
  static getLibraryDataApi(payload: any) {
    return request.post(endpoints.GET_LIBRARY_DATA, payload);
  }
  static updateLibraryApi(payload: any) {
    return request.post(endpoints.UPDATE_LIBRARY, payload);
  }
  static deleteLibraryVideoApi(payload: any) {
    return request.delete(
      `${endpoints.DELETE_VIDEO_LIBRARY}/${payload.library_id}`,
      {}
    );
  }
  static deleteLibraryResourceApi(payload: any) {
    return request.delete(
      `${endpoints.DELETE_VIDEO_LIBRARY}/${payload.library_id}`,
      {}
    );
  }
}
