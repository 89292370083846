export const microServices = {
  NCMSV2: process.env.REACT_APP_NCMS_MICROSERVICE,
};

export const endpoints = {
  FEATURE_CONFIG: `${microServices.NCMSV2}/feature-config`,
};

export const externalUsers = {
  "e-ncms-01": {
    objectId: "DrixAGouhi",
    username: "",
  },
  "e-ncms-02": {
    objectId: "XZGNnrxG9G",
    username: "e-ncms-02",
  },
  "e-ncms-03": {
    objectId: "nHTm88PvQp",
    username: "e-ncms-03",
  },
  "e-ncms-04": {
    objectId: "7GZUFMOeGn",
    username: "e-ncms-04",
  },
  "e-ncms-05": {
    objectId: "Qdn55xKRMP",
    username: "e-ncms-05",
  },
  "e-ncms-06": {
    objectId: "KsyOEpKPXL",
    username: "e-ncms-06",
  },
  "e-ncms-07": {
    objectId: "yZpUm4ivGi",
    username: "e-ncms-07",
  },
  "e-ncms-08": {
    objectId: "yjCrpHgXjU",
    username: "e-ncms-08",
  },
  "e-ncms-09": {
    objectId: "CUHNcmXCqy",
    username: "e-ncms-09",
  },
  "e-ncms-10": {
    objectId: "391KdiK2KV",
    username: "e-ncms-10",
  },
  "e-ncms-11": {
    objectId: "zksiFs1gCn",
    username: "e-ncms-11",
  },
  "e-ncms-12": {
    objectId: "Hd6ueSxNgt",
    username: "e-ncms-12",
  },
  "e-ncms-13": {
    objectId: "YwGXCpD4FK",
    username: "e-ncms-13",
  },
  "e-ncms-14": {
    objectId: "XX0ojCTTGK",
    username: "e-ncms-14",
  },
  "e-ncms-15": {
    objectId: "wtqxCW80WE",
    username: "e-ncms-15",
  },
  "e-ncms-16": {
    objectId: "mDsE6n2Cd1",
    username: "e-ncms-16",
  },
  "e-ncms-17": {
    objectId: "GyC2yutMq1",
    username: "e-ncms-17",
  },
  "e-ncms-18": {
    objectId: "JYu2ZUf53D",
    username: "e-ncms-18",
  },
  "e-ncms-19": {
    objectId: "JvFQEWFKQJ	",
    username: "e-ncms-19",
  },
  "e-ncms-20": {
    objectId: "9Ce45143wb",
    username: "e-ncms-20",
  },
};
