import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  previewData: false,
  isLoading: false,
  error: false,
  updatedAt: new Date().getTime(),
};

const previewSlice = createSlice({
  name: "previewSlice",
  initialState,
  reducers: {
    getPreviewDataRequest(state: any, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    getPreviewDataSuccess(state: any, action: PayloadAction<any>) {
      state.isLoading = false;
      state.previewData = action.payload;
    },
    getPreviewDataError(state: any, action: PayloadAction<any>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    mathjaxUpdateTime(state: any) {
      state.updatedAt = new Date().getTime();
    },
  },
});

export const {
  getPreviewDataRequest,
  getPreviewDataSuccess,
  getPreviewDataError,
  mathjaxUpdateTime,
} = previewSlice.actions;
export default previewSlice.reducer;
