import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IActivitiesFunsheetDetailsInitialState,
  IActivitesFunsheetDetailsReqBody,
  IActivitiesFunsheetData,
} from "pages/activities/interfaces";

const initialState: IActivitiesFunsheetDetailsInitialState = {
  loading: false,
  data: {},
  error: "",
};

const funSheetDetailsSlice = createSlice({
  name: "funSheetDetails",
  initialState,
  reducers: {
    funSheetDetailsRequest(
      state,
      action: PayloadAction<IActivitesFunsheetDetailsReqBody>
    ) {
      state.loading = true;
    },
    funSheetDetailsSuccess(
      state,
      action: PayloadAction<IActivitiesFunsheetData>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { funSheetDetailsRequest, funSheetDetailsSuccess } =
  funSheetDetailsSlice.actions;
export default funSheetDetailsSlice.reducer;
