import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITermExam, ITermExamListReqBody, IInitState } from "../interfaces";
import { termExamInitialValues } from "../routes/create-term-exam/term-exam-form/helper";
const initialState = {
  loading: false,
  dataLoading: false,
  data: [],
  filtersData: { class_id: "", term: "" },
  termExamData: termExamInitialValues,
  error: "",
};

const termExamListSlice = createSlice({
  name: "termExamList",
  initialState,
  reducers: {
    termExamListRequest(
      state: IInitState,
      action: PayloadAction<ITermExamListReqBody>
    ) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    termExamListSuccess(state: IInitState, action: PayloadAction<ITermExam[]>) {
      state.loading = false;
      state.data = action.payload;
    },
    resetTermExamList(state: IInitState) {
      state.data = [];
      state.filtersData = { class_id: "", term: "" };
    },
    termExamDataRequest(state: IInitState, action: PayloadAction<any>) {
      state.dataLoading = true;
    },
    termExamDataSuccess(state: IInitState, action: PayloadAction<any>) {
      state.dataLoading = false;
      state.termExamData = action.payload;
    },
    resetTermExamData(state: IInitState) {
      state.termExamData = termExamInitialValues;
    },
  },
});

export const {
  termExamListRequest,
  termExamListSuccess,
  resetTermExamList,
  termExamDataRequest,
  termExamDataSuccess,
  resetTermExamData,
}: any = termExamListSlice.actions;
export default termExamListSlice.reducer;
