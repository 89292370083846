import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitialState {
  isLoading: boolean;
  data: any;
  error: any;
  message:string;
}

const initialState: IInitialState = {
  isLoading: false,
  data: false,
  error: false,
  message:""
};

const saveSectionWiseQuestionsSlice = createSlice({
  name: "saveSectionWiseQuestions",
  initialState,
  reducers: {
    saveSectionWiseQuesionsRequest(state: IInitialState, action: PayloadAction<any>) {
      state.isLoading = true;
      state.error = false;
    },
    saveSectionWiseQuesionsSuccess(state: IInitialState, action: PayloadAction<any>) {
      state.isLoading = false;
    },
    saveSectionWiseQuesionsError(state: IInitialState, action: PayloadAction<any>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    saveSectionWiseQuesionsErrorMessage(state: IInitialState, action: PayloadAction<any>) {
      state.isLoading = false;
      state.message = action.payload;
    },
  },
});

export const {
  saveSectionWiseQuesionsRequest,
  saveSectionWiseQuesionsSuccess,
  saveSectionWiseQuesionsError,
  saveSectionWiseQuesionsErrorMessage,
} = saveSectionWiseQuestionsSlice.actions;

export default saveSectionWiseQuestionsSlice.reducer;
