import { createTheme } from "@mui/material/styles";
import { indigo } from "@mui/material/colors";

const font = "Roboto";

// export const themeOptions: ThemeOptions = {
//   palette: {
//     type: "light",
//     primary: {
//       main: "#766df4",
//     },
//     secondary: {
//       main: "#c5cae9",
//     },
//     background: {
//       default: "#f7f7fc",
//     },
//     info: {
//       main: "#6a9bf4",
//     },
//     success: {
//       main: "#16c995",
//     },
//     warning: {
//       main: "#ffb15c",
//     },
//     error: {
//       main: "#f74f78",
//     },
//   },
//   shape: {
//     borderRadius: 4,
//   },
//   spacing: 8,
//   overrides: {
//     MuiSwitch: {
//       root: {
//         width: 42,
//         height: 26,
//         padding: 0,
//         margin: 8,
//       },
//       switchBase: {
//         padding: 1,
//         "&$checked, &$colorPrimary$checked, &$colorSecondary$checked": {
//           transform: "translateX(16px)",
//           color: "#fff",
//           "& + $track": {
//             opacity: 1,
//             border: "none",
//           },
//         },
//       },
//       thumb: {
//         width: 24,
//         height: 24,
//       },
//       track: {
//         borderRadius: 13,
//         border: "1px solid #bdbdbd",
//         backgroundColor: "#fafafa",
//         opacity: 1,
//         transition:
//           "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
//       },
//     },
//   },
// };
const indigoTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      light: indigo[300],
      main: indigo[500],
      dark: indigo[700],
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: font,
  },
});

export default indigoTheme;
