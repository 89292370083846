import * as Yup from "yup";
import { ICreateMonthlyScheduleFieldTypes } from "pages/monthly-schedule/interfaces";

export const libResourceCols = [
  "ID",
  "Name",
  "Subject Name",
  "Chapter Name",
  "Page Count",
  "PDF URL",
  "Actions",
];
export const libVideoCols = [
  "ID",
  "Video Name",
  "Subject Name",
  "Chapter Name",
  "Duration",
  "Video URL",
  "Actions",
];

export const mandatoryFields = [
  { id: "class_id" },
  { id: "course_id" },
  { id: "state_id" },
  { id: "batch_id" },
  { id: "sub_batch_id" },
  { id: "start_date" },
  { id: "academic_year_id" },
  { id: "end_date" },
  { id: "pdf_name" },
  { id: "week_number" },
];

export const monthlyScheduleProperties = [
  {
    id: "class_id",
    type: "single-select-dependency",
    label: "Class *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    required: true,
    list: "class_course_batch_data",
    keyValue: { label: "class_name", value: "class_id" },
    dependentFields: ["course_id", "batch_id", "sub_batch_id"],
  },
  {
    id: "course_id",
    type: "single-select-dependency",
    label: "Course *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: true,
    isDependent: true,
    options: [],
    list: "course_list_dep",
    dependencyLabel: "Please select Class",
    keyValue: { label: "course_name", value: "course_id" },
    fieldKeyLists: {
      // class_course_batch_data: "class_id", // create of monthlysch
    },
  },
  {
    id: "academic_year_id",
    type: "single-select-dependency",
    label: "Academics *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    required: true,
    list: "academic_year_list",
    keyValue: { label: "year_name", value: "year_id" },
    //keyValue: { label: "academic_year", value: "academic_year_id" },
  },
  {
    id: "board_id",
    type: "single-select-dependency",
    label: "Board",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    list: "board_list",
    keyValue: { label: "board_name", value: "board_id" },
  },
  {
    id: "state_id",
    type: "multi-select-dependency",
    label: "State *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    options: [],
    required: true,
    list: "state_list",
    keyValue: { label: "state_name", value: "state_id" },
  },
  {
    id: "batch_id",
    type: "single-select-dependency",
    label: "Batch *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: true,
    isDependent: true,
    options: [],
    list: "batch_list",
    dependencyLabel: "Please select Class",
    keyValue: { label: "batch_name", value: "batch_id" },
    fieldKeyLists: {
      //class_course_batch_data: "class_id",
    },
    dependentFields: ["sub_batch_id"],
  },
  {
    id: "sub_batch_id",
    type: "multi-select-dependency",
    label: "Sub Batch *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: true,
    isDependent: true,
    options: [],
    list: "sub_batch_list",
    dependencyLabel: "Please select Class & Batch",
    keyValue: { label: "sub_batch_name", value: "sub_batch_id" },
    fieldKeyLists: {
      //class_course_batch_data: "class_id",
      //batch_list: "batch_id",
    },
  },
  {
    id: "week_number",
    type: "number",
    label: "Month Number *",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
  },
  {
    id: "start_date",
    type: "date-picker",
    required: true,
    label: "Start Date",
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
  },
  {
    id: "end_date",
    type: "date-picker",
    label: "End Date",
    required: true,
    className: "number-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
  },
  {
    id: "pdf_name",
    type: "upload",
    label: "Upload PDF *",
    className: "select-styles",
    breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
    required: true,
    acceptTypes: ".pdf",
    fileType: [".pdf"],
    fields: { class_id: "", course_id: "" },
    feature: "schedule",
  },
];

export const createMonthlyScheduleInitialValues: ICreateMonthlyScheduleFieldTypes =
  {
    class_id: "",
    course_id: "",
    academic_year_id: "",
    board_id: "",
    state_id: [],
    batch_id: "",
    sub_batch_id: [],
    week_number: "",
    start_date: undefined,
    end_date: undefined,
    pdf_name: "",
  };

export const createMonthlyScheduleValidationSchema = Yup.object().shape({
  class_id: Yup.string().required("class is required"),
  course_id: Yup.string().required("course is required"),
  academic_year_id: Yup.string().required("academic year is required"),
  state_id: Yup.array().required("state is required").min(1, "value required"),
  batch_id: Yup.string().required("batch is required"),
  sub_batch_id: Yup.array()
    .required("sub batch is required")
    .min(1, "value required"),
  pdf_name: Yup.string().required("week_number is required"),
  week_number: Yup.number()
    .min(1, "Invalid Month")
    .max(12, "Invalid Month")
    .required("Month is required")
    .typeError("enter valid number"),
  start_date: Yup.date().required("start date is required"),
  end_date: Yup.date().required("end date is required"),
});
