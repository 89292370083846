import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IBuildTestListReqBody } from "../interfaces";
interface IInitState {
  loading: boolean;
  data: IBuildTest[];
  error: any;
  filtersData: any;
}

const initialState = {
  loading: false,
  data: [],
  error: "",
  filtersData: "",
};

const getAnswerKey = createSlice({
  name: "getAnswer",
  initialState,
  reducers: {
    getAnswerKeyRequest(state: IInitState, action: PayloadAction<any>) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    getAnswerKeySuccess(state: IInitState, action: PayloadAction<any[]>) {
      state.loading = false;
      state.data = action.payload;
    },
    getAnswerKeyError(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getAnswerKeyRequest,
  getAnswerKeySuccess,
  getAnswerKeyError,
}: any = getAnswerKey.actions;
export default getAnswerKey.reducer;
