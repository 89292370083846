export const microServices = {
    NCMSV2: process.env.REACT_APP_NCMS_MICROSERVICE,
  };
  
  export const endpoints={
      CREATE_FUN_SHEET:`${ microServices.NCMSV2}/fun-sheet/create-funsheet`,
      FUN_SHEET_LIST:`${microServices.NCMSV2}/fun-sheet/get-funsheet-list`,
      GET_FUN_SHEET_DETAILS:`${microServices.NCMSV2}/fun-sheet/get-funsheet-details`,
      UPDATE_FUN_SHEET:`${microServices.NCMSV2}/fun-sheet/update-funsheet`,

      CREATE_MAGAZINE : `${ microServices.NCMSV2}/magazine/create-magazine`,
      MAGAZINE_LIST : `${ microServices.NCMSV2}/magazine/get-magazine-list`,
      GET_MAGAZINE_DETAILS : `${ microServices.NCMSV2}/magazine/get-magazine-details`,
      UPDATE_MAGAZINE : `${ microServices.NCMSV2}/magazine/update-magazine`,
  }