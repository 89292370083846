export const microServices = {
  NCMSV2: process.env.REACT_APP_NCMS_MICROSERVICE,
};

export const endpoints = {
  CREATE_ANNOUNCEMENT: `${microServices.NCMSV2}/announcement/create-announcement`,
  UPDATE_ANNOUNCEMENT: `${microServices.NCMSV2}/announcement/update-announcement`,
  ANNOUNCEMENT_LIST: `${microServices.NCMSV2}/announcement/get-announcement-list`,
  DELETE_ANNOUNCEMENT: `${microServices.NCMSV2}/announcement/delete-announcement`,

  CREATE_ANNOUNCEMENT_DELIVERY: `${microServices.NCMSV2}/announcement/create-announcement-delivery`,
  UPDATE_ANNOUNCEMENT_DELIVERY: `${microServices.NCMSV2}/announcement/update-announcement-delivery`,
  ANNOUNCEMENT_DELIVERY_LIST: `${microServices.NCMSV2}/announcement/get-announcement-delivery-list`,
  DELETE_ANNOUNCEMENT_DELIVERY: `${microServices.NCMSV2}/announcement/delete-announcement-delivery`,
  
  GET_ANNOUNCEMENT_BY_ID: `${microServices.NCMSV2}/announcement/get-announcement-by-id`,
  GET_ANNOUNCEMENT_DELIVERY_BY_ID: `${microServices.NCMSV2}/announcement/get-announcement-delivery-by-id`,
};
