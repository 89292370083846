import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IGetQuestion } from "../interfaces";
interface IInitState {
  loading: boolean;
  questionDetails: IBuildTest[];
  error: string;
  filtersData:any;
}

const initialState = {
  loading: false,
  questionDetails: [],
  error: "",
  filtersData:'',
};

const getQuestionDetailsByID = createSlice({
  name: "getQuestionDetails",
  initialState,
  reducers: {
    questionDetailsByIdRequest(
      state: IInitState,
      action: PayloadAction<IGetQuestion>
    ) {
      state.loading = true;
      state.filtersData = action.payload
    },
    questionDetailsByIdSuccess(
      state: IInitState,
      action: PayloadAction<any[]>
    ) {
      state.loading = false;
      state.questionDetails = action.payload;
    },
  },
});

export const { questionDetailsByIdRequest, questionDetailsByIdSuccess } =
getQuestionDetailsByID.actions;
export default getQuestionDetailsByID.reducer;
