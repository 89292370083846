import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ICreateAssignmentListReqBody,
  IInitState,
} from "pages/assignments/interfaces";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

const createAssignementSlice = createSlice({
  name: "createassignment",
  initialState,
  reducers: {
    createAssignementRequest(
      state: IInitState,
      action: PayloadAction<ICreateAssignmentListReqBody>
    ) {
      state.loading = true;
    },
    createAssignementSuccess(state: IInitState, action) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { createAssignementRequest, createAssignementSuccess } =
  createAssignementSlice.actions;
export default createAssignementSlice.reducer;
