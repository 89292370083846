import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IInitState {
  loading: boolean;
  data: any;
  error: string;
}

const initialState = {
  loading: false,
  error: "",
  data: {},
};

const hierarchyMetricsSlice = createSlice({
  name: "hierarchymetrics",
  initialState,
  reducers: {
    getViewHierarchyMetricsRequest(state: IInitState, action: PayloadAction<any>) {
      state.loading = true;
    },
    getViewHierarchyMetricsSuccess(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const {
  getViewHierarchyMetricsRequest,
  getViewHierarchyMetricsSuccess,
} = hierarchyMetricsSlice.actions;
export default hierarchyMetricsSlice.reducer;
