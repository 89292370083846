import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IActivitiesListState,
  IActivitiesListReqBody,
  IActivitiesMagazineData,
} from "pages/activities/interfaces";

const initialState: IActivitiesListState = {
  loading: false,
  data: [],
  error: "",
  filtersData: {
    start_date: "",
    class_id: "",
    end_date: "",
    activity_type: "",
  },
};

const magazineListSlice = createSlice({
  name: "magazineList",
  initialState,
  reducers: {
    magazineListRequest(state, action: PayloadAction<IActivitiesListReqBody>) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    magazineListSuccess(state, action: PayloadAction<IActivitiesMagazineData[]>) {
      state.loading = false;
      state.data = action.payload;
    },
    resetmagazineList(state) {
      state.data = [];
      state.filtersData = {
        start_date: "",
        class_id: "",
        end_date: "",
        activity_type: "",
      };
    },
  },
});

export const { magazineListRequest, magazineListSuccess, resetmagazineList } =
  magazineListSlice.actions;
export default magazineListSlice.reducer;
