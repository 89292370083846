import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IBuildTestListReqBody } from "../interfaces";
interface IInitState {
  loading: boolean;
  data: IBuildTest[];
  error: string;
  filtersData: any;
}

const initialState = {
  loading: false,
  data: [],
  error: "",
  filtersData: "",
};

const buildDeliveryListSlice = createSlice({
  name: "buildDeliveryList",
  initialState,
  reducers: {
    getBuildDeliveryListRequest(
      state: IInitState,
      action: PayloadAction<IBuildTestListReqBody>
    ) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    getBuildDeliveryListSuccess(
      state: IInitState,
      action: PayloadAction<any[]>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
    getBuildDeliveryListError(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getBuildDeliveryListRequest,
  getBuildDeliveryListSuccess,
  getBuildDeliveryListError,
} = buildDeliveryListSlice.actions;
export default buildDeliveryListSlice.reducer;
