import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAnnouncement, IAnnouncementByIdReqBody, IInitState } from "../interfaces";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

const announcementDeliveryByIdSlice = createSlice({
  name: "announcementDeliveryDelvieryById",
  initialState,
  reducers: {
    getAnnouncementDeliveryByIdRequest(
      state: IInitState,
      action: PayloadAction<IAnnouncementByIdReqBody>
    ) {
      state.loading = true;
    },
    getAnnouncementDeliveryByIdSuccess(
      state: IInitState,
      action: PayloadAction<IAnnouncement[]>
    ) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { getAnnouncementDeliveryByIdRequest, getAnnouncementDeliveryByIdSuccess } =
  announcementDeliveryByIdSlice.actions;
export default announcementDeliveryByIdSlice.reducer;
