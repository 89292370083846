import { createSlice, PayloadAction } from "@reduxjs/toolkit";
type stringArr = string[];
interface IInitState {
  answerKey: string | [];
  choices: stringArr | stringArr[];
  response: string | stringArr;
  isLoading: boolean;
  choiceById: any;
  gridChoiceCount: number | string;
  error: any;
  data: any;
  questionData: any;
  gridRowsAndColumns: any;
  updateChoice: stringArr | stringArr[];
  fetchDupQuestionId: string;
  questionProps: any;
  paragraphCount: number;
}

let questionDetails = {
  rows: ["choice_5", "choice_6", "choice_7", "choice_8"],
  columns: ["choice_1", "choice_2", "choice_3", "choice_4"],
};

const initialState = {
  answerKey: "",
  choices: ["choice_1", "choice_2", "choice_3", "choice_4"],
  choiceById: {},
  gridChoiceCount: 8,
  gridRowsAndColumns: questionDetails,
  updateChoice: ["choice_1", "choice_2", "choice_3", "choice_4"],
  response: "RESPONSE",
  isLoading: false,
  error: false,
  data: false,
  questionData: false,
  fetchDupQuestionId: "",
  questionProps: {},
  paragraphCount: 0,
};

const createQuestionSlice = createSlice({
  name: "createQuestion",
  initialState,
  reducers: {
    setAnswerKey(state: IInitState, action: PayloadAction<any>) {
      state.answerKey = action.payload;
    },
    setChoices(state: IInitState, action: PayloadAction<any>) {
      state.choices = action.payload;
    },
    setGridChoiceById(state: IInitState, action: PayloadAction<any>) {
      state.choiceById = action.payload;
    },
    setGridChoiceCount(state: IInitState, action: PayloadAction<any>) {
      state.gridChoiceCount = action.payload;
    },
    getQuestionDataByIdRequest(state: IInitState, action: PayloadAction<any>) {
      state.isLoading = true;
      state.data = action.payload;
      // state.questionData=action.payload;
    },
    getQuestionDataByIdSuccess(state: IInitState, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.questionData = action.payload;
    },
    getFetchQuestionId(state: IInitState, action: PayloadAction<any>) {
      state.isLoading = false;
      state.fetchDupQuestionId = action.payload;
    },
    updateGridRowsAndColumns(state: IInitState, action: PayloadAction<any>) {
      state.gridRowsAndColumns = action.payload;
    },
    updateChoices(state: IInitState, action: PayloadAction<any>) {
      state.updateChoice = action.payload;
    },
    resetCreateQuestion(state: IInitState) {
      state.choices = ["choice_1", "choice_2", "choice_3", "choice_4"];
      state.answerKey = "";
      state.response = "RESPONSE";
      state.data = false;
      state.gridRowsAndColumns = { ...questionDetails };
      state.choiceById = {};
      state.gridChoiceCount = 8;
    },
    setEditorQuestionProps(state: IInitState, action: PayloadAction<any>) {
      state.questionProps = action.payload;
    },
    setParagraphQuestionCount(state: IInitState, action: PayloadAction<any>) {
      state.paragraphCount = action.payload;
    },
  },
});

export const {
  setAnswerKey,
  setChoices,
  setGridChoiceById,
  setGridChoiceCount,
  updateGridRowsAndColumns,
  getQuestionDataByIdRequest,
  getQuestionDataByIdSuccess,
  resetCreateQuestion,
  updateChoices,
  getFetchQuestionId,
  setEditorQuestionProps,
  setParagraphQuestionCount
} = createQuestionSlice.actions;
export default createQuestionSlice.reducer;
