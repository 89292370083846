import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IBuildTestListReqBody } from "../interfaces";
import deliverListSlice from "pages/term-exams/slices/deliver-list-slice";
interface IInitState {
  loading: boolean;
  validateTestData: {};
  error: string;
  filtersData: any;
  deliveryList: any
}

const initialState = {
  loading: false,
  validateTestData: {},
  error: "",
  filtersData: "",
  deliveryList:[]
};

const validateTestIdSlice = createSlice({
  name: "ValidateTestId",
  initialState,
  reducers: {
    validateTestIdRequest(state: IInitState, action: PayloadAction<any>) {
      state.loading = true;
    },
    validateTestIdSuccess(state: IInitState, action: PayloadAction<any[]>) {
      state.loading = false;
      state.validateTestData = action.payload;
    },
    getValidateTestIdDataError(state: any, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    makeErrorEmpty(state: IInitState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = "";
    },
    storeDeliveryList(state: IInitState, action: PayloadAction<any>) {
      state.deliveryList = action.payload;
    },
    emptyDeliveryList(state: IInitState, action: PayloadAction<any>) {
      state.deliveryList = action.payload;
    },
  
  },
});

export const {
  validateTestIdRequest,
  validateTestIdSuccess,
  getValidateTestIdDataError,
  makeErrorEmpty,
  storeDeliveryList,
  emptyDeliveryList
} = validateTestIdSlice.actions;
export default validateTestIdSlice.reducer;
