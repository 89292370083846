import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function LoaderModal(props) {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent:'center',
        position:'fixed',
        width:'100vw',
        height:'100vh',
        zIndex:9999999,
        background:'rgba(0,0,0,0.2)',
      }}
    >
      <Typography style={{fontWeight:700, fontSize:'24px',color:'black'}}>{props.message || "Please wait..."}</Typography>
      <Typography style={{fontWeight:700, fontSize:'24px',color:'black'}}>{props.message || "Fetching metadata from server"}</Typography>      
    </Box>
  );
}
