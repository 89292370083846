import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IInitUpdateState, IUpdateRequestBody } from "../interfaces";



const initialState = {
  loading: false,
  error: "",
};

const updateLearnList = createSlice({
  name: "update-learn-list",
  initialState,
  reducers: {
    updateLearnListRequest(state: IInitUpdateState, action: PayloadAction<IUpdateRequestBody>) {
      console.log(action, "action");
      state.loading = true;
    },
    updateLearnListError(state: IInitUpdateState, action) {
      state.loading = false;
    }
  },
});
export const {
  updateLearnListRequest,
  updateLearnListError
} = updateLearnList.actions;

export default updateLearnList.reducer;