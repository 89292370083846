import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, IGetAnswerKey } from "../interfaces";
interface IInitState {
  isLoadingrefresh: boolean;
  refreshdata: IBuildTest[];
  refresherror: string;
  filtersData: any;
}

const initialState = {
  isLoadingrefresh: false,
  refreshdata: [],
  refresherror: "",
  filtersData: "",
};

const refreshDeliverySlice = createSlice({
  name: "refreshDelivery",
  initialState,
  reducers: {
    refreshDeliveryRequest(
      state: IInitState,
      action: PayloadAction<IGetAnswerKey>
    ) {
      state.isLoadingrefresh = true;
      state.filtersData = action.payload;
    },
    refreshDeliverySuccess(state: IInitState, action: PayloadAction<any[]>) {
      state.isLoadingrefresh = false;
      state.refreshdata = action.payload;
    },
  },
});

export const { refreshDeliveryRequest, refreshDeliverySuccess } =
  refreshDeliverySlice.actions;
export default refreshDeliverySlice.reducer;
