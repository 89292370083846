import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IScheduleListReqBody, IInitState, IScheduleListSuccess } from "../interfaces";
import { createMonthlyScheduleInitialValues } from "../routes/create-new/helper";
import { ICreateMonthlyScheduleFieldTypes } from "pages/monthly-schedule/interfaces";

const initialState = {
  loading: false,
  data: [],
  error: "",
  filtersData: {
    batch_id: "",
  class_id: "",
  course_id: "",
  },
  scheduleData: createMonthlyScheduleInitialValues,
};

const scheduleListSlice = createSlice({
  name: "scheduleList",
  initialState,
  reducers: {
    scheduleListRequest(
      state: IInitState,
      action: PayloadAction<IScheduleListReqBody>
    ) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    scheduleListSuccess(state: IInitState, action: PayloadAction<IScheduleListReqBody>) {
      state.loading = false;
      state.data = action.payload;
    },
    getScheduleDataRequest(state: IInitState, action: PayloadAction<IScheduleListSuccess>) {
      state.loading = false;
    },
    getScheduleDataSuccess(
      state: IInitState,
      action: PayloadAction<ICreateMonthlyScheduleFieldTypes>
    ) {
      state.loading = false;
      state.scheduleData = action.payload;
    },
    resetScheduleList(state){
      state.data=[]
      state.filtersData={
        batch_id: "",
        class_id: "",
        course_id: ""
    }}
  },
});

export const {
  scheduleListRequest,
  scheduleListSuccess,
  getScheduleDataRequest,
  getScheduleDataSuccess,
  resetScheduleList,
} = scheduleListSlice.actions;
export default scheduleListSlice.reducer;
