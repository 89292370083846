export const microServices = {
  NCMSV1: `ncms/v1`,
  NCMSV2: process.env.REACT_APP_NCMS_MICROSERVICE,
};

export const endpoints = {
  CREATE_MONTHLY_SCHEDULE: `${microServices.NCMSV2}/monthly-schedule/create`,
  MONTHLY_SCHEDULE_BY_ID: `${microServices.NCMSV2}/monthly-schedule/get-schedule-by-id`,
  MONTHLY_SCHEDULE_LIST: `${microServices.NCMSV2}/monthly-schedule/get-list`,
  UPDATE_MONTHLY_SCHEDULE: `${microServices.NCMSV2}/monthly-schedule/update`,
  DELETE_MONTHLY_SCHEDULE: `${microServices.NCMSV2}/monthly-schedule/delete`,
};

export const actionTypes = {
  DELETE_MONTHLY_SCHEDULE: "DELETE_MONTHLY_SCHEDULE",
};