import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loading: false,
  data: [],
  error: "",
};

const deliveryListSlice = createSlice({
  name: "deliveryList",
  initialState,
  reducers: {
    announcementDeliveryListRequest(state, action) {
      state.loading = true;
    },
    announcementDeliveryListSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { announcementDeliveryListRequest, announcementDeliveryListSuccess } =
  deliveryListSlice.actions;

export default deliveryListSlice.reducer;
