export const microServices = {
  TCMSV1: "tcms/v1",
  TCMSV2: "tcms/v2",
  TCMSV3: "tcms/v3",
  NCMSV1: "ncms/v1",
  ICMSV1: "icms/v1",
  NCMSV2:"ncms/v2"
};

export const endpoints = {
  BUILD_TEST_LIST: `${microServices.TCMSV3}/test/list`,
  TEST_BY_ID: `${microServices.TCMSV2}/test/get-details-by-id`,
  UPDATE_EXCEL_TEST_ITEM: `${microServices.TCMSV2}/test/update-test-item`,
  UPDATE_TEST_ITEM: `${microServices.TCMSV3}/test/update-test-item`,
  BUILD_DELIVERY_LIST: `${microServices.TCMSV2}/delivery/list`,
  CREATE_BUILD_DELIVERY: `${microServices.TCMSV2}/delivery/create`,
  UPDATE_BUILD_DELIVERY: `${microServices.TCMSV2}/delivery/update`,
  CREATE_EXCEL_TEMPLATE: `${microServices.TCMSV2}/test/create`,
  CREATE_EXCEL_TEST_ITEM: `${microServices.TCMSV2}/test/create-test-item`,
  CREATE_BUILD_TEST: `${microServices.TCMSV3}/test/create`,
  CREATE_TEST_ITEM: `${microServices.TCMSV3}/test/create-test-item`,
  STORE_TEST: `${microServices.TCMSV3}/test/store-test`,
  GET_DELIVERY_BY_ID: `${microServices.TCMSV2}/delivery/get-details-by-id`,
  GET_ANSWER_KEY: `${microServices.TCMSV3}/test/answer-key`,
  GET_PREVIEW_DATA_API: `${microServices.TCMSV2}/test/preview`,
  CREATE_TEMPLATE_API: `${microServices.TCMSV3}/template/create`,
  VALIDATE_WARM_UP_ID: `${microServices.TCMSV2}/warm-up-delivery/validate-delivery`,
  REFRESH_DELIVERY: `${microServices.TCMSV2}/delivery/create-delivery-exectution-for-new-students`,
  VAIDATE_TEST_ID: `${microServices.TCMSV2}/test/validate-test`,
  PREVIEW_TEST_DATA: `${microServices.TCMSV3}/test/preview-test`,
  GET_TEST_DETAILS: `${microServices.TCMSV3}/test/details`,
  GET_TEST_PREVIEW: `${microServices.TCMSV3}/test/preview-test`,
  UPDATE_TEST: `${microServices.TCMSV3}/test/update`,
  TEMPLATE_LIST: `${microServices.TCMSV3}/template/list`,
  TEMPLATE_DETAILS: `${microServices.TCMSV3}/template/details`,
  CHECK_DUPLICATE_TEMPLATE: `${microServices.TCMSV3}/template/validate-template`,
  GET_QUESTION_GROUP_ITEM_LIST: `${microServices.TCMSV3}/test/question-group-item-list`,
  SAVE_SECTION_WISE_QUESTIONS: `${microServices.TCMSV3}/test/create-test-item`,
  UPDATE_SECTION_WISE_QUESTIONS: `${microServices.TCMSV3}/test/update-test-item`,
  VIEW_QUESTION_DETAILS: `${microServices.NCMSV1}/item/get-item-details`,

  QUESTION_GROUP_LIST: `${microServices.ICMSV1}/qg/question-group-list`,
  CREATE_QUESTION_GROUP: `${microServices.ICMSV1}/qg/create-question-group`,
  SUB_QUESTION_GROUP_LIST: `${microServices.ICMSV1}/sqg/sub-question-group-list`,
  SUB_QUESTION_GROUP_ITEM_LIST: `${microServices.ICMSV1}/item/sqg-item-list`,
  DELETE_SUB_QUESTION_GROUP: `${microServices.ICMSV1}/sqg/delete-sub-question-group`,
  GET_HIEARCHY_BY_BATCH: `${microServices.ICMSV1}/meta/get-hierarchy-list`,
  GET_QUESTION_DETAILS: `${microServices.ICMSV1}/item/get-question`,
  CREATE_SUB_QUESTION_GROUP: `${microServices.ICMSV1}/sqg/create-sub-question-group`,
  CREATE_SUB_QUESTION_GROUP_ITEM: `${microServices.ICMSV1}/item/create-properties`,
  UPDATE_SUB_QUESTION_GROUP_ITEM: `${microServices.ICMSV1}/item/update-properties`,
  UPDATE_QUESTION_GROUP: `${microServices.ICMSV1}/qg/update-question-group`,
  UPDATE_SUB_QUESTION_GROUP: `${microServices.ICMSV1}/sqg/update-sub-question-group`,
  ADD_CONTENT: `${microServices.ICMSV1}/item/submit-item-content`,
  GET_QUESTION_GROUP_LIST_DETAILS: `${microServices.ICMSV1}/qg/get-sqg-list`,
  FETCH_OQB_QUESTION: `${microServices.ICMSV1}/common/migrate-sbd-item-data-to-db`,
  GET_HIEARCHY_SUBJECTS_BY_BATCH: `${microServices.ICMSV1}/meta/get-batch-subject-list`,
  GET_HIEARCHY_CHAPTER_BY_BATCH: `${microServices.ICMSV1}/meta/get-chapter-list`,
  GET_HIEARCHY_TOPIC_BY_BATCH: `${microServices.ICMSV1}/meta/get-topic-list`,
  GET_STATE_LIST: `${microServices.NCMSV2}/usage/get-state-list`,
};

export const CHOICE_NAMES = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "F",
  6: "G",
  7: "H",
  8: "I",
  9: "J",
};

export const LISTS = {
  STANDARD_LIST: "standard_template_list",
  CUSTOM_LIST: "custom_template_list",
};
