import { Grid, Box } from "@mui/material";
import ErrorLogo from "assets/oops.svg";
import { useStyles } from "./styles";
import { Link, useLocation } from "react-router-dom";
import { getQueryParam } from "utils/helpers";

export default function ErrorPage() {
  const location = useLocation();
  const classes = useStyles();

  let errorMessage = getQueryParam("message", location);
  errorMessage = errorMessage.replaceAll("%20", " ");

  return (
    <Grid container>
      <Grid item xs={12}>
        <img
          src={ErrorLogo}
          alt=""
          className={classes.logo}
          style={{ height: "400px" }}
        />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Box className={classes.header}>Ooops... 403!</Box>
        <Box className={classes.content}>{errorMessage}</Box>
      </Grid>
      <Grid sx={{ textAlign: "center", padding: 1 }} xs={12}>
        <Link to="/" style={{ color: "#1976d2" }}>
          <Box>Back To Login</Box>
        </Link>
      </Grid>
    </Grid>
  );
}
