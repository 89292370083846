import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import history from "../history";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";

const reduxSagaMonitorOptions = {};
const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
let middlewares = [sagaMiddleware, routerMiddleware(history)];
if (process.env.NODE_ENV === "development") middlewares.push(logger);
const enhancers = [applyMiddleware(...middlewares)];

const store: any = createStore(
  rootReducer,
  undefined,
  compose(...enhancers) as any
);

sagaMiddleware.run(rootSaga);

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
