import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBuildTest, ICreateSubGroupItem } from "../interfaces";
interface IInitState {
  subQuestionLoading: boolean;
  subQuestiondata: any;
  error: string;
  createAddQuestion: any;
  addFormId:any;
}

const initialState = {
  subQuestionLoading: false,
  subQuestiondata: {},
  error: "",
  createAddQuestion: {},
  addFormId:"",
};

const createSubQuestionGroupItemSlice = createSlice({
  name: "createSubQuestionGroupItem",
  initialState,
  reducers: {
    createSubQuestionGroupItemRequest(state: IInitState, action: PayloadAction<any>) {
      state.subQuestionLoading = true;
      // state.filtersData = action.payload;
    },
    createSubQuestionGroupItemSuccess(
      state: IInitState,
      action: PayloadAction<any[]>
    ) {
      state.subQuestionLoading = false;
      state.subQuestiondata = action.payload;
    },
    createAddSubQuestionGroupItemSuccess(state: IInitState, action: PayloadAction<any>) {
      state.subQuestionLoading = false;
      state.createAddQuestion = action.payload;
    },
    setAddFormId(state:IInitState,action:PayloadAction<any>){
      state.addFormId=action.payload;
    }
  },
});

export const {
  createSubQuestionGroupItemRequest,
  createSubQuestionGroupItemSuccess,
  createAddSubQuestionGroupItemSuccess,
  setAddFormId,
} = createSubQuestionGroupItemSlice.actions;
export default createSubQuestionGroupItemSlice.reducer;
