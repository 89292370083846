import { createSlice } from "@reduxjs/toolkit";
import {IInitState} from "../interfaces";

const initialState = {
  loading: false,
  data: [],
  error: "",
};

const subjectListSlice = createSlice({
  name: "subjectList",
  initialState,
  reducers: {
    subjectListRequest(state: IInitState) {
      state.loading = true;
    },
    subjectListSuccess(state: IInitState, action) {
      state.loading = false;
      state.data = action.payload;
    }
  },
});

export const { subjectListRequest, subjectListSuccess } =
  subjectListSlice.actions;
export default subjectListSlice.reducer;
