import { takeLatest, put, call, all } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { ResponseGenerator } from "interfaces";
import LearnService from "./service";
import {
  chapterTopicListRequest,
  chapterTopicListSuccess,
  postLockChaptersRequest,
} from "./slices/chapter-topics-list-slice";
import {
  deleteLearnListRequest,
  learnListRequest,
  learnListSuccess,
} from "./slices/learn-list-slice";
import {
  createResourcesRequest,
  createVideoRequest,
  createSummaryRequest,
  createSummarySuccess,
  createQASuccess,
  createQARequest,
  createSynopsisSuccess,
  createSynopsisRequest,
} from "./slices/upload-learn-topics-slice";
import {
  updateLearnListRequest,
  updateLearnListError,
} from "./slices/update-learn-slice";
import { getItem } from "utils/helpers";
import snackbarReducer, {
  snackbarRequest,
} from "shared/molecules/snackbar/snackbar-slice";
import { replace } from "connected-react-router";
import { chapterDisableListRequest, chapterDisableListSuccess, postDisableChaptersRequest } from "./slices/disable-chapter-list-slice";
const learnService = new LearnService();

function* postLockChapters(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      learnService.postLockChaptersApi,
      action.payload.reqBody
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.payload.callback;
    }
  } catch (error) {
    yield action.payload.callback;
  }
}

function* postDisableChapters(action: any) {
  try {
    const res: ResponseGenerator = yield call(
      learnService.postDisableChaptersApi,
      action.payload.reqBody
    );
    if (res.status === 200 && res.data.status === "success") {
      yield action.payload.callback;
    }
  } catch (error) {
    yield action.payload.callback;
  }
}

function* postLearnList(action) {
  try {
    const res: ResponseGenerator = yield call(
      learnService[action.payload.apiCall],
      action.payload.reqBody
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(
        learnListSuccess({ data: res.data.data, key: action.payload.key })
      );
    }
  } catch (error) {
    yield action.payload.callback;
  }
}

function* deleteLearnListWorker(action) {
  const { chapter_id, class_id, course_id, subject_id } =
    getItem("learnlistfields");
  const reqBody = {
    chapter_id,
    class_id,
    course_id,
    subject_id,
  };
  try {
    const res: ResponseGenerator = yield call(
      learnService[action.payload.apiCall],
      action.payload.apiCall === "deleteResource"
        ? { resource_id: action.payload.resource_id }
        : { content_id: action.payload.content_id }
    );
    console.log("check for res", res);
    if (res.status === 200 && res.data.status === "success") {
      yield put(
        learnListRequest({
          apiCall: action.payload.callBackApiCall,
          reqBody,
          key: action.payload.key,
        })
      );
      yield put(
        snackbarRequest({ message: "deleted successfully", type: "success" })
      );
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (error) {
    yield put(snackbarRequest({ message: "error", type: "error" }));
  }
}

// upload learn
function* postUploadResources(action) {
  const { fieldValues } = action.payload;
  try {
    const res: ResponseGenerator = yield call(
      learnService.postUploadResource,
      action.payload.reqBody
    );
    if (res.status === 200 && res.data.status === "success") {
      // yield put(learnListSuccess({ data: res.data.data, key: action.payload.key }));
      yield put(
        snackbarRequest({ message: "Posted Successfully", type: "success" })
      );
      yield put(
        replace(
          `/learn/list/${fieldValues.class_id}/${fieldValues.course_id}/${fieldValues.subject_id}/${fieldValues.chapter_id}`
        )
      );
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (error) {
    yield put(snackbarRequest({ message: "Went Wrong", type: "error" }));
  }
}

//upload videos
function* postVideoResources(action) {
  const { fieldValues } = action.payload;
  try {
    const res: ResponseGenerator = yield call(
      learnService.postUploadVideo,
      action.payload.reqBody
    );
    if (res.status === 200 && res.data.status === "success") {
      // yield put(learnListSuccess({ data: res.data.data, key: action.payload.key }));
      yield put(
        snackbarRequest({ message: "Posted Successfully", type: "success" })
      );
      yield put(
        replace(
          `/learn/list/${fieldValues.class_id}/${fieldValues.course_id}/${fieldValues.subject_id}/${fieldValues.chapter_id}`
        )
      );
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (error) {
    yield put(snackbarRequest({ message: "Went Wrong", type: "error" }));
  }
}
//upload summary
function* postUploadSummary(action: PayloadAction<any>) {
  const { fieldValues } = action.payload;
  try {
    const res: ResponseGenerator = yield call(
      learnService.createSummary,
      action.payload.reqBody
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(createSummarySuccess(res.data.data));
      yield put(
        snackbarRequest({ message: "Posted Successfully", type: "success" })
      );
      yield put(
        replace(
          `/learn/list/${fieldValues.class_id}/${fieldValues.course_id}/${fieldValues.subject_id}/${fieldValues.chapter_id}`
        )
      );
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (e) {
    yield put(snackbarRequest({ message: "Went Wrong", type: "error" }));
  }
}

//upload qa
function* createQAWorker(action: PayloadAction<any>) {
  const { fieldValues } = action.payload;
  try {
    const res: ResponseGenerator = yield call(
      learnService.createQA,
      action.payload.reqBody
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(createQASuccess(res.data.data));
      yield put(
        snackbarRequest({ message: "Posted Successfully", type: "success" })
      );
      yield put(
        replace(
          `/learn/list/${fieldValues.class_id}/${fieldValues.course_id}/${fieldValues.subject_id}/${fieldValues.chapter_id}`
        )
      );
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (e) {
    yield put(snackbarRequest({ message: "Went Wrong", type: "error" }));
  }
}

//upload synopsis
function* createSynopsisWorker(action: PayloadAction<any>) {
  const { fieldValues } = action.payload;
  try {
    const res: ResponseGenerator = yield call(
      learnService.createSynopsis,
      action.payload.reqBody
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(createSynopsisSuccess(res.data.data));
      yield put(
        snackbarRequest({ message: "Posted Successfully", type: "success" })
      );
      yield put(
        replace(
          `/learn/list/${fieldValues.class_id}/${fieldValues.course_id}/${fieldValues.subject_id}/${fieldValues.chapter_id}`
        )
      );
      // yield put(replace('/learn/list'))
    } else {
      yield put(snackbarRequest({ message: res.data.message, type: "error" }));
    }
  } catch (error) {
    yield put(snackbarRequest({ message: "Went Wrong", type: "error" }));
  }
}

function* getChapterTopicsList(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      learnService.getChapterTopicListApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(chapterTopicListSuccess(res.data.data));
    }
  } catch (e) {}
}

function* getChapterDisableList(action: PayloadAction<any>) {
  try {
   // console.log("saga file getChapterDisableList trigger ")
    const res: ResponseGenerator = yield call(
      learnService.getChapterTopicListApi,
      action.payload
    );
    if (res.status === 200 && res.data.status === "success") {
     // console.log("saga file getChapterDisableList success ")
      yield put(chapterDisableListSuccess(res.data.data));
    }
  } catch (e) {}
}

function* updateLearnListWorker(action: PayloadAction<any>) {
  try {
    const res: ResponseGenerator = yield call(
      learnService[action.payload.apiCall],
      action.payload.reqBody
    );
    if (res.status === 200 && res.data.status === "success") {
      yield put(
        snackbarRequest({ message: "Updated Successfully", type: "success" })
      );
    }
  } catch (error) {
    yield put(updateLearnListError(error));
    yield put(snackbarRequest({ message: "error", type: "error" }));
  }
}

export default function* getLearnSaga() {
  yield takeLatest(chapterTopicListRequest.type, getChapterTopicsList);
  yield takeLatest(postLockChaptersRequest.type, postLockChapters);
  yield takeLatest(chapterDisableListRequest.type, getChapterDisableList);
  yield takeLatest(postDisableChaptersRequest.type, postDisableChapters);
  yield takeLatest(learnListRequest.type, postLearnList);
  yield takeLatest(createResourcesRequest.type, postUploadResources);
  yield takeLatest(createVideoRequest.type, postVideoResources);
  yield takeLatest(createSummaryRequest.type, postUploadSummary);
  yield takeLatest(createQARequest.type, createQAWorker);
  yield takeLatest(createSynopsisRequest.type, createSynopsisWorker);
  yield takeLatest(deleteLearnListRequest.type, deleteLearnListWorker);
  yield takeLatest(updateLearnListRequest.type, updateLearnListWorker);
}
