import React from "react";
import SideBar from "shared/organisms/sidebar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Sidebar2 from 'shared/organisms/sidebar2'

interface Props {
  children: React.ReactNode;
}

export default function AppLayout(props: Props) {
  return (
    // <Box sx={{ display: "flex" }}>
    //   <SideBar />
    //   <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    //     <Toolbar />
    //     {props.children}
    //   </Box>
    // </Box>
    <Sidebar2>
      {props.children}
    </Sidebar2>
  );
}
