import { createSlice, PayloadAction, PayloadActionCreator } from "@reduxjs/toolkit";
interface IInitState {
  loading: boolean;
  deliveryData:any;
  
}

const initialState = {
  loading: false,
  deliveryData:{},
  metaData:[],
  filtersData:'',
  errors:'',
};

const warmUpTestDeliveryListSlice = createSlice({
  name: "WarmUpTestDeliveryList",
  initialState,
  reducers: {
    getWarmUpDeliveryDetailsRequest(state,action: PayloadAction<any>){
      state.loading = true;
      state.filtersData = action.payload;
    },
    getWarmUpDeliveryDetailsSuccess(state, action: PayloadAction<any>) {
      state.loading = true;
      state.deliveryData = action.payload;
    },
  }, 
});

export const { getWarmUpDeliveryDetailsRequest,getWarmUpDeliveryDetailsSuccess} =
warmUpTestDeliveryListSlice.actions;
export default warmUpTestDeliveryListSlice.reducer;
