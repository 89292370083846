import { createSlice, PayloadAction } from "@reduxjs/toolkit";
 import { IWarmUpTest } from "../interfaces";
interface IInitState {
  loading: boolean;
  data: IWarmUpTest[];
  error: string;
  filtersData:any;
}

const initialState = {
  loading: false,
  data: [],
  error: "",
  filtersData:""
};
 
const warmUpTestListSlice = createSlice({
  name: "WarmUpTestList",
  initialState,
  reducers: {
    getWarmUpTestListRequest(state: IInitState, action: PayloadAction<any>) {
      state.loading = true;
      state.filtersData = action.payload;
    },
    getWarmUpTestListSuccess(state: IInitState, action: PayloadAction<any[]>) {
      state.loading = false;
      state.data = action.payload;
    },
    getFilteredDataRequest(state:IInitState,action:PayloadAction<any>){
      state.loading=true;
      state.data=action.payload;
    },
    getWarmUpEditRequest(state:IInitState,action:PayloadAction<any>){

    }
  },
});

export const { getWarmUpTestListRequest, getWarmUpTestListSuccess,getFilteredDataRequest,getWarmUpEditRequest } =
  warmUpTestListSlice.actions;
export default warmUpTestListSlice.reducer;
