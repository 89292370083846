import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IInitialState {
  questionData: any;
  options: any;
  lowValue?: string;
  highValue?: string;
  patternMask: string;
  numberOfQuestions?: string;
  updatedAnswerKey?: string;
  clientPreviewData?: any;
  previewAnswerKey?: any;
  explanation?: any;
  hint?: any;
  question?: any;
}

const initialState: IInitialState = {
  questionData: "",
  options: {},
  lowValue: "",
  highValue: "",
  patternMask: "",
  numberOfQuestions: "",
  updatedAnswerKey: "",
  clientPreviewData: "",
  previewAnswerKey: "",
  explanation: "",
  hint: "",
  question: {},
};

const questionEditorsSlice = createSlice({
  name: "questionEditors",
  initialState: initialState,
  reducers: {
    updateQuestionData(state: IInitialState, action: PayloadAction<any>) {
      console.log("check for question data api111", action);

      state.questionData = action.payload;
    },
    updateOptions(state: IInitialState, action: PayloadAction<any>) {
      state.options = action.payload;
    },
    updateNumericValues(state: IInitialState, action: PayloadAction<any>) {
      state.patternMask = action.payload.patternMask;
      state.lowValue = action.payload.lowValue;
      state.highValue = action.payload.highValue;
    },
    updateNumberOfQuestions(state: IInitialState, action: PayloadAction<any>) {
      state.numberOfQuestions = action.payload;
    },
    updateAnswerKey(state: IInitialState, action: PayloadAction<any>) {
      state.updatedAnswerKey = action.payload;
    },
    setClientPreviewData(state: IInitialState, action: PayloadAction<any>) {
      state.clientPreviewData = action.payload;
    },
    setPreviewAnswerKey(state: IInitialState, action: PayloadAction<any>) {
      state.previewAnswerKey = action.payload;
    },
    setExplanation(state: IInitialState, action: PayloadAction<any>) {
      state.explanation = action.payload;
    },
    setHint(state: IInitialState, action: PayloadAction<any>) {
      state.hint = action.payload;
    },
    resetEditorValues(state: IInitialState) {
      state.questionData = "";
      state.options = {};
      state.patternMask = "";
      state.lowValue = "";
      state.highValue = "";
      state.explanation = "";
      state.hint = "";
    },
    setQuestionProps(state: IInitialState, action: PayloadAction<any>) {
      state.question = action.payload;
    },
  },
});

export const {
  updateQuestionData,
  updateOptions,
  updateNumericValues,
  resetEditorValues,
  updateNumberOfQuestions,
  updateAnswerKey,
  setClientPreviewData,
  setPreviewAnswerKey,
  setExplanation,
  setHint,
} = questionEditorsSlice.actions;

export default questionEditorsSlice.reducer;
