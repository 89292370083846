import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/List";
import NcmsLogo from "assets/nCMS_logo.svg";

import { DrawerHeader, AppBar, Drawer } from "./styles";

import Profile from "../sidebar/profile";
import SidePanel from "./side-panel";
import { useAppDispatch } from "hooks/redux";
import { getSideBarOpenStatus } from "pages/build-test/slices/get-sub-question-item-list";

const drawerWidth = 260;

export default function MiniDrawer(props: any) {
  const dispatch = useAppDispatch();
  const { children } = props;
  const [toggleOpen, setToggleOpen] = React.useState(true);
  const [hoverOpen, setHoverOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    if (!toggleOpen) {
      setHoverOpen(true);
      // dispatch(getSideBarOpenStatus(true));
    }
  };

  const handleDrawerClose = () => {
    if (!toggleOpen) {
      setHoverOpen(false);
      dispatch(getSideBarOpenStatus(false));
    }
  };
  const handleDrawerToggle = () => {
    setToggleOpen((prev) => !prev);
    setHoverOpen((prev) => !prev);
    dispatch(getSideBarOpenStatus(!toggleOpen || !hoverOpen));
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={toggleOpen || hoverOpen}
        drawerWidth={drawerWidth}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              sx={{
                marginRight: 1,
              }}
            >
              {toggleOpen ? <MenuIcon /> : <MenuOpenIcon />}
            </IconButton>
            <img src={NcmsLogo} style={{ color: "white", width: "80px" }} />
          </Box>
          <Profile hideUser={false} hideLogo={true} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={toggleOpen || hoverOpen}
        onMouseOver={handleDrawerOpen}
        onMouseOut={handleDrawerClose}
        sx={{
          position: `${toggleOpen ? "static" : "fixed"}`,
          backgroundColor: "#0F172B",
          zIndex: 10,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <SidePanel panelStatus={hoverOpen} />
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          position: "relative",
          left: `${toggleOpen ? 0 : "64px"}`,
          maxWidth: `${toggleOpen ? "100%" : "calc(100% - 64px)"}`,
        }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
