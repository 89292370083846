import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUploadInitialState, IUploadLearnRequestBody, IUploadQABody, IUploadResourceBody, IUploadSummaryBody, IUploadSynopsisBody, IUploadVideoBody } from "../interfaces";

const initialState = {
  loading: false,
  error: "",
  data: {
    resources: {},
    videos: {},
    summary: {},
    qa: {},
    synopsis: {}
  }
};


const uploadLearnTopicsSlice = createSlice({
  name: "upload-topics",
  initialState,
  reducers: {
    createResourcesRequest(state: IUploadInitialState, action: PayloadAction<IUploadLearnRequestBody>) {
      state.loading = true;
    },
    createResourcesSuccess(state: IUploadInitialState, action: PayloadAction<IUploadResourceBody>) {
      state.data.resources = action.payload.data
    },
    createVideoRequest(state: IUploadInitialState, action: PayloadAction<IUploadLearnRequestBody>) {
      state.loading = true;
    },
    createVideoSuccess(state: IUploadInitialState, action: PayloadAction<IUploadVideoBody>) {
      state.data.videos = action.payload.data
    },
    createSummaryRequest(state: IUploadInitialState, action: PayloadAction<IUploadLearnRequestBody>) {
      state.loading = true
    },
    createSummarySuccess(state: IUploadInitialState, action: PayloadAction<IUploadSummaryBody>) {
      state.data.summary = action.payload.data
    },
    createQARequest(state: IUploadInitialState, action: PayloadAction<IUploadLearnRequestBody>) {
      state.loading = true
    },
    createQASuccess(state: IUploadInitialState, action: PayloadAction<IUploadQABody>) {
      state.data.qa = action.payload.data
    },
    createSynopsisRequest(state: IUploadInitialState, action: PayloadAction<IUploadLearnRequestBody>) {
      state.loading = true
    },
    createSynopsisSuccess(state: IUploadInitialState, action: PayloadAction<IUploadSynopsisBody>) {
      state.data.synopsis = action.payload
    }

  },
});
export const {
  createResourcesRequest,
  createResourcesSuccess,
  createVideoRequest,
  createVideoSuccess,
  createSummaryRequest,
  createSummarySuccess,
  createQARequest,
  createQASuccess,
  createSynopsisRequest,
  createSynopsisSuccess

} = uploadLearnTopicsSlice.actions;

export default uploadLearnTopicsSlice.reducer;
