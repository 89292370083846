import { Typography } from "@mui/material"

export const TypographyTitle = ({ children, sx }: any) => {
  return (
    <Typography variant="subtitle2" fontWeight="500" sx={{ ...sx }} style={{paddingBottom:'8px'}}>
      {children}
    </Typography>
  );
};

export const TypographyHeading = ({ children, sx }: any) => {
  return (
    <Typography variant="h6" fontWeight="bold" sx={{ ...sx }} style={{paddingBottom:12}}>
      {children}
    </Typography>
  );
};