import { lazyLoad } from "utils/loadable";

const MiscellaneousPage = lazyLoad(
  () => import("./index"),
  (module) => module.MiscellaneousPage,
  {
    fallback: (
      <div>
        <p>Loading....</p>
      </div>
    ),
  }
);

export default MiscellaneousPage;
