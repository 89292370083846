export const actionTypes = {
  DELETE_ASSIGNMENT: "DELETE_ASSIGNMENT",
  DELETE_ASSIGNMENT_DELIVERY: "DELETE_ASSIGNMENT_DELIVERY",
};
export const microServices = {
  NCMSV1: "ncms/v1",
  NCMSV2: process.env.REACT_APP_NCMS_MICROSERVICE,
};

export const endpoints = {
  CREATE_ASSIGNMENT: `${microServices.NCMSV2}/assignment/create-assignment`,
  ASSIGNMENT_LIST: `${microServices.NCMSV2}/assignment/get-assignment-list`,
  GET_ASSIGNMENT_DATA_BY_ID: `${microServices.NCMSV2}/assignment/get-assignment-by-id`,
  UPDATE_ASSIGNMENT: `${microServices.NCMSV2}/assignment/update-assignment`,
  DELETE_ASSIGNMENT: `${microServices.NCMSV2}/assignment/delete-assignment`,

  CREATE_ASSIGNMENT_DELIVERY: `${microServices.NCMSV2}/assignment/create-assignment-delivery`,
  ASSIGNMENT_DELIVERY_LIST: `${microServices.NCMSV2}/assignment/get-assignment-delivery-list`,
  GET_ASSIGNMENT_DELIVERY_BY_ID: `${microServices.NCMSV2}/assignment/get-assignment-delivery-by-id`,
  UPDATE_ASSIGNMENT_DELIVERY: `${microServices.NCMSV2}/assignment/update-assignment-delivery`,
  DELETE_ASSIGNMENT_DELIVERY: `${microServices.NCMSV2}/assignment/delete-assignment-delivery`,
  
  GET_ASSIGNMENT_DELIVERY_DATA: `${microServices.NCMSV2}/assignment/get-assignment-delivery-data`,
  DOWNLOAD_ASSIGNMENT_DATA: `${microServices.NCMSV2}/assignment/download-assignment-delivery-data`,
};
